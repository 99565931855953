import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { updateInvoice, fetchInvoicesByAppointmentId } from "../../store/thunks/invoiceThunk";
import CreateInvoice from "./CreateInvoice";

export default function EditInvoice({ appointment, invoice }) {
  const dispatch = useDispatch();
  const createInvoiceRef = useRef(null);

  // Local state to track the status of the current operation
  const [localStatus, setLocalStatus] = useState("idle");

  const handleSubmit = async () => {
    const invoiceData = createInvoiceRef.current?.getInvoiceData();
    if (!invoiceData) {
      console.error("No invoice data available");
      return;
    }

    setLocalStatus("loading");

    try {
      await dispatch(updateInvoice({ id: invoice._id, updateData: invoiceData }));
      setLocalStatus("succeeded");
    } catch (error) {
      console.error("Error creating invoice:", error);
      setLocalStatus("error");
    }
  };

  return (
    <div>
      <div className="bg-white rounded-lg shadow-md p-6 mb-6">
        <h3 className="text-lg font-semibold mb-4">Edit Single Invoice</h3>
        <CreateInvoice ref={createInvoiceRef} appointment={appointment} isEditInvoice={true} />
      </div>
      <button
        onClick={handleSubmit}
        disabled={localStatus === "loading" || localStatus === "succeeded"}
        className={`mt-4 px-4 py-2 rounded ${
          localStatus === "loading"
            ? "bg-gray-400"
            : localStatus === "succeeded"
            ? "bg-green-600 hover:bg-green-600"
            : localStatus === "idle"
            ? "bg-indigo-600 hover:bg-indigo-700"
            : "bg-red-600 hover:bg-red-700"
        } text-white`}
      >
        {localStatus === "loading"
          ? "Updating Invoice..."
          : localStatus === "succeeded"
          ? "Invoice Updated"
          : localStatus === "idle"
          ? "Update Invoice"
          : "Error"}
      </button>
    </div>
  );
}
