import React from "react";
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import RevenueReport from "./reports/revenueReports/RevenueReport";
import CollectionsReport from "./reports/revenueReports/CollectionsReport"
// import ClientsReport from "./ReportComponents/ClientsReport";
// import ActiveClientsReport from "./ReportComponents/ActiveClientsReport";
// import ClientsDropoffReport from "./ReportComponents/ClientsDropoffReport";
// import BlockTherapyReport from "./ReportComponents/BlockTherapyReport";
import ReportButton from "../../applicationUi/components/ReportButton";
import ClientStatementReport from "./reports/clientReports/clientStatementReport";
import ClinicianHoursReport from "./reports/clinicianReports/ClinicianHoursReport";
import ClientsDropoffReport from "./reports/clientReports/ClientDropoffReport";
import ActiveClientsReport from "./reports/clientReports/ActiveClientsReport";

const reportComponents = {
  "Revenue Report": RevenueReport,
  "Collections Report": CollectionsReport,
  "Client Statement": ClientStatementReport,
  "Clinician Hours Report": ClinicianHoursReport,
  "Clients Dropoff Report": ClientsDropoffReport,
  "Active Clients Report": ActiveClientsReport,
//   "Clients Report": ClientsReport,
//   "Active Clients Report": ActiveClientsReport,
//   "Clients Dropoff Report": ClientsDropoffReport,
//   "Block Therapy Report": BlockTherapyReport,
};

function ReportDetail({ reportName, onBack }) {
  const ReportComponent = reportComponents[reportName] || (() => <p>No report available</p>);

  return (
    <div className="p-4">
      <ReportButton
        reportName="Back"
        icon={<ArrowLeftIcon className="h-5 w-5" />}
        onClick={onBack}
        ariaLabel="Back to Reports"
      />
      <h1 className="text-2xl font-bold mt-4">{reportName}</h1>
      <ReportComponent />
    </div>
  );
}

export default ReportDetail;
