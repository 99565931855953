import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  getBlock,
  updateBlock,
  appendAppointmentToBlock,
} from "../../store/thunks/blocksThunk";
import { XMarkIcon } from "@heroicons/react/24/outline";
import AppointmentSummary from "./appointments/AppointmentSummary";
// import QuickAddAppointmentModal from "../modals/QuickAddAppointmentModal";
import QuickAddAppointmentModal from "../../modals/QuickAddAppointmentModal";

export default function ModifyBlockModal({ isOpen, onClose, blockId }) {
  const dispatch = useDispatch();
  const [localAppointments, setLocalAppointments] = useState([]);
  const [showQuickAddModal, setShowQuickAddModal] = useState(false);
  const [lastAppointmentTime, setLastAppointmentTime] = useState(null);
  const block = useSelector((state) => state.blocks.block);
  const loading = useSelector((state) => state.blocks.loading);
  const error = useSelector((state) => state.blocks.error);

  const [isAppointmentSummaryOpen, setIsAppointmentSummaryOpen] =
    useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);

  useEffect(() => {
    if (isOpen && blockId) {
      dispatch(getBlock(blockId));
    }
  }, [isOpen, blockId, dispatch]);

  useEffect(() => {
    if (block && block.appointments) {
      setLocalAppointments([...block.appointments]);
    }
  }, [block]);

  if (!isOpen) return null;

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    const updatedAppointments = Array.from(localAppointments);
    const [removed] = updatedAppointments.splice(source.index, 1);
    updatedAppointments.splice(destination.index, 0, removed);

    setLocalAppointments(updatedAppointments);
  };

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  const handleAppointmentClick = (appointment) => {
    setSelectedAppointment(appointment);
    setIsAppointmentSummaryOpen(true);
  };

  const handleSaveChanges = async () => {
    const updatedAppointments = localAppointments.map((appointment, idx) => ({
      ...appointment,
      blockNumber: idx + 1,
    }));

    const updatedAppointmentIds = updatedAppointments.map(
      (appointment) => appointment._id
    );

    try {
      await dispatch(
        updateBlock({
          blockId: block._id,
          updatedData: { appointments: updatedAppointmentIds },
        })
      ).unwrap();

      window.alert("Block updated successfully!");
      onClose();
    } catch (err) {
      console.error("Failed to update block:", err);
      window.alert("Failed to update block. Check console for details.");
    }
  };

  const handleAddAppointmentAtEnd = () => {
    if (localAppointments.length > 0) {
      const lastAppointment = localAppointments[localAppointments.length - 1];
      setLastAppointmentTime(new Date(lastAppointment.end));
    }
    setShowQuickAddModal(true);
  };

  const handleQuickAddSubmit = async (appointmentData) => {
    try {
      await dispatch(
        appendAppointmentToBlock({ blockId: block._id, appointmentData })
      ).unwrap();

      window.alert("Appointment added to block successfully!");
      dispatch(getBlock(block._id)); // Refresh block data
      setShowQuickAddModal(false);
    } catch (error) {
      console.error("Failed to append appointment:", error);
      window.alert("Failed to add appointment to block.");
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4 overflow-y-auto">
      <div className="bg-white rounded-lg w-full max-w-4xl relative mt-10 shadow-lg overflow-hidden">
        {/* Close button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none">
          <XMarkIcon className="h-6 w-6" />
        </button>

        <div className="p-6 overflow-y-auto max-h-[70vh] flex flex-col">
          <div className="mb-4">
            <h2 className="text-lg font-semibold">Modify Block</h2>
            <p className="text-sm text-gray-900">
              Drag and drop appointments to reorder them. Rescheduling needs to
              be done manually.
            </p>
          </div>
          {loading && <p>Loading block information...</p>}
          {error && (
            <p className="text-red-600">
              Error:{" "}
              {typeof error === "object"
                ? error.message || JSON.stringify(error)
                : error}
            </p>
          )}

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="appointments">
              {(provided) => (
                <table
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="table-auto w-full border-collapse border border-gray-200 text-sm">
                  <thead>
                    <tr className="bg-gray-100">
                      <th className="border border-gray-200 px-4 py-2">#</th>
                      <th className="border border-gray-200 px-4 py-2">
                        Start Time
                      </th>
                      <th className="border border-gray-200 px-4 py-2">
                        Client
                      </th>
                      <th className="border border-gray-200 px-4 py-2">
                        Title
                      </th>
                      <th className="border border-gray-200 px-4 py-2">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {localAppointments.map((appointment, index) => (
                      <Draggable
                        key={appointment._id}
                        draggableId={appointment._id}
                        index={index}>
                        {(provided) => (
                          <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="hover:bg-gray-50 transition-colors cursor-pointer"
                            onClick={() => handleAppointmentClick(appointment)}>
                            <td className="border border-gray-200 px-4 py-2 text-center">
                              {index + 1}
                            </td>
                            <td className="border border-gray-200 px-4 py-2">
                              {formatDateTime(appointment.start)}
                            </td>
                            <td className="border border-gray-200 px-4 py-2">
                              {appointment.client?.firstName}{" "}
                              {appointment.client?.lastName || "Unknown"}
                            </td>{" "}
                            <td className="border border-gray-200 px-4 py-2">
                              {appointment.service?.description} - $
                              {appointment.service?.price || "Unknown"}
                            </td>
                            <td className="border border-gray-200 px-4 py-2">
                              {appointment.status?.length
                                ? appointment.status.join(", ")
                                : "No Status"}
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </tbody>
                </table>
              )}
            </Droppable>
          </DragDropContext>

          <div className="flex justify-between mt-4">
            <button
              onClick={handleAddAppointmentAtEnd}
              className="rounded-md bg-gray-200 px-3 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-300 focus:outline-none">
              Add Appointment at End
            </button>

            <div className="flex space-x-2">
              <button
                className="rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white hover:bg-gray-500 focus:outline-none"
                onClick={onClose}>
                Close
              </button>
              <button
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white hover:bg-indigo-500 focus:outline-none"
                onClick={handleSaveChanges}>
                Update Block
              </button>
            </div>
          </div>
        </div>

        {isAppointmentSummaryOpen && (
          <AppointmentSummary
            open={isAppointmentSummaryOpen}
            onClose={() => {
              setIsAppointmentSummaryOpen(false);
              dispatch(getBlock(blockId)); // Re-fetch the block data on modal close
            }}
            appointment={selectedAppointment}
          />
        )}

        {showQuickAddModal && (
          <QuickAddAppointmentModal
            isOpen={showQuickAddModal}
            onClose={() => setShowQuickAddModal(false)}
            initialDate={lastAppointmentTime?.toISOString().split("T")[0]}
            initialTime={lastAppointmentTime
              ?.toISOString()
              .split("T")[1]
              .slice(0, 5)}
            selectedClinician={block?.clinician}
            clinic={block?.clinic}
            blockId={blockId} // Pass blockId to QuickAddAppointmentModal
          />
        )}
      </div>
    </div>
  );
}
