import React, { useEffect, useState, useMemo } from "react";
import AddUser from "./AddUser";
import Table from "../../applicationUi/components/Table";
import { fetchUsers, fetchUserById } from "../../store/thunks/userThunk";
import { useDispatch, useSelector } from "react-redux";
import UserDetails from "./UserDetails";
import { logError, logInfo } from "../../utils/logger";
import { setSelectedUser } from "../../store/slices/userSlice";

function HRPage({ clickedItem }) {
  const [isAddUserOpen, setIsAddUserOpen] = useState(false);
  const [isEditUserOpen, setIsEditUserOpen] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: null
  });

  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.users || []);
  const selectedUser = useSelector((state) => state.users.selectedUser);

  // Fetch users on mount
  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  const handleUserAdded = () => {
    dispatch(fetchUsers());
    setIsAddUserOpen(false);
  };

  useEffect(() => {
    logInfo("HRPage component rendered", {
      componentName: "HRPage",
      usersCount: users.length,
      isAddUserOpen,
      isEditUserOpen,
      selectedUserId: selectedUser?._id,
    });
  }, [users, isAddUserOpen, isEditUserOpen, selectedUser]);

  // Modal open/close handlers
  const openAddUser = () => setIsAddUserOpen(true);
  const closeAddUser = () => setIsAddUserOpen(false);

  const openEditUser = (user) => {
    dispatch(setSelectedUser(user));
    setIsEditUserOpen(true);
  };

  const closeEditUser = () => {
    setIsEditUserOpen(false);
    dispatch(setSelectedUser(null));
  };

  // Handle row click from the Table
  const handleUserClick = (userId) => {
    console.log("Clicked user ID:", userId);
    const user = users.find((u) => u._id === userId);

    if (user) {
      console.log("User found:", user);
      dispatch(setSelectedUser(user)); // Dispatch action to update Redux state
      setIsEditUserOpen(true); // Open the edit modal
    } else {
      console.warn("User not found for the clicked ID:", userId);
    }
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortedUsers = (userList) => {
    if (!sortConfig.key) return userList;

    return [...userList].sort((a, b) => {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];

      if (aValue < bValue) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  };

  const columns = useMemo(
    () => [
      { header: "First Name", accessor: "firstName", sortable: true },
      { header: "Last Name", accessor: "lastName", sortable: true },
      { header: "Role", accessor: "role", sortable: true },
      { header: "Email", accessor: "email" },
      { header: "Phone", accessor: "phone" },
      { header: "Clinic Name", accessor: "defaultLocation.name" },
      { header: "Qualifications", accessor: "qualifications" },
    ],
    []
  );

  // Filter users by their status
  const activeUsers = useMemo(
    () => users.filter((user) => user.userStatus === "active"),
    [users]
  );

  const inactiveUsers = useMemo(
    () => users.filter((user) => user.userStatus === "inactive"),
    [users]
  );

  return (
    <>
      {/* Edit user modal */}
      {selectedUser && isEditUserOpen ? (
        
        <div className="p-4">
          <UserDetails user={selectedUser} onClose={closeEditUser} />
        </div>
      ) : (
        <div>
          {!isAddUserOpen && !isEditUserOpen && (
            <>
              <Table
                columns={columns}
                data={getSortedUsers(activeUsers)}
                onRowClick={handleUserClick}
                actions={[
                  {
                    label: "Add User",
                    onClick: openAddUser,
                  },
                ]}
                title="Active Users"
                description="A list of all active users connected to your account."
                sortConfig={sortConfig}
                onSortChange={handleSort}
              />
              <div className="py-6">
                <Table
                  columns={columns}
                  data={getSortedUsers(inactiveUsers)}
                  onRowClick={handleUserClick}
                  title="Inactive Users"
                  description="A list of all inactive users connected to your account."
                  sortConfig={sortConfig}
                  onSortChange={handleSort}
                />
              </div>
            </>
          )}
        </div>
      )}

      {/* Add user modal */}
      {isAddUserOpen && (
        <AddUser onClose={closeAddUser} onUserAdded={handleUserAdded} />
      )}
    </>
  );
}

export default HRPage;
