import AppointmentList from "../../../applicationUi/components/AppointmentList";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchAppointmentsList } from "../../../store/thunks/appointmentsThunk";
import { fetchTodos } from "../../../store/thunks/todoThunk";
import { logInfo, logError } from "../../../utils/logger"; // Import both logging functions
import UserToDoList from "../owner/UserToDoList";
import WaagChart from "../owner/WaagChart";

export default function OfficeManagerDashboardComponent() {
    return (
        <div className="w-full pb-20">
            <WaagChart />
            <UserToDoList />
        </div>
    );
}
