// store/slices/blocksSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  createBlock,
  getBlock,
  updateBlock,
  deleteBlock,
} from "../thunks/blocksThunk";

const initialState = {
  block: null,
  warnings: [],
  loading: false,
  error: null,
};

const blocksSlice = createSlice({
  name: "blocks",
  initialState,
  reducers: {
    clearBlockState: (state) => {
      state.block = null;
      state.warnings = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // createBlock
      .addCase(createBlock.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.block = null;
        state.warnings = [];
      })
      .addCase(createBlock.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.block = action.payload.block || null;
        state.warnings = action.payload.warnings || [];
      })
      .addCase(createBlock.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      })

      // getBlock
      .addCase(getBlock.pending, (state) => {
        state.loading = true;
        state.error = null;
        // We don't necessarily clear the block here, but we can if we want a fresh state while loading.
        // state.block = null;
      })
      .addCase(getBlock.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.block = action.payload || null; // action.payload should be the block object
        // getBlock doesn't return warnings typically, but if needed, handle them:
        state.warnings = [];
      })
      .addCase(getBlock.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      })

      // updateBlock
      .addCase(updateBlock.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateBlock.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        // updateBlock should return the updated block
        state.block = action.payload || null;
        state.warnings = [];
      })
      .addCase(updateBlock.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      })

      // deleteBlock
      .addCase(deleteBlock.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteBlock.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        // After deleting the block, it's gone, so clear the block state
        state.block = null;
        state.warnings = [];
      })
      .addCase(deleteBlock.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      });
  },
});

export const { clearBlockState } = blocksSlice.actions;
export default blocksSlice.reducer;
