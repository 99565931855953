import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ChartModal = ({ metric, onClose }) => {
  if (!metric) return null;

  // Create array of 52 weeks, filling in missing weeks with 0
  const generateWeeklyLabels = () => {
    const today = new Date();
    const weeks = [];
    for (let i = 51; i >= 0; i--) {
      const date = new Date(today);
      date.setDate(date.getDate() - i * 7);
      weeks.push(date);
    }
    return weeks;
  };

  const weekLabels = generateWeeklyLabels();
  const labels = weekLabels.map((date) =>
    date.toLocaleDateString("en-US", {
      weekday: "short",
      month: "short",
      day: "numeric",
      year: "numeric",
    })
  );

  const getDatasets = () => {
    // Create a map of existing data points by date string
    const dataMap = new Map();

    if (metric.weeklyData && Array.isArray(metric.weeklyData)) {
      metric.weeklyData.forEach((week) => {
        if (week && week.date) {
          const dateStr = new Date(week.date).toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
          });
          dataMap.set(dateStr, week.value || 0);
        }
      });
    }

    const weeklyValues = labels.map((dateStr) => {
      const value = dataMap.get(dateStr);
      return value !== undefined ? value : 0;
    });

    const colors = getMetricColor(metric.name);

    return [
      {
        label: metric.name,
        data: weeklyValues,
        borderColor: colors.border,
        backgroundColor: colors.background,
        tension: 0.3,
      },
    ];
  };

  const getMetricColor = (metricName) => {
    switch (metricName) {
      case "Total Revenue":
        return {
          border: "rgb(75, 192, 192)",
          background: "rgba(75, 192, 192, 0.5)",
        };
      case "Total Appointments":
        return {
          border: "rgb(153, 102, 255)",
          background: "rgba(153, 102, 255, 0.5)",
        };
      case "New Clients":
        return {
          border: "rgb(255, 159, 64)",
          background: "rgba(255, 159, 64, 0.5)",
        };
      case "Consult":
        return {
          border: "rgb(153, 102, 255)",
          background: "rgba(153, 102, 255, 0.5)",
        };
      default:
        return {
          border: "rgb(75, 192, 192)",
          background: "rgba(75, 192, 192, 0.5)",
        };
    }
  };

  const data = {
    labels,
    datasets: getDatasets(),
  };

  const formatYAxisLabel = (value, metricName) => {
    switch (metricName) {
      case "Total Revenue":
        return "$" + value.toFixed(2);
      default:
        return Math.round(value);
    }
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: `${metric.name} - Weekly Trends`,
      },
    },
    scales: {
      x: {
        type: "category",
        title: {
          display: true,
          text: "Week",
        },
        ticks: {
          maxRotation: 45,
          minRotation: 45,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return formatYAxisLabel(value, metric.name);
          },
          stepSize: Math.ceil(Math.max(...getDatasets()[0].data) / 5), // Divide y-axis into ~5 steps
        },
      },
    },
  };

  const formatValue = (value, metricName) => {
    if (value === undefined || value === null || value === "") return "N/A";

    switch (metricName) {
      case "Total Revenue":
        return typeof value === "string" && value.startsWith("$")
          ? value
          : `$${parseFloat(value).toFixed(2)}`;
      default:
        return value.toString();
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-8 max-w-4xl w-full mx-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">{metric.name} Analysis</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700">
            <span className="sr-only">Close</span>
            <svg
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <div className="h-[400px]">
          <Line options={options} data={data} />
        </div>

        <div className="mt-4 grid grid-cols-2 gap-4">
          <div className="bg-gray-50 p-4 rounded">
            <h3 className="font-semibold mb-2">Current Performance</h3>
            <p>Last Week: {formatValue(metric.lastWeek, metric.name)}</p>
            <p>Previous Week: {formatValue(metric.prevWeek, metric.name)}</p>
            <p>MTD: {formatValue(metric.mtd, metric.name)}</p>
          </div>
          <div className="bg-gray-50 p-4 rounded">
            <h3 className="font-semibold mb-2">Previous Performance</h3>
            <p>Last Month: {formatValue(metric.lastMonth, metric.name)}</p>
            <p>Target: {formatValue(metric.target, metric.name)}</p>
            <p>Achievement: {metric.percentLast}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChartModal;
