import React, { useState, useEffect } from "react";
import Table from "../../applicationUi/components/Table";
import QuickAddClinicianModal from "../../modals/QuickAddClinicianModal";
import { useDispatch, useSelector } from "react-redux";
import { fetchClinicians } from "../../store/thunks/cliniciansThunk";
import { logInfo, logError } from "../../utils/logger";

const CliniciansPage = () => {
  const dispatch = useDispatch();
  const clinicians = useSelector((state) => state.clinicians.clinicians || []); // Default to an empty array if undefined
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: null
  });

  useEffect(() => {
    dispatch(fetchClinicians())
      .unwrap()
      .then(() => {
        logInfo('Clinicians fetched successfully', {
          componentName: 'CliniciansPage',
          action: 'fetchClinicians'
        });
      })
      .catch(error => {
        logError('Failed to fetch clinicians', {
          componentName: 'CliniciansPage',
          action: 'fetchClinicians',
          error: error.message
        });
      });
  }, [dispatch]);

  const handleRefresh = () => {
    dispatch(fetchClinicians())
      .unwrap()
      .then(() => {
        logInfo('Clinicians refreshed successfully', {
          componentName: 'CliniciansPage',
          action: 'refreshClinicians'
        });
      })
      .catch(error => {
        logError('Failed to refresh clinicians', {
          componentName: 'CliniciansPage',
          action: 'refreshClinicians',
          error: error.message
        });
      });
  };

  const [isQuickAddClinicianModalOpen, setIsQuickAddClinicianModalOpen] =
    useState(false);

  const handleCloseQuickAddClinicianModal = () => {
    setIsQuickAddClinicianModalOpen(false);
    logInfo('Quick Add Clinician modal closed', {
      componentName: 'CliniciansPage',
      action: 'closeQuickAddClinicianModal'
    });
  };

  const handleAddClinician = () => {
    setIsQuickAddClinicianModalOpen(true);
    logInfo('Quick Add Clinician modal opened', {
      componentName: 'CliniciansPage',
      action: 'openQuickAddClinicianModal'
    });
  };

  const handleClinicianClick = (clinicianId) => {
    logInfo('Clinician clicked', {
      componentName: 'CliniciansPage',
      action: 'clinicianClick',
      clinicianId
    });
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortedClinicians = () => {
    if (!sortConfig.key) return clinicians;

    return [...clinicians].sort((a, b) => {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];

      if (aValue < bValue) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  };

  useEffect(() => {
    logInfo('CliniciansPage rendered', {
      componentName: 'CliniciansPage',
      cliniciansCount: clinicians.length
    });
  }, [clinicians]);

  return (
    <div>
      <Table
        columns={[
          { header: "First Name", accessor: "firstName", sortable: true },
          { header: "Last Name", accessor: "lastName", sortable: true },
          { header: "Email", accessor: "email", },
          { header: "Phone", accessor: "phone", },
          { header: "Clinic Name", accessor: "defaultLocation.name", },
          { header: "Qualifications", accessor: "qualifications", },
        ]}
        data={getSortedClinicians()}
        onRowClick={handleClinicianClick}
        sortConfig={sortConfig}
        onSortChange={handleSort}
        actions={[
          { label: "Add Clinician", onClick: handleAddClinician },
        ]}
        title="Clinicians"
        description="A list of all clinicians connected to your account."
      />

      {isQuickAddClinicianModalOpen && (
        <QuickAddClinicianModal onClose={handleCloseQuickAddClinicianModal} />
      )}
    </div>
  );
};

export default CliniciansPage;
