import { useState, useEffect } from "react";
import {
  ChevronRightIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  MinusIcon,
} from "@heroicons/react/24/outline";
import StatusLights from "./StatusLights";

function AppointmentList({ appointments = [], onAppointmentClick }) {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items per page
  const [filter, setFilter] = useState("Future"); // 'All', 'Future', 'Previous'
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  // Effect to recalculate `filteredAppointments` when `appointments` or `filter` changes
  useEffect(() => {
    const updatedFilteredAppointments = uniqueAppointments.filter(
      (appointment) => {
        const appointmentDate = new Date(appointment.start);
        const now = new Date();

        if (filter === "Future") {
          return appointmentDate > now;
        } else if (filter === "Previous") {
          return appointmentDate < now;
        }
        return true; // 'All' appointments
      }
    );

    setFilteredAppointments(updatedFilteredAppointments);
  }, [appointments, filter]); // Recalculate when `appointments` or `filter` changes

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const calculateDuration = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const duration = (endDate - startDate) / (1000 * 60); // Duration in minutes
    return duration;
  };

  function formatPrice(price) {
    if (price === undefined || price === null) {
      return "$0.00"; // Return a default value or handle it as needed
    }
    const numPrice = Number(price);
    return `$${numPrice.toFixed(2)}`;
  }

  let uniqueAppointments = [];
  // Filter out duplicate appointments based on the _id
  if (appointments) {
    uniqueAppointments = Array.from(
      new Set(appointments.map((a) => a._id))
    ).map((id) => appointments.find((a) => a._id === id));
  }

  const [filteredAppointments, setFilteredAppointments] =
    useState(uniqueAppointments);

  // const filteredAppointments = uniqueAppointments.filter((appointment) => {
  //   const appointmentDate = new Date(appointment.start);
  //   const now = new Date();

  //   if (filter === "Future") {
  //     return appointmentDate > now;
  //   } else if (filter === "Previous") {
  //     return appointmentDate < now;
  //   }
  //   return true; // 'All' appointments
  // });

  const sortedAppointments = [...filteredAppointments].sort((a, b) => {
    if (!sortConfig.key) return 0;
    const aValue =
      sortConfig.key === "client"
        ? `${a.client?.firstName || ""} ${a.client?.lastName || ""}`
        : a[sortConfig.key];
    const bValue =
      sortConfig.key === "client"
        ? `${b.client?.firstName || ""} ${b.client?.lastName || ""}`
        : b[sortConfig.key];
    const compare = aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
    return sortConfig.direction === "asc" ? compare : -compare;
  });

  // Sorting logic
  const handleSort = (key) => {
    const isAscending =
      sortConfig.key === key && sortConfig.direction === "asc";

    const sorted = [...filteredAppointments].sort((a, b) => {
      let aValue, bValue;

      if (key === "service.description") {
        aValue = a.service?.description || "";
        bValue = b.service?.description || "";
      } else if (key === "service.price") {
        aValue = a.service?.price || 0;
        bValue = b.service?.price || 0;
      } else if (key === "duration") {
        aValue = calculateDuration(a.start, a.end);
        bValue = calculateDuration(b.start, b.end);
      } else if (key === "client") {
        aValue = `${a.client?.firstName || ""} ${a.client?.lastName || ""}`;
        bValue = `${b.client?.firstName || ""} ${b.client?.lastName || ""}`;
      } else {
        aValue = a[key] || "";
        bValue = b[key] || "";
      }

      if (aValue < bValue) return isAscending ? -1 : 1;
      if (aValue > bValue) return isAscending ? 1 : -1;
      return 0;
    });

    setFilteredAppointments(sorted);
    setSortConfig({ key, direction: isAscending ? "desc" : "asc" });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key !== key)
      return <MinusIcon className="h-4 w-4 inline ml-1 text-gray-500" />;
    if (sortConfig.direction === "asc")
      return <ChevronUpIcon className="h-4 w-4 inline ml-1 text-gray-700" />;
    if (sortConfig.direction === "desc")
      return <ChevronDownIcon className="h-4 w-4 inline ml-1 text-gray-700" />;
    return <MinusIcon className="h-4 w-4 inline ml-1 text-gray-500" />;
  };

  // Sort appointments by start date and time
  // uniqueAppointments.sort((a, b) => new Date(a.start) - new Date(b.start));
  // This new one reverses the list to show the most recent first and then the furthest away last
  uniqueAppointments.sort((a, b) => new Date(b.start) - new Date(a.start));

  // Pagination logic
  const totalItems = filteredAppointments.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  // Use `filteredAppointments` for rendering
  const paginatedAppointments = filteredAppointments.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const TableHeader = () => (
    <thead>
      <tr>
        <th
          className="bg-gray-50 px-2 py-3 text-left text-sm font-semibold text-gray-900 w-1/4 cursor-pointer"
          onClick={() => handleSort("service.description")}>
          Appointment {getSortIcon("service.description")}
        </th>
        <th
          className="bg-gray-50 px-2 py-3 text-left text-sm font-semibold text-gray-900 cursor-pointer"
          onClick={() => handleSort("start")}>
          Date & Time {getSortIcon("start")}
        </th>
        <th
          className="bg-gray-50 px-2 py-3 text-left text-sm font-semibold text-gray-900 cursor-pointer"
          onClick={() => handleSort("duration")}>
          Duration {getSortIcon("duration")}
        </th>
        <th
          className="bg-gray-50 px-2 py-3 text-left text-sm font-semibold text-gray-900 cursor-pointer"
          onClick={() => handleSort("client")}>
          Client {getSortIcon("client")}
        </th>
        <th
          className="bg-gray-50 px-2 py-3 text-left text-sm font-semibold text-gray-900 cursor-pointer"
          onClick={() => handleSort("service.price")}>
          Amount {getSortIcon("service.price")}
        </th>
        <th className="hidden bg-gray-50 px-2 py-3 text-left text-sm font-semibold text-gray-900 md:block">
          Status
        </th>
      </tr>
    </thead>
  );

  const TableRow = ({ appointment, index }) => (
    <tr
      key={`${appointment._id}-${index}`}
      className="bg-white hover:bg-gray-100 cursor-pointer"
      onClick={() => onAppointmentClick(appointment)}>
      <td className="w-1/3 max-w-0 whitespace-nowrap px-2 py-4 text-sm text-gray-900">
        <div className="overflow-x-auto" style={{ maxWidth: "350px" }}>
          <p className="truncate text-gray-500 group-hover:text-gray-900">
            {appointment.service?.description || "N/A"}
          </p>
        </div>
      </td>
      <td className="whitespace-nowrap px-2 py-4 text-left text-sm text-gray-500">
        <time dateTime={appointment.datetime}>
          {new Date(appointment.start).toLocaleDateString("en-US", {
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "numeric",
          })}
          ,{" "}
          {new Date(appointment.start).toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })}
        </time>
      </td>
      <td className="whitespace-nowrap px-2 py-4 text-left text-sm text-gray-500">
        <span className="font-medium text-gray-900">
          {calculateDuration(appointment.start, appointment.end)}
        </span>{" "}
        min
      </td>
      <td className="whitespace-nowrap px-2 py-4 text-left text-sm text-gray-500">
        <div className="overflow-x-auto" style={{ maxWidth: "150px" }}>
          <span className="font-medium text-gray-900">
            {appointment.client?.firstName} {appointment.client?.lastName}
          </span>
        </div>
      </td>
      <td className="whitespace-nowrap px-2 py-4 text-left text-sm text-gray-500">
        <span className="font-medium text-gray-900">
          {appointment.service
            ? formatPrice(appointment.service.price)
            : "$0.00"}
        </span>
      </td>
      <td className="hidden whitespace-nowrap px-2 py-4 text-sm text-gray-500 md:block">
        <span>
          <StatusLights appointment={appointment} />
        </span>
      </td>
    </tr>
  );

  return (
    <>
      <div className="flex items-center justify-between mx-auto mt-8 max-w-6xl px-4 sm:px-6 lg:px-8">
        <h2 className="text-lg font-medium leading-6 text-gray-900">
          Recent activity
        </h2>
        <div className="flex items-center space-x-2">
          <label className="font-medium text-gray-700">Filter by:</label>
          <select
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            className="border border-gray-300 rounded-md px-3 py-1 text-sm text-gray-700 w-40">
            <option value="All">All</option>
            <option value="Future">Future</option>
            <option value="Previous">Previous</option>
          </select>
        </div>
      </div>

      {/* Activity list (smallest breakpoint only) */}
      <div className="shadow sm:hidden">
        <ul className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
          {paginatedAppointments.map((appointment, index) => (
            <li key={`${appointment._id}-${index}`}>
              <span className="flex items-center space-x-4">
                <span className="flex flex-1 space-x-2 truncate">
                  <span className="flex flex-col truncate text-sm text-gray-500">
                    <span className="truncate">
                      {appointment.service?.description || "N/A"}
                    </span>
                    <span>
                      <span className="font-medium text-gray-900">
                        {appointment.service
                          ? formatPrice(appointment.service.price)
                          : "$0.00"}
                      </span>{" "}
                    </span>
                    <time dateTime={appointment.start}>
                      {new Date(appointment.start).toLocaleString()}
                    </time>
                  </span>
                </span>
                <ChevronRightIcon
                  className="h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </li>
          ))}
        </ul>

        <nav
          className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3"
          aria-label="Pagination">
          <div className="flex flex-1 justify-between">
            <button
              type="button"
              onClick={() => setCurrentPage((page) => Math.max(page - 1, 1))}
              className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              disabled={currentPage === 1}>
              Previous
            </button>
            <button
              type="button"
              onClick={() =>
                setCurrentPage((page) => Math.min(page + 1, totalPages))
              }
              className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              disabled={currentPage === totalPages}>
              Next
            </button>
          </div>
        </nav>
      </div>

      {/* Activity table (small breakpoint and up) */}
      <div className="hidden sm:block">
        <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
          <div className="mt-2 flex flex-col">
            {/* Top Pagination */}
            <nav
              className="flex items-center justify-between border-b border-gray-200 bg-white px-4 py-3 sm:px-6"
              aria-label="Pagination">
              <div className="hidden sm:block">
                <p className="text-sm text-gray-700">
                  Showing{" "}
                  <span className="font-medium">
                    {(currentPage - 1) * itemsPerPage + 1}
                  </span>{" "}
                  to{" "}
                  <span className="font-medium">
                    {Math.min(currentPage * itemsPerPage, totalItems)}
                  </span>{" "}
                  of <span className="font-medium">{totalItems}</span> results
                </p>
              </div>
              <div className="flex flex-1 justify-between gap-x-3 sm:justify-end">
                <button
                  type="button"
                  onClick={() =>
                    setCurrentPage((page) => Math.max(page - 1, 1))
                  }
                  className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                  disabled={currentPage === 1}>
                  Previous
                </button>
                <button
                  type="button"
                  onClick={() =>
                    setCurrentPage((page) => Math.min(page + 1, totalPages))
                  }
                  className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                  disabled={currentPage === totalPages}>
                  Next
                </button>
              </div>
            </nav>
            <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <TableHeader />
                <tbody className="divide-y divide-gray-200 bg-white">
                  {paginatedAppointments.length > 0 ? (
                    paginatedAppointments.map((appointment, index) => (
                      <TableRow
                        key={`${appointment._id}-${index}`}
                        appointment={appointment}
                        index={index}
                      />
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="6"
                        className="px-2 py-4 text-center text-sm text-gray-500">
                        No {filter.toLowerCase()} appointments found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {/* Pagination */}
              <nav
                className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                aria-label="Pagination">
                <div className="hidden sm:block">
                  <p className="text-sm text-gray-700">
                    Showing{" "}
                    <span className="font-medium">
                      {(currentPage - 1) * itemsPerPage + 1}
                    </span>{" "}
                    to{" "}
                    <span className="font-medium">
                      {Math.min(currentPage * itemsPerPage, totalItems)}
                    </span>{" "}
                    of <span className="font-medium">{totalItems}</span> results
                  </p>
                </div>
                <div className="flex flex-1 justify-between gap-x-3 sm:justify-end">
                  <button
                    type="button"
                    onClick={() =>
                      setCurrentPage((page) => Math.max(page - 1, 1))
                    }
                    className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                    disabled={currentPage === 1}>
                    Previous
                  </button>
                  <button
                    type="button"
                    onClick={() =>
                      setCurrentPage((page) => Math.min(page + 1, totalPages))
                    }
                    className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                    disabled={currentPage === totalPages}>
                    Next
                  </button>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AppointmentList;
