// src/store/slices/invoiceSlice.js

import { createSlice } from "@reduxjs/toolkit";
import {
  createInvoice,
  createSplitInvoice,
  convertInvoiceToSplit,
  fetchInvoices,
  fetchInvoiceById,
  fetchInvoicesByClientId,
  fetchInvoicesByAppointmentId,
  updateInvoice,
  deleteInvoice,
  updateInvoiceStatus,
  fetchGenerateInvoiceData,
} from "../thunks/invoiceThunk";

const initialState = {
  invoices: [],
  currentInvoice: [],
  clientInvoices: [],
  appointmentInvoices: [],
  generateInvoiceData: [],
  responseMessage: "",
  status: "idle",
  error: null,
  currentPage: 1,
  totalPages: 1,
  totalInvoices: 0,
};

const invoiceSlice = createSlice({
  name: "invoices",
  initialState,
  reducers: {
    setCurrentInvoice: (state, action) => {
      state.currentInvoice = action.payload;
    },
    clearAppointmentInvoices: (state) => {
      state.appointmentInvoices = [];
    },
    clearClientInvoices: (state) => {
      state.clientInvoices = [];
    },
    clearCurrentInvoice: (state) => {
      state.currentInvoice = [];
    },
    clearStatus: (state) => {
      state.status = "idle";
      state.error = null;
      state.responseMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createInvoice.pending, (state) => {
        state.status = "loading";
        state.appointmentInvoices = [];
        state.error = null;
      })
      .addCase(createInvoice.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.appointmentInvoices.push(action.payload);
        state.error = null;
      })
      .addCase(createInvoice.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(createSplitInvoice.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createSplitInvoice.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.responseMessage = action.payload.message || "Split invoices created";
      
        // Extract child and parent invoices from response
        const { childInvoiceData, parentInvoiceData } = action.payload;
      
        if (childInvoiceData) state.appointmentInvoices.push(childInvoiceData);
        if (parentInvoiceData) state.appointmentInvoices.push(parentInvoiceData);
      
        state.error = null;
      })      
      .addCase(createSplitInvoice.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(convertInvoiceToSplit.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(convertInvoiceToSplit.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.invoices.push(action.payload.childInvoice);
        state.invoices.push(action.payload.parentInvoice);
        state.error = null;
      })
      .addCase(convertInvoiceToSplit.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchInvoices.pending, (state) => {
        state.invoices = [];
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchInvoices.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.invoices = action.payload.invoices;
        state.currentPage = action.payload.currentPage;
        state.totalPages = action.payload.totalPages;
        state.totalInvoices = action.payload.totalInvoices;
        state.error = null;
      })
      .addCase(fetchInvoices.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchInvoiceById.pending, (state) => {
        state.currentInvoice = null;
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchInvoiceById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.currentInvoice = action.payload;
        state.error = null;
      })
      .addCase(fetchInvoiceById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchInvoicesByClientId.pending, (state) => {
        state.clientInvoices = [];
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchInvoicesByClientId.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.clientInvoices = action.payload;
        state.error = null;
      })
      .addCase(fetchInvoicesByClientId.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchInvoicesByAppointmentId.pending, (state) => {
        state.appointmentInvoices = [];
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchInvoicesByAppointmentId.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.appointmentInvoices = action.payload;
        state.error = null;
      })
      .addCase(fetchInvoicesByAppointmentId.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(updateInvoice.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateInvoice.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.invoices.findIndex(
          (invoice) => invoice._id === action.payload._id
        );
        state.invoices[index] = action.payload;
        state.currentInvoice = action.payload;
        state.error = null;
      })
      .addCase(updateInvoice.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(deleteInvoice.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(deleteInvoice.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.invoices = state.invoices.filter(
          (invoice) => invoice._id !== action.payload
        );
        state.error = null;
      })
      .addCase(deleteInvoice.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(updateInvoiceStatus.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateInvoiceStatus.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.invoices.findIndex(
          (invoice) => invoice._id === action.payload._id
        );
        state.invoices[index] = action.payload;
        state.error = null;
      })
      .addCase(updateInvoiceStatus.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchGenerateInvoiceData.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchGenerateInvoiceData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.generateInvoiceData = action.payload;
        state.error = null;
      })
      .addCase(fetchGenerateInvoiceData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      }); 
  },
});

export const {
  clearAppointmentInvoices,
  clearClientInvoices,
  clearCurrentInvoice,
  setCurrentInvoice,
  clearStatus,
} = invoiceSlice.actions;
export default invoiceSlice.reducer;
