import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTodos } from "../store/thunks/todoThunk";
import AdminToDoList from "../pages/dashboardPage/admin/AdminToDoList";
import ClinicianToDoList from "../pages/dashboardPage/clinician/ClinicianToDoList";
import { clearSelectedUserTodos } from "../store/slices/todoSlice";

const ToDoModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const selectedUserTodos = useSelector(
    (state) => state.todos.selectedUserTodos
  );
  const loadingSelectedUserTodos = useSelector(
    (state) => state.todos.loadingSelectedUserTodos
  );
  const selectedUser = useSelector((state) => state.users.selectedUser);

  useEffect(() => {
    if (selectedUser?._id) {
      // Always use clinician role if user has multiple roles
      const userRole =
        selectedUser.role.length > 1 ? "clinician" : selectedUser.role[0];
      dispatch(
        fetchTodos({
          userRole,
          clinicianId: selectedUser._id,
        })
      );
    }

    return () => {
      dispatch(clearSelectedUserTodos());
    };
  }, [dispatch, selectedUser]);

  console.log("SELECTED USER ROLES", selectedUser?.role);
  console.log("SELECTED USER TODOS", selectedUserTodos);

  if (!selectedUser) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-8 max-w-4xl w-full mx-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">
            {selectedUser.firstName} {selectedUser.lastName}'s To-Do List
          </h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700">
            <span className="sr-only">Close</span>
            <svg
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <div className="overflow-y-auto max-h-[calc(100vh-200px)]">
          {loadingSelectedUserTodos ? (
            <div>Loading...</div>
          ) : selectedUserTodos ? (
            (() => {
              // If user has multiple roles, default to clinician view
              if (selectedUser.role.length > 1) {
                return <ClinicianToDoList todos={selectedUserTodos} />;
              }
              // Otherwise check single role
              if (selectedUser.role.includes("admin")) {
                return <AdminToDoList todos={selectedUserTodos} />;
              } else {
                return <ClinicianToDoList todos={selectedUserTodos} />;
              }
            })()
          ) : (
            <div>No to-do items available</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ToDoModal;
