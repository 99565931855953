import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllAppointmentsByDateRange } from "../../store/thunks/appointmentsThunk";
import { fetchAllPayrollsByStatusAndPayPeriod } from "../../store/thunks/payrollThunk";
import { fetchClinicians } from "../../store/thunks/cliniciansThunk";
import HRIndividualPayrollPage from "./HRIndividualPayrollPage";
import PayrollScheduleTable from "./PayrollSchedule";
import payrollDates from "./payrollDates";
import PayrollStats from "./PayrollStats";
import * as XLSX from "xlsx";
import { Listbox } from "@headlessui/react";
import {
  CheckIcon,
  ChevronUpDownIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";

export default function HRPayrollOverviewPage() {
  const dispatch = useDispatch();
  const clinicians = useSelector((state) => state.clinicians.clinicians || []);
  const [ishrpayrollpage, setishrpayrollpage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);

  const [startDate, setStartDate] = useState(() => {
    const date = new Date();
    date.setHours(0, 0, 0, 0); // Start of the current day
    return date;
  });

  const [endDate, setEndDate] = useState(() => {
    const currentPayPeriod = payrollDates.find((period) => {
      const [startDateString, endDateString] = period.includeDates.split("-");
      const startDate = new Date(startDateString.trim());
      const endDate = new Date(endDateString.trim());
      endDate.setHours(23, 59, 59, 999); // End of the day
      return new Date() >= startDate && new Date() <= endDate;
    });

    if (currentPayPeriod) {
      const [, endDateString] = currentPayPeriod.includeDates.split("-");
      const initialEndDate = new Date(endDateString.trim());
      initialEndDate.setHours(23, 59, 59, 999); // End of the day
      return initialEndDate;
    }
    return new Date(); // Fallback to the current date if no pay period found
  });

  const [showPayrollSchedule, setShowPayrollSchedule] = useState(false);
  const [selectedClinician, setSelectedClinician] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [submissionDate, setSubmissionDate] = useState(new Date());

  const allAppointments = useSelector(
    (state) => state.appointments.allPayrollAppointments || []
  );
  const allPayrolls = useSelector(
    (state) => state.payrolls.allCliniciansPayrolls || []
  );

  const findUpperBoundPayPeriod = () => {
    return payrollDates.find((period) => {
      const [startDateString] = period.includeDates.split("-");
      const startDate = new Date(Date.parse(startDateString.trim()));
      const paymentDate = new Date(Date.parse(period.paymentDate.trim()));

      paymentDate.setHours(23, 59, 59, 999); // Include the entire payment date

      return currentDate >= startDate && currentDate <= paymentDate;
    });
  };

  const [selectedPeriod, setSelectedPeriod] = useState(
    findUpperBoundPayPeriod()
  );

  const handlePeriodChange = (selectedPeriod) => {
    setStartDate(
      new Date(`${selectedPeriod.includeDates.split("-")[0].trim()}`)
    );
    const newEndDate = new Date(
      `${selectedPeriod.includeDates.split("-")[1].trim()}`
    );
    newEndDate.setHours(23, 59, 59, 999); // End of the day
    setEndDate(newEndDate);

    setSelectedPeriod(selectedPeriod);
    setSubmissionDate(new Date(`${selectedPeriod.submissionDate}`));
  };

  const [inCarryover, setInCarryover] = useState(false);

  // Fetch all the data
  useEffect(() => {
    if (selectedPeriod) {
      setLoading(true);

      const fetchData = async () => {
        try {
          await Promise.all([
            dispatch(fetchClinicians()),
            dispatch(fetchAllAppointmentsByDateRange({ endDate })), // Use the correct endDate
            dispatch(
              fetchAllPayrollsByStatusAndPayPeriod({
                statuses: ["Pending", "Ready", "Approved", "Paid to Clinician"],
                payPeriod: selectedPeriod.payPeriodNumber,
              })
            ),
          ]);

          const adjustedSubmissionDate = new Date(submissionDate);
          adjustedSubmissionDate.setHours(23, 59, 59, 999); // Set to end of the day in local time

          setInCarryover(currentDate > adjustedSubmissionDate);
        } catch (error) {
          console.error("Fetch data error:", error);
        } finally {
          setLoading(false); // End loading
        }
      };

      fetchData();
    }
  }, [
    dispatch,
    startDate,
    endDate,
    selectedPeriod,
    currentDate,
    submissionDate,
    refreshKey, // Include refreshKey as a dependency
  ]);

  const handleClinicianClick = (clinician) => {
    if (!clinician || !clinician._id) {
      console.error("Invalid clinician object:", clinician);
      return; // Prevent opening the page if clinician is invalid
    }
    setSelectedClinician(clinician);
    setishrpayrollpage(true);
  };

  const handleCloseModal = () => {
    setShowPayrollSchedule(false);
  };

  const getBorderStyle = (data) => {
    const hasPendingAppointments = (data.pendingAppointments || []).length > 0;
    const hasReadyCompletedAppointments = (
      data.completedAppointments || []
    ).some((appointment) => appointment.status === "Ready");
    const hasPaidCompletedAppointments = (
      data.completedAppointments || []
    ).some((appointment) => appointment.status === "Paid to Clinician");

    if (hasReadyCompletedAppointments) {
      return "border-green-500 border-dashed";
    } else if (hasPaidCompletedAppointments) {
      return "border-green-500 border-solid";
    } else if (hasPendingAppointments) {
      return "border-yellow-500 border-dashed";
    } else {
      return "border-yellow-500 border-solid";
    }
  };

  const exportToSpreadsheet = (allPayrolls) => {
    if (!allPayrolls || allPayrolls.length === 0) {
      alert("No data available to export.");
      return;
    }

    // Flatten the payrolls from all groups in localPayrolls
    const flattenedPayrolls = allPayrolls.flatMap((group) => group.payrolls);

    // Map the data to the desired format
    const data = flattenedPayrolls.map((payroll) => ({
      "payroll._id": payroll._id || "N/A",
      "appointment._id": payroll.appointmentId?._id || "N/A",
      "invoice._id": payroll.invoiceId?._id || "N/A",
      "Pay Period": payroll.payPeriod || "N/A",
      "Clinician First Name": payroll.clinicianId?.firstName || "N/A",
      "Clinician Last Name": payroll.clinicianId?.lastName || "N/A",
      "Appointment Title": payroll.appointmentId?.title || "N/A",
      "Appointment Start Date": payroll.appointmentId?.start
        ? new Date(payroll.appointmentId.start).toLocaleString("en-US")
        : "N/A",
      "Client First Name": payroll.appointmentId?.client?.firstName || "N/A",
      "Client Last Name": payroll.appointmentId?.client?.lastName || "N/A",
      "Invoice ID": payroll.invoiceId?.invoiceId || "N/A",
      "Invoice Amount": payroll.invoiceId?.amount || "N/A",
      "Percent Received": payroll.percentReceived || "N/A",
      "Payroll Amount": payroll.payrollAmount || "N/A",
    }));

    // Create a new workbook and worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Payroll Data");

    // Generate a dynamic file name
    const firstPayroll = flattenedPayrolls[0]; // Use the first payroll to derive the file name
    const fileName = `${
      firstPayroll.payPeriod || "UnknownPayPeriod"
    }_payroll_data.xlsx`;

    // Generate a download
    XLSX.writeFile(workbook, fileName);
  };

  const getSubmissionDate = () => {
    const currentPeriod = payrollDates.find(
      (period) => period.payPeriodNumber === selectedPeriod.payPeriodNumber
    );

    if (currentPeriod) {
      const submissionDate = new Date(currentPeriod.submissionDate);
      return submissionDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
      });
    } else {
      return "N/A";
    }
  };

  const getScheduledPayDay = () => {
    const currentPeriod = payrollDates.find(
      (period) => period.payPeriodNumber === selectedPeriod.payPeriodNumber
    );

    if (currentPeriod) {
      const paymentDate = new Date(currentPeriod.paymentDate);
      return paymentDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
      });
    } else {
      return "N/A";
    }
  };

  const calculatePendingAppointments = () => {
    if (!allAppointments || typeof allAppointments !== "object" || inCarryover)
      return 0;

    const totalPendingAppointments = Object.values(allAppointments).reduce(
      (total, clinicianData) => {
        const { appointments } = clinicianData;

        const clinicianPendingCount = appointments.filter((appointment) => {
          const hasSoapNotesComplete = appointment.history?.some(
            (historyItem) => historyItem.status === "Soap Notes Complete"
          );
          const hasInvoicePaid =
            appointment.invoice && appointment.invoice.status === "paid";

          return !(hasSoapNotesComplete && hasInvoicePaid);
        }).length;

        return total + clinicianPendingCount;
      },
      0
    );

    return totalPendingAppointments;
  };

  const calculateCompletedAppointments = (clinicianId = null) => {
    const { clinicianMetrics } = metrics;

    if (clinicianId) {
      return clinicianMetrics[clinicianId]?.completedAppointments || 0;
    }

    return totalCompletedAppointments;
  };

  const calculateCarryoverAppointments = () => {
    if (!inCarryover || typeof allAppointments !== "object") return 0;

    return Object.values(allAppointments).reduce(
      (total, clinicianData) => total + clinicianData.appointments.length,
      0
    );
  };

  const calculatePendingPay = () => {
    if (
      !allAppointments ||
      typeof allAppointments !== "object" ||
      inCarryover
    ) {
      return 0;
    }

    const totalPendingPay = Object.values(allAppointments).reduce(
      (total, clinicianData) => {
        const { appointments, clinician } = clinicianData;
        const payPercentage = clinician.defaultPayPercentage
          ? clinician.defaultPayPercentage / 100
          : 0;

        const clinicianTotal = appointments.reduce((sum, appointment) => {
          let amount =
            appointment.invoice && appointment.invoice.amount
              ? appointment.invoice.amount
              : appointment.service.price;

          if (appointment.service.tax.isTaxable) {
            amount = amount * (1 + appointment.service.tax.taxRate);
          }
          return sum + amount * payPercentage;
        }, 0);

        return total + clinicianTotal;
      },
      0
    );

    return totalPendingPay.toFixed(2);
  };

  const calculateCompletedPay = () => {
    return totalPayrollAmount;
  };

  const calculateCarryoverPay = () => {
    // Check if in carryover mode and allAppointments is valid
    if (!inCarryover || typeof allAppointments !== "object") {
      return 0;
    }

    const totalCarryoverPay = Object.values(allAppointments).reduce(
      (total, clinicianData) => {
        // console.log("Processing clinicianData:", clinicianData);

        const { appointments, clinician } = clinicianData;
        const payPercentage = clinician.defaultPayPercentage
          ? clinician.defaultPayPercentage / 100
          : 0;

        const clinicianTotal = appointments.reduce((sum, appointment) => {
          // Use appointment.service.price for the calculation
          const servicePrice = appointment.service?.price || 0;

          // Add the calculated amount for the appointment
          const calculatedPay = servicePrice * payPercentage;

          return sum + calculatedPay;
        }, 0);

        return total + clinicianTotal;
      },
      0
    );

    // console.log("Total carryover pay:", totalCarryoverPay);

    return totalCarryoverPay.toFixed(2);
  };

  const calculateMetrics = () => {
    const metrics = {
      clinicianMetrics: {},
      totalCompletedAppointments: 0,
      totalPayrollAmount: 0,
    };

    if (!Array.isArray(allPayrolls)) {
      console.error("allPayrolls is not an array:", allPayrolls);
      return metrics;
    }

    allPayrolls.forEach((entry) => {
      // Ensure entry and clinician are defined
      if (!entry || !entry.clinician || !entry.clinician._id) {
        console.warn("Invalid payroll entry or clinician:", entry);
        return;
      }

      const clinicianId = entry.clinician._id;
      const payrolls = entry.payrolls || []; // Default to an empty array if payrolls is undefined

      // Calculate metrics for each clinician
      const completedAppointments = payrolls.length;
      const payrollAmount = payrolls.reduce(
        (sum, payroll) => sum + (parseFloat(payroll.payrollAmount) || 0),
        0
      );

      // Store clinician metrics
      if (!metrics.clinicianMetrics[clinicianId]) {
        metrics.clinicianMetrics[clinicianId] = {
          completedAppointments: 0,
          payrollAmount: 0,
        };
      }

      metrics.clinicianMetrics[clinicianId].completedAppointments +=
        completedAppointments;
      metrics.clinicianMetrics[clinicianId].payrollAmount = (
        parseFloat(metrics.clinicianMetrics[clinicianId].payrollAmount) +
        payrollAmount
      ).toFixed(2);

      // Update totals
      metrics.totalCompletedAppointments += completedAppointments;
      metrics.totalPayrollAmount += payrollAmount;
    });

    metrics.totalPayrollAmount = metrics.totalPayrollAmount.toFixed(2);

    return metrics;
  };

  // Use calculateMetrics result
  const metrics = calculateMetrics();
  const { clinicianMetrics, totalCompletedAppointments, totalPayrollAmount } =
    metrics;

  const calculatePayForClinician = (appointments, payPercentage) => {
    if (!Array.isArray(appointments)) {
      console.error("Invalid appointments array:", appointments);
      return 0; // Default to 0 if appointments is not a valid array
    }

    return appointments.reduce((sum, appointment) => {
      let amount =
        appointment.invoice && appointment.invoice.amount
          ? appointment.invoice.amount
          : appointment.service.price;

      if (appointment.service.tax.isTaxable) {
        amount = amount * (1 + appointment.service.tax.taxRate);
      }

      return sum + amount * payPercentage;
    }, 0);
  };

  const stats = [
    {
      name: `Pay Period ${selectedPeriod.payPeriodNumber}`,
      value: `${selectedPeriod.includeDates}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Submit Appointments By End Of",
      value: `${getSubmissionDate()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Pay Day",
      value: `${getScheduledPayDay()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Pending Appointments",
      value: `${calculatePendingAppointments()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Completed Appointments",
      value: `${calculateCompletedAppointments()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Carryover Appointments",
      value: `${calculateCarryoverAppointments()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Total Pending Pay",
      value: `$${calculatePendingPay()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Total Completed Pay",
      value: `$${calculateCompletedPay()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Total Carryover Pay",
      value: `$${calculateCarryoverPay()}`,
      change: "",
      changeType: "neutral",
    },
  ];

  return (
    <div>
      <div className="relative">
        {/* Loading overlay */}
        {loading && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
            <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 border-solid"></div>
          </div>
        )}

        {/* Main content */}
        <div
          className={`py-4 pb-20 ${
            loading ? "pointer-events-none opacity-50" : ""
          }`}>
          <PayrollStats stats={stats} />
          {/* Pay period selector and toggles */}
          <div className="flex space-x-4 mb-4 justify-around items-center">
            <div className="flex flex-col items-center space-y-2">
              <Listbox value={selectedPeriod} onChange={handlePeriodChange}>
                <div className="flex flex-col items-center">
                  <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900 text-center">
                    Select Payroll Period
                  </Listbox.Label>
                  <div className="relative mt-2 w-full">
                    <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                      <span className="block truncate">
                        {`Pay Period ${selectedPeriod.payPeriodNumber} (${selectedPeriod.includeDates})`}
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {payrollDates.map((period) => (
                        <Listbox.Option
                          key={period.payPeriodNumber}
                          value={period}
                          className={({ active, selected }) =>
                            `relative cursor-default select-none py-2 pl-3 pr-9 ${
                              active
                                ? "bg-indigo-600 text-white"
                                : "text-gray-900"
                            }`
                          }>
                          {({ selected }) => (
                            <>
                              <span
                                className={`block truncate ${
                                  selected ? "font-semibold" : "font-normal"
                                }`}>
                                {`Pay Period ${period.payPeriodNumber} (${period.includeDates})`}
                              </span>
                              {selected && (
                                <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600">
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              )}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </div>
                </div>
              </Listbox>
            </div>

            <div>
              <button
                type="button"
                onClick={() => exportToSpreadsheet(allPayrolls)}
                className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Export Spreadsheet{" "}
              </button>
            </div>
            <div>
              <button
                type="button"
                onClick={() => setShowPayrollSchedule(!showPayrollSchedule)}
                className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                View Payroll Schedule
              </button>
            </div>
          </div>

          {/* Clinician grid tiles */}
          <div>
            <ul className="grid grid-cols-1 gap-x-4 gap-y-4 lg:grid-cols-4 xl:gap-x-4">
              {clinicians
                .filter(
                  (clinician) =>
                    clinician.userStatus !== "inactive" &&
                    clinician.payrollType === "fee for service"
                )
                .map((clinician) => {
                  const clinicianId = clinician._id;
                  const clinicianData = clinicianMetrics[clinicianId] || {
                    completedAppointments: 0,
                    payrollAmount: "0.00",
                  };

                  const pendingAppointments =
                    allAppointments[clinicianId]?.appointments || [];
                  const carryoverAppointments =
                    (inCarryover &&
                      allAppointments[clinicianId]?.appointments) ||
                    0;

                  return (
                    <li
                      key={clinicianId}
                      className="overflow-hidden rounded-md border"
                      onClick={() => handleClinicianClick(clinician)}>
                      <div className="flex items-center gap-x-2 border-b border-gray-900/5 bg-gray-50 p-4">
                        <div className="text-xl font-semibold leading-6 text-gray-900">
                          {clinician.firstName} {clinician.lastName}
                        </div>
                      </div>
                      <dl className="-my-3 divide-y divide-gray-100 px-4 py-4 text-sm leading-6">
                        {inCarryover ? (
                          <div className="flex justify-between gap-x-4 py-3">
                            <dt className="text-gray-500">
                              {carryoverAppointments?.length || 0} Carryover
                            </dt>
                            <dd className="font-medium text-gray-900">
                              $
                              {calculatePayForClinician(
                                carryoverAppointments || [],
                                clinician.defaultPayPercentage / 100
                              ).toFixed(2)}
                            </dd>
                          </div>
                        ) : (
                          <div className="text-md flex justify-between gap-x-4 py-3">
                            <dt className="text-gray-500">
                              {pendingAppointments?.length || 0} Pending
                            </dt>
                            <dd className="font-medium text-gray-900">
                              $
                              {calculatePayForClinician(
                                pendingAppointments || [],
                                clinician.defaultPayPercentage / 100
                              ).toFixed(2)}
                            </dd>
                          </div>
                        )}
                        <div className="text-md flex justify-between gap-x-4 py-3">
                          <dt className="text-gray-500">
                            {clinicianData.completedAppointments} Completed
                          </dt>
                          <dd className="font-medium text-gray-900">
                            ${clinicianData.payrollAmount}
                          </dd>
                        </div>
                      </dl>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>

      {showPayrollSchedule && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={handleCloseModal}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Escape") handleCloseModal();
          }}>
          <div
            className="bg-white rounded-lg shadow-lg p-8 max-w-4xl w-full relative"
            onClick={(e) => e.stopPropagation()}>
            <button
              onClick={handleCloseModal}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700">
              <XMarkIcon className="h-6 w-6" />
            </button>
            <PayrollScheduleTable />
          </div>
        </div>
      )}
      {ishrpayrollpage && selectedClinician && (
        <HRIndividualPayrollPage
          clinician={selectedClinician}
          selectedPeriod={selectedPeriod}
          isOpen={ishrpayrollpage}
          onClose={() => {
            setishrpayrollpage(false);
          }}
          onUpdateSuccess={() => {
            console.log(
              "Update success from HRIndividualPayrollPage. Refreshing..."
            );

            // Refresh the parent data
            setRefreshKey((prev) => prev + 1);

            // Close the HRIndividualPayrollPage
            setishrpayrollpage(false);

            // Re-open the HRIndividualPayrollPage after a small delay
            setTimeout(() => {
              setishrpayrollpage(true);
            }, 0); // Adjust the delay if needed
          }}
        />
      )}

      {/* {isPayrollSlideOver && selectedAppointment && (
        <PayrollSlideOver
          isOpen={isPayrollSlideOver}
          appointment={selectedAppointment}
          onClose={() => setIsPayrollSlideOver(false)}
          selectedPeriod={selectedPeriod.payPeriodNumber}
          selectedPeriodDates={selectedPeriod.includeDates}
          isHr={true}
          onUpdateSuccess={() => {
            console.log("Update success from PayrollSlideOver. Refreshing...");
            // fetchData();
          }}
        />
      )} */}
    </div>
  );
}
