import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { generateConfig } from "../../utils/api";
import { handleApiError } from "../../utils/apiErrorHandler";

export const fetchClientStatement = createAsyncThunk(
    'reports/fetchClientStatement',
    async ({ clientId, startDate, endDate }, { rejectWithValue, getState, dispatch }) => {
        try {
            const state = getState();
            const token = state.auth.token;
            const { baseURL, config } = generateConfig({ token });

            const response = await axios.post(
                `${baseURL}/api/reports/client-statement`,
                { clientId, startDate, endDate },
                config
            );

            return response.data;
        } catch (error) {
            return handleApiError(error, rejectWithValue, dispatch);
        }
    }
);

export const fetchRevenueReport = createAsyncThunk(
    'reports/fetchRevenueReport',
    async ({ startDate, endDate }, { rejectWithValue, getState, dispatch }) => {
        try {
            const state = getState();
            const token = state.auth.token;
            const { baseURL, config } = generateConfig({ token });

            const response = await axios.post(
                `${baseURL}/api/reports/revenue`,
                { startDate, endDate },
                config
            );

            return response.data;
        } catch (error) {
            return handleApiError(error, rejectWithValue, dispatch);
        }
    }
);

export const fetchCollectionsReport = createAsyncThunk(
    'reports/fetchCollectionsReport',
    async ({ startDate, endDate }, { rejectWithValue, getState, dispatch }) => {
        try {
            const state = getState();
            const token = state.auth.token;
            const { baseURL, config } = generateConfig({ token });

            const response = await axios.post(
                `${baseURL}/api/reports/collections`,
                { startDate, endDate },
                config
            );

            return response.data;
        } catch (error) {
            return handleApiError(error, rejectWithValue, dispatch);
        }
    }
);

export const fetchClinicianHoursReport = createAsyncThunk(
    'reports/fetchClinicianHoursReport',
    async ({ startDate, endDate }, { rejectWithValue, getState, dispatch }) => {
        try {
            const state = getState();
            const token = state.auth.token;
            const { baseURL, config } = generateConfig({ token });

            const response = await axios.post(
                `${baseURL}/api/reports/clinician-hours`,
                { startDate, endDate },
                config
            );

            return response.data;
        } catch (error) {
            return handleApiError(error, rejectWithValue, dispatch);
        }
    }
);

export const fetchClientDropoffReport = createAsyncThunk(
    'reports/fetchClientDropoffReport',
    async (_, { rejectWithValue, getState, dispatch }) => {
        try {
            const state = getState();
            const token = state.auth.token;
            const { baseURL, config } = generateConfig({ token });

            const response = await axios.get(
                `${baseURL}/api/reports/client-dropoff`,
                config
            );

            return response.data;
        } catch (error) {
            return handleApiError(error, rejectWithValue, dispatch);
        }
    }
);

export const fetchActiveClientsReport = createAsyncThunk(
    'reports/fetchActiveClientsReport',
    async ({ daysBack }, { rejectWithValue, getState, dispatch }) => {
        try {
            const state = getState();
            const token = state.auth.token;
            const { baseURL, config } = generateConfig({ token });

            const response = await axios.post(
                `${baseURL}/api/reports/active-clients`,
                { daysBack },
                config
            );

            return response.data;
        } catch (error) {
            return handleApiError(error, rejectWithValue, dispatch);
        }
    }
);

