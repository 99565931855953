import { createSlice } from '@reduxjs/toolkit';
import {
  fetchRevenueReport,
  fetchCollectionsReport,
  fetchClinicianHoursReport,
  fetchClientDropoffReport,
  fetchActiveClientsReport
} from '../thunks/reportsThunk';

const initialState = {
  revenue: {
    data: null,
    loading: false,
    error: null
  },
  collections: {
    data: null, 
    loading: false,
    error: null
  },
  clinicianHours: {
    data: null,
    loading: false,
    error: null
  },
  clientDropoff: {
    data: null,
    loading: false,
    error: null
  },
  activeClients: {
    data: null,
    loading: false,
    error: null
  }
};

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Revenue Report
    builder
      .addCase(fetchRevenueReport.pending, (state) => {
        state.revenue.loading = true;
        state.revenue.error = null;
      })
      .addCase(fetchRevenueReport.fulfilled, (state, action) => {
        state.revenue.loading = false;
        state.revenue.data = action.payload;
      })
      .addCase(fetchRevenueReport.rejected, (state, action) => {
        state.revenue.loading = false;
        state.revenue.error = action.payload;
      });

    // Collections Report
    builder
      .addCase(fetchCollectionsReport.pending, (state) => {
        state.collections.loading = true;
        state.collections.error = null;
      })
      .addCase(fetchCollectionsReport.fulfilled, (state, action) => {
        state.collections.loading = false;
        state.collections.data = action.payload;
      })
      .addCase(fetchCollectionsReport.rejected, (state, action) => {
        state.collections.loading = false;
        state.collections.error = action.payload;
      });

    // Clinician Hours Report
    builder
      .addCase(fetchClinicianHoursReport.pending, (state) => {
        state.clinicianHours.loading = true;
        state.clinicianHours.error = null;
      })
      .addCase(fetchClinicianHoursReport.fulfilled, (state, action) => {
        state.clinicianHours.loading = false;
        state.clinicianHours.data = action.payload;
      })
      .addCase(fetchClinicianHoursReport.rejected, (state, action) => {
        state.clinicianHours.loading = false;
        state.clinicianHours.error = action.payload;
      });

    // Client Dropoff Report
    builder
      .addCase(fetchClientDropoffReport.pending, (state) => {
        state.clientDropoff.loading = true;
        state.clientDropoff.error = null;
      })
      .addCase(fetchClientDropoffReport.fulfilled, (state, action) => {
        state.clientDropoff.loading = false;
        state.clientDropoff.data = action.payload;
      })
      .addCase(fetchClientDropoffReport.rejected, (state, action) => {
        state.clientDropoff.loading = false;
        state.clientDropoff.error = action.payload;
      });

    // Active Clients Report
    builder
      .addCase(fetchActiveClientsReport.pending, (state) => {
        state.activeClients.loading = true;
        state.activeClients.error = null;
      })
      .addCase(fetchActiveClientsReport.fulfilled, (state, action) => {
        state.activeClients.loading = false;
        state.activeClients.data = action.payload;
      })
      .addCase(fetchActiveClientsReport.rejected, (state, action) => {
        state.activeClients.loading = false;
        state.activeClients.error = action.payload;
      });
  }
});

export default reportsSlice.reducer;
