import { useState } from "react";
import StatusLights from "./StatusLights";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedAppointment } from "../../store/slices/appointmentsSlice";
import AppointmentSummary from "./appointments/AppointmentSummary";
import { fetchAppointmentsByClientId } from "../../store/thunks/appointmentsThunk";
import { fetchInvoicesByAppointmentId } from "../../store/thunks/invoiceThunk";
import { fetchInvoicePaymentsByAppointmentId } from "../../store/thunks/paymentsThunk";

function FutureAppointments({ appointments = [] }) {
  const selectedAppointment = useSelector(
    (state) => state.appointments.selectedAppointment
  );
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [filter, setFilter] = useState("All"); // 'All', 'Future', 'Previous'
  const dispatch = useDispatch();

  const calculateDuration = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const duration = (endDate - startDate) / (1000 * 60); // Duration in minutes
    return duration;
  };

  function formatPrice(price) {
    if (price === undefined || price === null) {
      return "$0.00"; // Return a default value or handle it as needed
    }
    const numPrice = Number(price);
    return `$${numPrice.toFixed(2)}`;
  }

  const filteredAppointments = appointments.filter((appointment) => {
    const appointmentDate = new Date(appointment.start);
    const now = new Date();

    if (filter === "Future") {
      return appointmentDate > now;
    } else if (filter === "Previous") {
      return appointmentDate < now;
    }
    return true; // 'All' appointments
  });

  const uniqueAppointments = Array.from(
    new Set(filteredAppointments.map((a) => a._id))
  ).map((id) => filteredAppointments.find((a) => a._id === id));

  const TableHeader = () => (
    <thead>
      <tr>
        <th className="bg-gray-50 px-2 py-3 text-left text-sm font-semibold text-gray-900">
          Appointment
        </th>
        <th className="bg-gray-50 px-2 py-3 text-left text-sm font-semibold text-gray-900">
          Date & Time
        </th>
        <th className="bg-gray-50 px-2 py-3 text-left text-sm font-semibold text-gray-900">
          Duration
        </th>
        <th className="bg-gray-50 px-2 py-3 text-left text-sm font-semibold text-gray-900">
          Client
        </th>
        <th className="bg-gray-50 px-2 py-3 text-left text-sm font-semibold text-gray-900">
          Amount
        </th>
        <th className="hidden bg-gray-50 px-2 py-3 text-left text-sm font-semibold text-gray-900 md:block">
          Status
        </th>
      </tr>
    </thead>
  );

  const TableRow = ({ appointment, index }) => (
    <tr
      key={`${appointment._id}-${index}`}
      className="bg-white hover:bg-gray-100 cursor-pointer"
      onClick={() => {
        dispatch(setSelectedAppointment(appointment));
        dispatch(fetchAppointmentsByClientId(appointment.client._id));
        dispatch(fetchInvoicesByAppointmentId(appointment._id));
        dispatch(fetchInvoicePaymentsByAppointmentId(appointment._id));
        setIsPanelOpen(true);
      }}>
      <td className="w-1/3 max-w-0 whitespace-nowrap px-2 py-4 text-sm text-gray-900">
        <div className="overflow-x-auto" style={{ maxWidth: "350px" }}>
          <p className="truncate text-gray-500 group-hover:text-gray-900">
            {appointment.title || "N/A"}
          </p>
        </div>
      </td>
      <td className="whitespace-nowrap px-2 py-4 text-left text-sm text-gray-500">
        <time dateTime={appointment.datetime}>
          {new Date(appointment.start).toLocaleDateString("en-US", {
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "numeric",
          })}
          ,{" "}
          {new Date(appointment.start).toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })}
        </time>
      </td>
      <td className="whitespace-nowrap px-2 py-4 text-left text-sm text-gray-500">
        <span className="font-medium text-gray-900">
          {calculateDuration(appointment.start, appointment.end)}
        </span>{" "}
        min
      </td>
      <td className="whitespace-nowrap px-2 py-4 text-left text-sm text-gray-500">
        <div className="overflow-x-auto" style={{ maxWidth: "150px" }}>
          <span className="font-medium text-gray-900">
            {appointment.client?.firstName} {appointment.client?.lastName}
          </span>
        </div>
      </td>
      <td className="whitespace-nowrap px-2 py-4 text-left text-sm text-gray-500">
        <span className="font-medium text-gray-900">
          {appointment.service
            ? formatPrice(appointment.service.price)
            : "$0.00"}
        </span>
      </td>
      <td className="hidden whitespace-nowrap px-2 py-4 text-sm text-gray-500 md:block">
        <span>
          <StatusLights appointment={appointment} />
        </span>
      </td>
    </tr>
  );

  return (
    <div>
      <h2 className="text-lg font-medium text-gray-900">Appointments</h2>

      {/* Filter Selector */}
      <div className="my-4">
        <label className="mr-2 font-medium text-gray-700">Filter by:</label>
        <select
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          className="border border-gray-300 rounded-md px-3 py-1 text-sm text-gray-700 w-40">
          {" "}
          {/* Adjusted width */}
          <option value="All">All</option>
          <option value="Future">Future</option>
          <option value="Previous">Previous</option>
        </select>
      </div>

      <div className="mt-4 overflow-x-auto">
        <div className="inline-block min-w-full align-middle">
          <div className="shadow-sm ring-1 ring-black ring-opacity-5">
            <table className="min-w-full divide-y divide-gray-300">
              <TableHeader />
              <tbody className="divide-y divide-gray-200 bg-white">
                {uniqueAppointments.length > 0 ? (
                  uniqueAppointments.map((appointment, index) => (
                    <TableRow
                      key={`${appointment._id}-${index}`}
                      appointment={appointment}
                      index={index}
                    />
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="6"
                      className="px-2 py-4 text-center text-sm text-gray-500">
                      No {filter.toLowerCase()} appointments found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {isPanelOpen && (
        <AppointmentSummary
          open={isPanelOpen}
          appointment={selectedAppointment}
          onClose={() => setIsPanelOpen(false)}
        />
      )}
    </div>
  );
}

export default FutureAppointments;
