import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { generateConfig } from "../../utils/api";
import { handleApiError } from "../../utils/apiErrorHandler";

export const fetchInsurers = createAsyncThunk(
    "insurer/fetchInsurers",
    async (_, { rejectWithValue, getState, dispatch }) => {
        try {
            const state = getState();
            const token = state.auth.token;
            const { baseURL, config } = generateConfig({ token });

            const response = await axios.get(`${baseURL}/api/insurer/`, config);
            return response.data;
        } catch (error) {
            return handleApiError(error, rejectWithValue, dispatch);
        }
    }
);

export const createInsurer = createAsyncThunk(
    "insurer/createInsurer",
    async (insurerData, { rejectWithValue, getState, dispatch }) => {
        try {
            const state = getState();
            const token = state.auth.token;
            const { baseURL, config } = generateConfig({ token });

            const response = await axios.post(
                `${baseURL}/api/insurer/`,
                insurerData,
                config
            );
            return response.data;
        } catch (error) {
            return handleApiError(error, rejectWithValue, dispatch);
        }
    }
);
export const fetchInsurer = createAsyncThunk(
    "insurer/fetchInsurer",
    async (insurerId, { rejectWithValue, getState, dispatch }) => {
        try {
            const state = getState();
            const token = state.auth.token;
            const { baseURL, config } = generateConfig({ token });

            const response = await axios.get(`${baseURL}/api/insurer/${insurerId}`, config);
            return response.data;
        } catch (error) {
            return handleApiError(error, rejectWithValue, dispatch);
        }
    }
);

export const updateInsurer = createAsyncThunk(
    "insurer/updateInsurer", 
    async ({id, data}, { rejectWithValue, getState, dispatch }) => {
        try {
            const state = getState();
            const token = state.auth.token;
            const { baseURL, config } = generateConfig({ token });

            const response = await axios.put(
                `${baseURL}/api/insurer/${id}`,
                data,
                config
            );
            return response.data;
        } catch (error) {
            return handleApiError(error, rejectWithValue, dispatch);
        }
    }
);


