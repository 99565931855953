import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBlock } from "../../../store/thunks/blocksThunk";
import {
  BookmarkIcon,
  ReceiptRefundIcon,
  XMarkIcon,
  CheckIcon,
  QuestionMarkCircleIcon,
  DocumentTextIcon,
  CreditCardIcon,
  ClipboardDocumentListIcon,
  ClipboardDocumentCheckIcon,
  ChatBubbleBottomCenterTextIcon,
  BanknotesIcon,
  CheckBadgeIcon,
  CheckCircleIcon,
  CalendarIcon,
} from "@heroicons/react/24/outline";

const CalendarEvent = ({
  appointment,
  onClick,
  onRightClick,
  column,
  maxColumns,
  startHour,
}) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false); // State for tooltip visibility
  const [tooltipPosition, setTooltipPosition] = useState({}); // State for tooltip position
  const eventRef = useRef(null); // Ref to get the bounding rectangle
  const dispatch = useDispatch();
  const [localBlockData, setLocalBlockData] = useState(null);

  useEffect(() => {
    if (appointment?.blockId) {
      dispatch(getBlock(appointment.blockId))
        .then((response) => {
          setLocalBlockData(response.payload);
        })
        .catch((error) => {
          console.error("Failed to fetch block data:", error);
        });
    }
  }, [dispatch, appointment?.blockId]);

  const start = new Date(appointment.start);
  const end = new Date(appointment.end);
  // Calculate offsets
  const startOffset =
    ((start.getHours() - startHour) * 60 + start.getMinutes()) / 5;
  const endOffset = ((end.getHours() - startHour) * 60 + end.getMinutes()) / 5;
  const eventDuration = endOffset - startOffset;

  const eventColor = appointment.clinician?.calendarColor || "#000FFF";

  // Create a string for all statuses for tooltip
  const statusText = Array.isArray(appointment.status)
    ? appointment.status.join(", ")
    : "No Status";

  const isMeeting = appointment.isMeeting;

  const handleMouseEnter = () => {
    setIsTooltipVisible(true);
    // Get the bounding rectangle of the event
    const rect = eventRef.current.getBoundingClientRect();
    // Set tooltip position directly above the event
    setTooltipPosition({
      top: rect.top - 30, // 30px above the event
      left: rect.left + rect.width / 2, // Centered horizontally
    });
  };

  const handleMouseLeave = () => {
    setIsTooltipVisible(false);
  };

  // Convert hex color to rgba with opacity
  const hexToRgba = (hex, opacity) => {
    if (!hex) return `rgba(0,0,0,0)`;

    let r = 0,
      g = 0,
      b = 0;
    if (hex.length === 4) {
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
    } else if (hex.length === 7) {
      r = parseInt(hex[1] + hex[2], 16);
      g = parseInt(hex[3] + hex[4], 16);
      b = parseInt(hex[5] + hex[6], 16);
    }
    return `rgba(${r},${g},${b},${opacity})`;
  };

  const handleContextMenu = (e) => {
    e.preventDefault();
    onRightClick(e, appointment);
  };

  const backgroundColor = hexToRgba(eventColor, 0.65);

  return (
    <ul
      ref={eventRef} // Assign the ref to the ul element
      key={appointment._id}
      className="text-white cursor-pointer border overflow-hidden rounded-lg"
      style={{
        backgroundColor: isMeeting ? "#00008B" : hexToRgba(eventColor, 0.65),
        borderColor: isMeeting ? "#fff" : eventColor,
        gridRow: `${startOffset + 2} / span ${eventDuration}`,
        gridColumn: `${column + 1} / span 1`,
        fontSize: "0.75rem",
        zIndex: 20,
      }}
      onClick={() => onClick(appointment)}
      onContextMenu={(e) => {
        e.preventDefault();
        onRightClick(e, appointment);
      }}
      onMouseEnter={handleMouseEnter} // Show tooltip on mouse enter
      onMouseLeave={handleMouseLeave} // Hide tooltip on mouse leave
    >
      {/* Custom Tooltip */}
      {isTooltipVisible && (
        <div className="absolute bg-gray-700 text-white p-2 rounded shadow-lg">
          {statusText}
        </div>
      )}
      <div className="p-1 h-full flex flex-col justify-between">
        <div>
          <div className="flex">
            <div className="flex items-center space-x-1 mr-1">
              {isMeeting ? (
                <CalendarIcon
                  className="h-5 w-5 text-white"
                  data-tip="Meeting"
                />
              ) : (
                Array.isArray(appointment.status) &&
                appointment.status.map((status) => renderStatusIcon(status))
              )}
            </div>
            {isMeeting ? (
              <strong className="text-md truncate">{appointment.title}</strong>
            ) : (
              appointment.client && (
                <strong className="text-md truncate">{`${appointment.client.firstName} ${appointment.client.lastName}`}</strong>
              )
            )}
          </div>
          {!isMeeting && <p className="text-xs">{appointment.title}</p>}
          {isMeeting && appointment.description && (
            <div className="text-xs mt-1">{appointment.description}</div>
          )}
        </div>

        <div className="flex justify-between">
          <p className="text-xs">
            {`${start.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })} - ${end.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}`}
          </p>
          {!isMeeting && appointment.blockId && (
            <p className="text-xs">
              Block {appointment.blockNumber}
              {localBlockData?.appointments?.length && (
                <span> / {localBlockData.appointments.length}</span>
              )}
            </p>
          )}
        </div>
      </div>
    </ul>
  );
};

const renderStatusIcon = (status) => {
  let IconComponent = null;
  let colorClass = "";

  switch (status) {
    case "Booked":
      IconComponent = BookmarkIcon;
      colorClass = "text-green-500";
      break;
    case "Changed":
      IconComponent = ReceiptRefundIcon;
      colorClass = "text-yellow-500";
      break;
    case "Cancelled":
      IconComponent = XMarkIcon;
      colorClass = "text-red-500";
      break;
    case "Cancelled - No Fee":
      IconComponent = XMarkIcon;
      colorClass = "text-red-500";
      break;
    case "Cancelled - Charge Fee":
      IconComponent = XMarkIcon;
      colorClass = "text-red-500";
      break;
    case "No Show":
      IconComponent = XMarkIcon;
      colorClass = "text-red-500";
      break;
    case "No Show - No Fee":
      IconComponent = XMarkIcon;
      colorClass = "text-red-500";
      break;
    case "No Show - Charge Fee":
      IconComponent = XMarkIcon;
      colorClass = "text-red-500";
      break;
    case "Treated":
      IconComponent = CheckCircleIcon;
      colorClass = "text-green-500";
      break;
    case "Tentative":
      IconComponent = QuestionMarkCircleIcon;
      colorClass = "text-yellow-500";
      break;
    case "Invoiced":
      IconComponent = DocumentTextIcon;
      colorClass = "text-green-500";
      break;
    case "Invoice Paid":
      IconComponent = CreditCardIcon;
      colorClass = "text-green-500";
      break;
    case "Soap Notes Saved":
      IconComponent = ClipboardDocumentListIcon;
      colorClass = "text-yellow-500";
      break;
    case "Soap Notes Completed":
      IconComponent = ClipboardDocumentListIcon;
      colorClass = "text-green-500";
      break;
    case "Soap Notes Updated":
      IconComponent = ClipboardDocumentListIcon;
      colorClass = "text-orange-500";
      break;
    case "Soap Notes Approved":
      IconComponent = ClipboardDocumentCheckIcon;
      colorClass = "text-green-500";
      break;
    case "Confirmed":
      IconComponent = CheckIcon;
      colorClass = "text-blue-500";
      break;
    case "Comments":
      IconComponent = ChatBubbleBottomCenterTextIcon;
      colorClass = "text-green-500";
      break;
    case "Paid to Clinician":
      IconComponent = BanknotesIcon;
      colorClass = "text-green-500";
      break;
    case "Completed":
      IconComponent = CheckBadgeIcon;
      colorClass = "text-green-500";
      break;
    default:
      return null;
  }

  return (
    <div className="relative" key={status}>
      <IconComponent className={`h-5 w-5 ${colorClass}`} />
    </div>
  );
};

export default CalendarEvent;
