// store/thunks/blocksThunk.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { generateConfig } from "../../utils/api";
import { handleApiError } from "../../utils/apiErrorHandler";

export const createBlock = createAsyncThunk(
  "blocks/createBlock",
  async (blockData, { rejectWithValue, getState, dispatch }) => {
    const state = getState();
    const token = state.auth.token;
    const { baseURL, config } = generateConfig({ token });

    try {
      const response = await axios.post(
        `${baseURL}/api/blocks`,
        blockData,
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// Get block information
export const getBlock = createAsyncThunk(
  "blocks/getBlock",
  async (blockId, { rejectWithValue, getState, dispatch }) => {
    const state = getState();
    const token = state.auth.token;
    const { baseURL, config } = generateConfig({ token });

    try {
      const response = await axios.get(
        `${baseURL}/api/blocks/${blockId}`,
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// Append appointment to block
export const appendAppointmentToBlock = createAsyncThunk(
  "blocks/appendAppointmentToBlock",
  async ({ blockId, appointmentData }, { rejectWithValue, getState }) => {
    const state = getState();
    const token = state.auth.token;
    const { baseURL, config } = generateConfig({ token });

    try {
      const response = await axios.post(
        `${baseURL}/api/blocks/${blockId}/appendAppointment`,
        appointmentData,
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Update (modify) a block
export const updateBlock = createAsyncThunk(
  "blocks/updateBlock",
  async ({ blockId, updatedData }, { rejectWithValue, getState, dispatch }) => {
    const state = getState();
    const token = state.auth.token;
    const { baseURL, config } = generateConfig({ token });

    try {
      const response = await axios.patch(
        `${baseURL}/api/blocks/${blockId}`,
        updatedData,
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// Delete a block
export const deleteBlock = createAsyncThunk(
  "blocks/deleteBlock",
  async (blockId, { rejectWithValue, getState, dispatch }) => {
    const state = getState();
    const token = state.auth.token;
    const { baseURL, config } = generateConfig({ token });

    try {
      const response = await axios.delete(
        `${baseURL}/api/blocks/${blockId}`,
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);
