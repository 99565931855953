import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { generateConfig } from "../../utils/api";

export default function CancelAppointment() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [appointment, setAppointment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [cancellationStatus, setCancellationStatus] = useState(null);

  useEffect(() => {
    const fetchAppointment = async () => {
      try {
        const { config, baseURL } = generateConfig();
        const response = await axios.get(
          `${baseURL}/api/appointments/id/${id}`,
          config
        );
        setAppointment(response.data);
        setLoading(false);
      } catch (err) {
        setError("Unable to fetch appointment details");
        setLoading(false);
      }
    };

    fetchAppointment();
  }, [id]);

  console.log("appointment", appointment);

  const handleCancel = async () => {
    try {
      const { config, baseURL } = generateConfig();
      await axios.post(`${baseURL}/api/appointments/${id}/cancel`, {}, config);
      setCancellationStatus("success");
    } catch (err) {
      setCancellationStatus("error");
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-red-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-xl font-semibold text-red-600 mb-2">Error</h2>
          <p className="text-gray-600">{error}</p>
        </div>
      </div>
    );
  }

  if (cancellationStatus === "success") {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-md">
          <div className="text-center">
            <img
              src="https://www.hearsay.ca/wp-content/uploads/2022/03/hearsay_logo_fnl_c.jpg"
              alt="Logo"
              className="h-12 w-auto mx-auto mb-6"
            />
            <svg
              className="mx-auto h-12 w-12 text-red-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"></path>
            </svg>
            <h2 className="mt-4 text-2xl font-semibold text-gray-900">
              Appointment Cancelled
            </h2>
            <p className="mt-2 text-gray-600">
              Your appointment has been successfully cancelled.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-md">
        <img
          src="https://www.hearsay.ca/wp-content/uploads/2022/03/hearsay_logo_fnl_c.jpg"
          alt="Logo"
          className="h-12 w-auto mx-auto mb-6"
        />
        <h2 className="text-2xl font-semibold text-gray-900 mb-6">
          Cancel Your Appointment
        </h2>

        {appointment && (
          <div className="space-y-4">
            <div>
              <p className="text-sm font-medium text-gray-500">Client Name</p>
              <p className="mt-1">
                {appointment.client.firstName} {appointment.client.lastName}
              </p>
            </div>
            <div>
              <p className="text-sm font-medium text-gray-500">Date & Time</p>
              <p className="mt-1">
                {new Date(appointment.start).toLocaleDateString("en-US", {
                  weekday: "short",
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                })}{" "}
                at{" "}
                {new Date(appointment.start).toLocaleTimeString("en-US", {
                  hour: "numeric",
                  minute: "2-digit",
                })}
              </p>
            </div>

            <div>
              <p className="text-sm font-medium text-gray-500">Provider</p>
              <p className="mt-1">
                {appointment.clinician.firstName}{" "}
                {appointment.clinician.lastName}
              </p>
            </div>

            <div>
              <p className="text-sm font-medium text-gray-500">Service</p>
              <p className="mt-1">{appointment.service.description}</p>
            </div>

            <div className="pt-4">
              {new Date(appointment.start).getTime() - new Date().getTime() <
              24 * 60 * 60 * 1000 ? (
                <div>
                  <p className="text-sm text-red-500 mb-4">
                    Appointments cannot be cancelled online within 24 hours of
                    the appointment time.
                  </p>
                  <p className="text-sm text-gray-500 mb-4">
                    Please call the clinic at 905-875-3345 to discuss
                    cancellation options.
                  </p>
                  <button
                    disabled
                    className="w-full bg-gray-300 text-gray-500 py-2 px-4 rounded-md cursor-not-allowed">
                    Cancel Appointment
                  </button>
                </div>
              ) : (
                <div>
                  <p className="text-sm text-gray-500 mb-4">
                    Are you sure you want to cancel this appointment? This
                    action cannot be undone.
                  </p>
                  <button
                    onClick={handleCancel}
                    className="w-full bg-red-600 text-white py-2 px-4 rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">
                    Cancel Appointment
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
