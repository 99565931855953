import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllUsersTodos } from "../../../store/thunks/todoThunk";
import ToDoModal from "../../../modals/ToDoModal";
import {
  setSelectedUser,
  clearSelectedUser,
} from "../../../store/slices/userSlice";

const UserToDoList = React.memo(() => {
  const dispatch = useDispatch();
  const allUsersTodos = useSelector((state) => state.todos.allUsersTodos);
  const loadingAllUsersTodos = useSelector(
    (state) => state.todos.loadingAllUsersTodos
  );
  const selectedUser = useSelector((state) => state.users.selectedUser);
  const [showToDoModal, setShowToDoModal] = useState(false);

  // Fetch all user todos on mount
  useEffect(() => {
    dispatch(fetchAllUsersTodos());
  }, [dispatch]);

  // Define all categories
  const allCategories = useMemo(
    () => [
      "Treat",
      "Soap",
      "Confirm",
      "Invoice",
      "Payment",
      "Schedule",
      "Reschedule",
    ],
    []
  );

  // Transform todos into table rows
  const userRows = useMemo(() => {
    if (!allUsersTodos) return [];
    return Object.entries(allUsersTodos).map(([userId, userData]) => ({
      userId,
      firstName: userData.userDetails.firstName,
      lastName: userData.userDetails.lastName,
      role: userData.userDetails.role,
      todos: userData.todos,
    }));
  }, [allUsersTodos]);

  // Handle row click
  const handleUserClick = (userRow) => {
    if (!selectedUser || selectedUser._id !== userRow.userId) {
      dispatch(
        setSelectedUser({
          _id: userRow.userId,
          firstName: userRow.firstName,
          lastName: userRow.lastName,
          role: userRow.role,
        })
      );
      setShowToDoModal(true);
    }
  };

  if (loadingAllUsersTodos) return <div>Loading To-Do List...</div>;
  if (!allUsersTodos) return <div>No todos available</div>;

  return (
    <>
      <div className="mt-8 bg-white shadow-md rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            To-Do List
          </h3>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  User
                </th>
                {allCategories.map((category) => (
                  <th
                    key={category}
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {category}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {userRows.map((userRow) => (
                <tr
                  key={userRow.userId}
                  onClick={() => handleUserClick(userRow)}
                  className="cursor-pointer hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {`${userRow.firstName} ${userRow.lastName}`}
                  </td>
                  {allCategories.map((category) => (
                    <td
                      key={`${userRow.userId}-${category}`}
                      className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {userRow.todos[category]?.total || 0}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {showToDoModal && (
        <ToDoModal
          onClose={() => {
            setShowToDoModal(false);
            dispatch(clearSelectedUser());
          }}
        />
      )}
    </>
  );
});

export default UserToDoList;
