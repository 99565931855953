import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllAppointmentsByDateRange,
  updateAppointmentHistory,
} from "../../store/thunks/appointmentsThunk";
import {
  fetchAllPayrollsByStatusAndPayPeriod,
  updatePayroll,
} from "../../store/thunks/payrollThunk";
import PayrollTable from "../../applicationUi/components/PayrollTable";
import CompletedPayrollTable from "../../applicationUi/components/CompletedPayrollTable";
import PayrollScheduleTable from "./PayrollSchedule";
import PayrollSlideOver from "./PayrollSlideOver";
import payrollDates from "./payrollDates";
import PayrollStats from "./PayrollStats";
import CustomPayrollModal from "./CustomPayrollModal";
import StatusLights from "../../applicationUi/components/StatusLights";
import * as XLSX from "xlsx";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { weekdays } from "moment/moment";

export default function HRIndividualPayrollPage({
  clinician,
  selectedPeriod,
  isOpen,
  onClose,
}) {
  console.log("received clincian", clinician);
  console.log("IN HR INDIVIDUAL PAYROLL PAGE");

  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const [localAppointments, setLocalAppointments] = useState([]);
  const [localPayrolls, setLocalPayrolls] = useState([]);
  const [isPayrollSlideOver, setIsPayrollSlideOver] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showPayrollSchedule, setShowPayrollSchedule] = useState(false);
  const [soapNotesComplete, setSoapNotesComplete] = useState(false);
  const [invoicePaid, setInvoicePaid] = useState(false);
  const [eligibleForPayroll, setEligibleForPayroll] = useState(false);
  const [pendingAppointments, setPendingAppointments] = useState([]);
  const [completedAppointments, setCompletedAppointments] = useState([]);
  const [carryoverAppointments, setCarryoverAppointments] = useState([]);
  const [isCustomModalOpen, setIsCustomModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [regularPayrolls, setRegularPayrolls] = useState([]);
  const [customPayrolls, setCustomPayrolls] = useState([]);

  useEffect(() => {
    const filteredRegular = completedAppointments
      .flatMap((item) =>
        item.payrolls
          .filter((payroll) => !payroll.isCustom)
          .map((payroll) => ({
            ...payroll,
            clinician: item.clinician,
          }))
      )
      .sort((a, b) => new Date(a.start) - new Date(b.start));

    const filteredCustom = completedAppointments
      .flatMap((item) =>
        item.payrolls
          .filter((payroll) => payroll.isCustom)
          .map((payroll) => ({
            ...payroll,
            clinician: item.clinician,
          }))
      )
      .sort((a, b) => new Date(a.start) - new Date(b.start));

    setRegularPayrolls(filteredRegular);
    setCustomPayrolls(filteredCustom);
  }, [completedAppointments]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  // Updated fetchData function
  const fetchData = async () => {
    console.log("fetch data called");
    if (selectedPeriod && user) {
      setLoading(true);

      const [start, end] = selectedPeriod.includeDates
        .split("-")
        .map((date) => new Date(`${date.trim()}`));

      setStartDate(start);
      setEndDate(end);

      const currentDate = new Date();
      const submissionDate = new Date(`${selectedPeriod.submissionDate}`);

      try {
        // Fetch appointments
        const appointmentsResult = await dispatch(
          fetchAllAppointmentsByDateRange({ endDate: end })
        ).unwrap();

        // Extract appointments for the current clinician
        const clinicianAppointments =
          appointmentsResult[clinician._id]?.appointments || [];

        setLocalAppointments(clinicianAppointments);

        // Fetch payrolls
        const payrollsResult = await dispatch(
          fetchAllPayrollsByStatusAndPayPeriod({
            statuses: ["Pending", "Ready", "Approved", "Paid to Clinician"],
            payPeriod: selectedPeriod.payPeriodNumber,
          })
        ).unwrap();

        // Filter payrolls to only include those for the current clinician
        const clinicianPayrolls = payrollsResult.filter(
          (payroll) => payroll.clinician?._id === clinician._id
        );

        setLocalPayrolls(clinicianPayrolls);

        // Categorize appointments
        const carryoverResult =
          currentDate > submissionDate ? clinicianAppointments : [];
        const pendingResult =
          currentDate <= submissionDate ? clinicianAppointments : [];

        setCarryoverAppointments(carryoverResult);
        setPendingAppointments(pendingResult);
        setCompletedAppointments(clinicianPayrolls);

        if (selectedAppointment) {
          const updatedAppointment = clinicianAppointments.find(
            (appointment) => appointment._id === selectedAppointment._id
          );
          setSelectedAppointment(updatedAppointment);
        }
      } catch (error) {
        console.error("Fetch data error:", error);
        // Reset values in case of failure
        setCarryoverAppointments([]);
        setPendingAppointments([]);
        setCompletedAppointments([]);
      } finally {
        setLoading(false); // End loading
        console.log("loading set to false");
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedPeriod, clinician._id]);

  const exportToSpreadsheet = (localPayrolls) => {
    if (!localPayrolls || localPayrolls.length === 0) {
      alert("No data available to export.");
      return;
    }

    // Flatten the payrolls from all groups in localPayrolls
    const flattenedPayrolls = localPayrolls.flatMap((group) => group.payrolls);

    // Map the data to the desired format
    const data = flattenedPayrolls.map((payroll) => ({
      "payroll._id": payroll._id || "N/A",
      "appointment._id": payroll.appointmentId?._id || "N/A",
      "invoice._id": payroll.invoiceId?._id || "N/A",
      "Pay Period": payroll.payPeriod || "N/A",
      "Clinician First Name": payroll.clinicianId?.firstName || "N/A",
      "Clinician Last Name": payroll.clinicianId?.lastName || "N/A",
      "Appointment Title": payroll.appointmentId?.title || "N/A",
      "Appointment Start Date": payroll.appointmentId?.start
        ? new Date(payroll.appointmentId.start).toLocaleString("en-US")
        : "N/A",
      "Client First Name": payroll.appointmentId?.client?.firstName || "N/A",
      "Client Last Name": payroll.appointmentId?.client?.lastName || "N/A",
      "Invoice ID": payroll.invoiceId?.invoiceId || "N/A",
      "Invoice Amount": payroll.invoiceId?.amount || "N/A",
      "Percent Received": payroll.percentReceived || "N/A",
      "Payroll Amount": payroll.payrollAmount || "N/A",
    }));

    // Create a new workbook and worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Payroll Data");

    // Generate a dynamic file name
    const firstPayroll = flattenedPayrolls[0]; // Use the first payroll to derive the file name
    const fileName = `${firstPayroll.payPeriod || "UnknownPayPeriod"}_${
      firstPayroll.clinicianId?.firstName || "UnknownFirstName"
    }_${
      firstPayroll.clinicianId?.lastName || "UnknownLastName"
    }_payroll_data.xlsx`;

    // Generate a download
    XLSX.writeFile(workbook, fileName);
  };

  if (!clinician || !clinician._id) {
    return (
      <div className="p-4">
        <p className="text-red-500">Error: Clinician data is unavailable.</p>
      </div>
    );
  }

  const normalizeAppointmentData = (data) => {
    let appointment = data.appointmentId ? data.appointmentId : data;
    let invoice = data.invoiceId ? data.invoiceId : data.invoice;
    let payrollId = data._id;
    return { appointment, invoice, payrollId };
  };

  const handleAppointmentClick = (data) => {
    const { appointment, invoice, payrollId } = normalizeAppointmentData(data);

    console.log("appointment", appointment);
    console.log("invoice", invoice);
    console.log("payrollid", payrollId);

    if (appointment) {
      setSelectedAppointment({ appointment, invoice, payrollId });
      setIsPayrollSlideOver(true);
    } else {
      console.error("Invalid appointment object:", appointment);
    }
  };
  const handleCloseScheduleModal = () => {
    setShowPayrollSchedule(false);
  };

  const filterAppointments = () => {
    return pendingAppointments
      .filter((appointment) => {
        const hasSoapNotesComplete = appointment.history.some((historyItem) =>
          historyItem.status.includes("Soap Notes Complete")
        );
        const hasInvoicePaid =
          appointment.invoice && appointment.invoice.status.includes("paid");
        const isCancelledNoFee =
          appointment.status.includes("Cancelled - No Fee");
        const isNoShowNoFee = appointment.status.includes("No Show - No Fee");
        const isCancelled = appointment.status.includes("Cancelled");
        const isNoShow = appointment.status.includes("No Show");

        // Exclude cancelled and no show appointments with no fee
        if (isCancelledNoFee || isNoShowNoFee || isCancelled || isNoShow) {
          return false;
        }

        if (eligibleForPayroll) {
          return hasSoapNotesComplete && hasInvoicePaid;
        }
        if (soapNotesComplete && !hasSoapNotesComplete) {
          return false;
        }
        if (invoicePaid && !hasInvoicePaid) {
          return false;
        }
        return true;
      })
      .sort((a, b) => new Date(a.start) - new Date(b.start));
  };

  const getSubmissionDate = () => {
    const currentPeriod = payrollDates.find(
      (period) => period.payPeriodNumber === selectedPeriod.payPeriodNumber
    );

    if (currentPeriod) {
      const submissionDate = new Date(currentPeriod.submissionDate);
      return submissionDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
      });
    } else {
      return "N/A";
    }
  };

  const getScheduledPayDay = () => {
    const currentPeriod = payrollDates.find(
      (period) => period.payPeriodNumber === selectedPeriod.payPeriodNumber
    );

    if (currentPeriod) {
      const paymentDate = new Date(currentPeriod.paymentDate);
      return paymentDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
      });
    } else {
      return "N/A";
    }
  };

  console.log("customPayrolls", customPayrolls);
  console.log("regularPayrolls", regularPayrolls);

  const calculateIncompleteAppointments = () => {
    return pendingAppointments.filter((appointment) => {
      const hasSoapNotesComplete = appointment.history.some(
        (historyItem) => historyItem.status === "Soap Notes Complete"
      );
      const hasInvoicePaid =
        appointment.invoice && appointment.invoice.status === "paid";

      return !(hasSoapNotesComplete && hasInvoicePaid);
    }).length;
  };

  const calculatePendingAppointments = () => {
    return pendingAppointments.filter((appointment) => {
      const hasSoapNotesComplete = appointment.history.some(
        (historyItem) => historyItem.status === "Soap Notes Complete"
      );
      const hasInvoicePaid =
        appointment.invoice && appointment.invoice.status === "paid";

      return !(hasSoapNotesComplete && hasInvoicePaid);
    }).length;
  };

  const calculateCompletedAppointments = () => {
    return regularPayrolls.length + customPayrolls.length;
  };

  const calculateCarryoverAppointments = () => {
    return carryoverAppointments.length;
  };

  const calculatePendingPay = () => {
    return pendingAppointments
      .reduce((sum, appointment) => {
        const servicePrice = appointment.service.price || 0;
        const pendingPay = servicePrice * 0.45;
        return sum + pendingPay;
      }, 0)
      .toFixed(2);
  };

  const calculateCompletedPay = () => {
    // Sum up payroll amounts for regular payrolls
    const regularPayrollsTotal = regularPayrolls.reduce(
      (sum, payroll) => sum + (payroll.payrollAmount || 0),
      0
    );

    // Sum up payroll amounts for custom payrolls
    const customPayrollsTotal = customPayrolls.reduce(
      (sum, customPayroll) => sum + (customPayroll.payrollAmount || 0),
      0
    );

    // Return the total payroll amount
    return (regularPayrollsTotal + customPayrollsTotal).toFixed(2);
  };

  // Updated calculateCarryoverPay function
  const calculateCarryoverPay = () => {
    if (!Array.isArray(carryoverAppointments)) {
      console.warn(
        "carryoverAppointments is not an array:",
        carryoverAppointments
      );
      return "0.00"; // Default value to avoid errors
    }

    return carryoverAppointments
      .reduce((sum, appointment) => {
        const servicePrice = appointment.service?.price || 0;
        const pendingPay = servicePrice * 0.45;
        return sum + pendingPay;
      }, 0)
      .toFixed(2);
  };

  const toggleCustomPayrollModal = () => {
    setIsCustomModalOpen((prev) => !prev); // Toggles between true and false
  };

  const markRegularAsPaid = async () => {
    const confirmAction = window.confirm(
      `Are you sure you want to mark ${regularPayrolls.length} regular payrolls as 'Paid to Clinician'?`
    );
    if (!confirmAction) {
      return;
    }

    try {
      // Loop through regularPayrolls and update each one
      const updatePromises = regularPayrolls.map(async (payroll) => {
        if (payroll && payroll._id) {
          // Update payroll status
          await dispatch(
            updatePayroll({
              id: payroll._id,
              updateData: { status: "Paid to Clinician" },
              updatedBy: user._id,
            })
          ).unwrap();

          // Check if payroll has an associated appointmentId
          if (payroll.appointmentId) {
            await dispatch(
              updateAppointmentHistory({
                id: payroll.appointmentId._id,
                historyItem: {
                  status: "Paid to Clinician",
                  timestamp: new Date().toISOString(),
                  comments: "Appointment marked as Paid to Clinician",
                  user: user._id,
                },
              })
            ).unwrap();
          } else {
            console.warn(
              `Payroll with ID ${payroll._id} does not have an associated appointmentId. Skipping appointment update.`
            );
          }
        } else {
          console.warn("Invalid payroll object:", payroll);
        }
      });

      // Wait for all updates to complete
      await Promise.all(updatePromises);

      fetchData();

      alert(
        "All regular payrolls have been marked as 'Paid to Clinician' successfully!"
      );
    } catch (error) {
      console.error("Error updating regular payrolls or appointments:", error);
      alert(
        "An error occurred while updating regular payrolls. Please try again."
      );
    }
  };

  const markCustomAsPaid = async () => {
    const confirmAction = window.confirm(
      `Are you sure you want to mark ${customPayrolls.length} custom payrolls as 'Paid to Clinician'?`
    );
    if (!confirmAction) {
      return;
    }

    try {
      // Loop through customPayrolls and update each one
      const updatePromises = customPayrolls.map(async (payroll) => {
        if (payroll && payroll._id) {
          await dispatch(
            updatePayroll({
              id: payroll._id,
              updateData: { status: "Paid to Clinician" },
              updatedBy: user._id,
            })
          ).unwrap();
        } else {
          console.warn("Invalid payroll object:", payroll);
        }
      });

      // Wait for all updates to complete
      await Promise.all(updatePromises);

      fetchData();

      alert(
        "All custom payrolls have been marked as 'Paid to Clinician' successfully!"
      );
    } catch (error) {
      console.error("Error updating custom payrolls:", error);
      alert(
        "An error occurred while updating custom payrolls. Please try again."
      );
    }
  };

  const stats = [
    {
      name: `Pay Period ${selectedPeriod.payPeriodNumber}`,
      value: `${selectedPeriod.includeDates}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Submit Appointments By End Of",
      value: `${getSubmissionDate()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Pay Day",
      value: `${getScheduledPayDay()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Pending Appointments",
      value: `${calculatePendingAppointments()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Completed Appointments",
      value: `${calculateCompletedAppointments()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Carryover Appointments",
      value: `${calculateCarryoverAppointments()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Total Pending Pay",
      value: `$${calculatePendingPay()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Total Completed Pay",
      value: `$${calculateCompletedPay()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Total Carryover Pay",
      value: `$${calculateCarryoverPay()}`,
      change: "",
      changeType: "neutral",
    },
  ];

  const getStatus = (item) => {
    if (!Array.isArray(item.history)) {
      console.warn("Invalid or missing history for item:", item);
      return {
        statusTags: <div className="text-red-500">No history available</div>,
        isReady: false,
      };
    }

    const canceledOrNoShowStatuses = [
      "Cancelled",
      "Cancelled - No Fee",
      "Cancelled - Charge Fee",
      "No Show",
      "No Show - No Fee",
      "No Show - Charge Fee",
    ];

    // Check if the appointment has any canceled or no-show status
    const canceledOrNoShowHistory = item.history.filter((historyItem) =>
      canceledOrNoShowStatuses.includes(historyItem.status)
    );

    const hasSoapNotesComplete = item.history.some(
      (historyItem) => historyItem.status === "Soap Notes Completed"
    );

    const hasSoapNotesSaved = item.history.some(
      (historyItem) => historyItem.status === "Soap Notes Saved"
    );

    const invoiceStatus = item.invoice ? item.invoice.status : null;

    const statuses = [];
    const statusClasses = {
      "SOAP Notes: Incomplete": "text-red-700 bg-red-50 ring-red-600/20",
      "Soap Notes: Saved": "text-orange-700 bg-orange-50 ring-orange-600/20",
      "Soap Notes Completed": "text-green-700 bg-green-50 ring-green-600/20",
      "Invoice: created": "text-orange-700 bg-orange-50 ring-orange-600/20",
      "Invoice: sent": "text-blue-700 bg-blue-50 ring-blue-600/20",
      "Invoice: paid": "text-green-700 bg-green-50 ring-green-600/20",
      "Invoice: void": "text-red-700 bg-red-50 ring-red-600/20",
      "Invoice: No Invoice": "text-red-700 bg-red-50 ring-red-600/20",
      Ready: "text-green-700 bg-green-50 ring-green-600/20",
      Default: "text-red-700 bg-red-50 ring-red-600/20",
    };

    if (canceledOrNoShowHistory.length > 0) {
      // Add all canceled or no-show statuses
      statuses.push(...canceledOrNoShowHistory.map((item) => item.status));

      // If it's a canceled or no-show appointment, skip SOAP Notes requirements
      if (
        ["Cancelled - Charge Fee", "No Show - Charge Fee"].some((status) =>
          canceledOrNoShowHistory.some(
            (historyItem) => historyItem.status === status
          )
        )
      ) {
        if (!invoiceStatus) statuses.push("Invoice: No Invoice");
      }
    } else {
      // If not canceled or no-show, check SOAP Notes and Invoice
      if (hasSoapNotesComplete) {
        statuses.push("Soap Notes Completed"); // Prioritize "Soap Notes Completed"
      } else if (hasSoapNotesSaved) {
        statuses.push("Soap Notes: Saved"); // Add tag for Soap Notes Saved
      } else {
        statuses.push("SOAP Notes: Incomplete"); // Add orange tag for incomplete
      }

      if (!item.invoice) statuses.push("Invoice: No Invoice");
      else if (invoiceStatus === "created")
        statuses.push("Invoice: created"); // Orange tag for Created
      else if (invoiceStatus === "sent")
        statuses.push("Invoice: sent"); // Blue tag for Sent
      else if (invoiceStatus === "paid")
        statuses.push("Invoice: paid"); // Green tag for Paid
      else if (invoiceStatus === "void") statuses.push("Invoice: void"); // Red tag for Void

      if (hasSoapNotesComplete && invoiceStatus === "paid")
        statuses.push("Ready");
    }

    const statusTags = (
      <div className="flex flex-col items-center space-y-1">
        {statuses.map((status, index) => (
          <div
            key={index}
            className={classNames(
              statusClasses[status] || statusClasses["Default"],
              "rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset"
            )}>
            {status}
          </div>
        ))}
      </div>
    );

    return {
      statusTags,
      isReady: hasSoapNotesComplete && invoiceStatus === "paid",
    };
  };

  const getTimeUntilPayday = () => {
    const currentPeriod = payrollDates.find(
      (period) => period.payPeriodNumber === selectedPeriod.payPeriodNumber
    );

    if (currentPeriod) {
      const paymentDate = new Date(currentPeriod.paymentDate);
      const now = new Date();
      const timeDifference = paymentDate - now;

      if (timeDifference > 0) {
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((timeDifference / 1000 / 60) % 60);
        return { days, hours, minutes, isPaydayReached: false };
      } else {
        return { days: 0, hours: 0, minutes: 0, isPaydayReached: true };
      }
    } else {
      return { days: 0, hours: 0, minutes: 0, isPaydayReached: false };
    }
  };

  const { days, hours, minutes, isPaydayReached } = getTimeUntilPayday();

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        {/* Loading overlay */}
        {loading && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
            <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 border-solid"></div>
          </div>
        )}

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16 md:pl-30">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full">
                <Dialog.Panel className="pointer-events-auto w-screen max-w-8xl">
                  {/* <div className="py-4"> */}
                  <div className="px-4 flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="text-2xl font-bold leading-6 text-gray-900">
                      {clinician.firstName} {clinician.lastName}
                    </div>
                    <PayrollStats stats={stats} />
                    {/* Pay period selector and toggles */}
                    <div className="flex space-x-4 mb-4 justify-around items-center">
                      <div>
                        <button
                          type="button"
                          onClick={() => exportToSpreadsheet(localPayrolls)}
                          className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                          Export Spreadsheet for {clinician.firstName}{" "}
                          {clinician.lastName}
                        </button>
                      </div>
                      <div>
                        <button
                          type="button"
                          onClick={() => toggleCustomPayrollModal()}
                          className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                          Add Custom Payroll Item{" "}
                        </button>
                      </div>
                      <div>
                        <button
                          type="button"
                          onClick={() =>
                            setShowPayrollSchedule(!showPayrollSchedule)
                          }
                          className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                          View Payroll Schedule
                        </button>
                      </div>
                    </div>

                    {/* ALL TABLES */}
                    <div>
                      {/* TABLE FOR COMPLETED PAYROLL */}
                      <div className="border border-gray-300 rounded-lg">
                        <div>
                          {/* Table for Regular Payrolls */}
                          <div className="border border-gray-300 rounded-lg mb-6">
                            <CompletedPayrollTable
                              columns={[
                                {
                                  header: "Payroll Status",
                                  accessor: (item) => `${item.status}`,
                                },
                                {
                                  header: "Appt Status",
                                  accessor: (item) =>
                                    item.appointmentId ? (
                                      <StatusLights
                                        appointment={item.appointmentId}
                                      />
                                    ) : (
                                      "N/A"
                                    ),
                                },
                                {
                                  header: "Client",
                                  accessor: (item) =>
                                    item.appointmentId &&
                                    item.appointmentId.client
                                      ? `${item.appointmentId.client.firstName} ${item.appointmentId.client.lastName}`
                                      : "N/A",
                                },
                                {
                                  header: "Service",
                                  accessor: (item) =>
                                    item.appointmentId &&
                                    item.appointmentId.title
                                      ? item.appointmentId.title
                                      : "N/A",
                                },
                                {
                                  header: "Service Date",
                                  accessor: (item) => {
                                    if (
                                      item.appointmentId &&
                                      item.appointmentId.start
                                    ) {
                                      const date = new Date(
                                        item.appointmentId.start
                                      );
                                      const optionsDate = {
                                        year: "numeric",
                                        month: "short",
                                        weekday: "short",
                                        day: "numeric",
                                      };
                                      const optionsTime = {
                                        hour: "numeric",
                                        minute: "numeric",
                                        hour12: true,
                                      };
                                      return `${date.toLocaleDateString(
                                        "en-US",
                                        optionsDate
                                      )}, ${date.toLocaleTimeString(
                                        "en-US",
                                        optionsTime
                                      )}`;
                                    } else {
                                      return "N/A";
                                    }
                                  },
                                },
                                {
                                  header: "Invoice",
                                  accessor: (item) =>
                                    item.invoiceId && item.invoiceId.invoiceId
                                      ? item.invoiceId.invoiceId
                                      : "N/A",
                                },
                                {
                                  header: "Billed Amount",
                                  accessor: (item) =>
                                    item.originalAmount !== undefined
                                      ? `$${item.originalAmount.toFixed(2)}`
                                      : "N/A",
                                },
                                {
                                  header: "Received",
                                  accessor: (item) =>
                                    item.percentReceived !== undefined
                                      ? `${item.percentReceived}%`
                                      : "N/A",
                                },
                                {
                                  header: "Payroll Amount",
                                  accessor: (item) =>
                                    item.payrollAmount !== undefined
                                      ? `$${item.payrollAmount.toFixed(2)}`
                                      : "N/A",
                                },
                              ]}
                              data={regularPayrolls}
                              onRowClick={(item) =>
                                handleAppointmentClick(item)
                              }
                              clinicianId={null}
                              title={`Completed Appointments [${regularPayrolls.length}]`}
                              description="List of regular completed appointments, per pay period."
                              actions={[
                                {
                                  label: `Mark ${regularPayrolls.length} as Paid to Clinician`,
                                  onClick: isPaydayReached
                                    ? markRegularAsPaid
                                    : null,
                                  disabled: !isPaydayReached,
                                },
                              ]}
                              countdown={
                                !isPaydayReached &&
                                `Time until allowed: ${days}d ${hours}h ${minutes}m`
                              }
                            />
                          </div>

                          {/* Table for Custom Payrolls */}
                          <div className="border border-gray-300 rounded-lg">
                            <CompletedPayrollTable
                              columns={[
                                {
                                  header: "Payroll Status",
                                  accessor: (item) => `${item.status}`,
                                },
                                {
                                  header: "Description",
                                  accessor: (item) =>
                                    item.description ? item.description : "N/A",
                                },
                                {
                                  header: "Payroll Amount",
                                  accessor: (item) =>
                                    item.payrollAmount !== undefined
                                      ? `$${item.payrollAmount.toFixed(2)}`
                                      : "N/A",
                                },
                              ]}
                              data={customPayrolls}
                              onRowClick={(item) =>
                                handleAppointmentClick(item)
                              }
                              actions={[
                                {
                                  label: `Mark ${customPayrolls.length} as Paid to Clinician`,
                                  onClick: isPaydayReached
                                    ? markCustomAsPaid
                                    : null,
                                  disabled: !isPaydayReached,
                                },
                              ]}
                              clinicianId={null}
                              title={`Custom Payrolls [${customPayrolls.length}]`}
                              description="List of custom payrolls, per pay period."
                            />
                          </div>
                        </div>
                      </div>

                      <div className="border border-gray-300 rounded-lg mt-6">
                        {/* TABLE FOR PENDING PAYROLL */}
                        {filterAppointments().length > 0 && (
                          <PayrollTable
                            columns={[
                              {
                                header: "Appt Status",
                                accessor: (item) => getStatus(item).statusTags,
                              },
                              {
                                header: "Client",
                                accessor: (item) =>
                                  item.client
                                    ? `${item.client.firstName} ${item.client.lastName}`
                                    : "N/A",
                              },
                              {
                                header: "Service",
                                accessor: (item) =>
                                  item.service
                                    ? item.service.description
                                    : "N/A",
                              },
                              {
                                header: "Service Date",
                                accessor: (item) => {
                                  if (item.start) {
                                    const date = new Date(item.start);
                                    const optionsDate = {
                                      year: "numeric",
                                      month: "short",
                                      weekday: "short",
                                      day: "numeric",
                                    };
                                    const optionsTime = {
                                      hour: "numeric",
                                      minute: "numeric",
                                      hour12: true,
                                    };
                                    return `${date.toLocaleDateString(
                                      "en-US",
                                      optionsDate
                                    )}, ${date.toLocaleTimeString(
                                      "en-US",
                                      optionsTime
                                    )}`;
                                  }
                                  return "N/A";
                                },
                                sortBy: (item) => {
                                  if (item.start) {
                                    const date = new Date(item.start);
                                    return isNaN(date) ? null : date; // Return null if date is invalid
                                  }
                                  return null;
                                },
                              },
                              {
                                header: "Invoice ID",
                                accessor: (item) =>
                                  item.invoice
                                    ? item.invoice.invoiceId
                                    : "No Invoice",
                              },
                              {
                                header: "Invoice Amount",
                                accessor: (item) =>
                                  item.invoice
                                    ? `$${item.invoice.amount.toFixed(2)}`
                                    : "No Invoice",
                              },
                              {
                                header: "Invoice Status",
                                accessor: (item) =>
                                  item.invoice
                                    ? item.invoice.status
                                    : "No Invoice",
                              },
                              {
                                header: "Invoice Created",
                                accessor: (item) =>
                                  item.invoice
                                    ? new Date(
                                        item.invoice.createdAt
                                      ).toLocaleDateString("en-US", {
                                        year: "numeric",
                                        month: "short",
                                        weekday: "short",
                                        day: "numeric",
                                      })
                                    : "No Invoice",
                              },
                            ]}
                            data={filterAppointments()}
                            onRowClick={handleAppointmentClick}
                            actions={[]}
                            title={`Pending Appointments [${calculateIncompleteAppointments()}]`}
                            description="Select a clinician to view a list of their incomplete appointments, per pay period."
                          />
                        )}

                        {/* TABLE FOR CARRYOVER PAYROLL */}
                        {carryoverAppointments.length > 0 && (
                          <PayrollTable
                            columns={[
                              {
                                header: "Appt Status",
                                accessor: (item) => getStatus(item).statusTags,
                              },
                              // {
                              //   header: "Appointment History",
                              //   accessor: (item) =>
                              //     item.history && item.history.length > 0
                              //       ? item.history
                              //           .map((entry) => entry.status)
                              //           .join(", ") // Joins all the statuses into a single string
                              //       : "No History",
                              // },
                              {
                                header: "Client",
                                accessor: (item) =>
                                  item.client
                                    ? `${item.client.firstName} ${item.client.lastName}`
                                    : "N/A",
                              },
                              {
                                header: "Service",
                                accessor: "service.description",
                              },
                              {
                                header: "Service Date",
                                accessor: (item) => {
                                  if (item.start) {
                                    const date = new Date(item.start);
                                    const optionsDate = {
                                      year: "numeric",
                                      month: "short",
                                      weekday: "short",
                                      day: "numeric",
                                    };
                                    const optionsTime = {
                                      hour: "numeric",
                                      minute: "numeric",
                                      hour12: true,
                                    };
                                    return `${date.toLocaleDateString(
                                      "en-US",
                                      optionsDate
                                    )}, ${date.toLocaleTimeString(
                                      "en-US",
                                      optionsTime
                                    )}`;
                                  } else {
                                    return "N/A";
                                  }
                                },
                              },
                              {
                                header: "Invoice ID",
                                accessor: (item) =>
                                  item.invoice
                                    ? item.invoice.invoiceId
                                    : "No Invoice",
                              },
                              {
                                header: "Invoice Amount",
                                accessor: (item) =>
                                  item.invoice
                                    ? `$${item.invoice.amount.toFixed(2)}`
                                    : "No Invoice",
                              },
                              {
                                header: "Invoice Status",
                                accessor: (item) =>
                                  item.invoice
                                    ? item.invoice.status
                                    : "No Invoice",
                              },
                              {
                                header: "Invoice Created",
                                accessor: (item) =>
                                  item.invoice
                                    ? new Date(
                                        item.invoice.createdAt
                                      ).toLocaleDateString("en-US", {
                                        year: "numeric",
                                        month: "short",
                                        weekday: "short",
                                        day: "numeric",
                                      })
                                    : "No Invoice",
                              },
                            ]}
                            data={carryoverAppointments}
                            onRowClick={handleAppointmentClick}
                            actions={[]}
                            title={`Carryover Appointments [${calculateCarryoverAppointments()}]`}
                            description={`These are all your appointments that have not been completed.`}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Modal for custom payroll items */}
                  <CustomPayrollModal
                    clinician={clinician}
                    selectedPeriod={selectedPeriod}
                    isOpen={isCustomModalOpen}
                    onClose={toggleCustomPayrollModal}
                  />

                  {showPayrollSchedule && (
                    <div
                      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
                      onClick={handleCloseScheduleModal}
                      tabIndex={0}
                      onKeyDown={(e) => {
                        if (e.key === "Escape") handleCloseScheduleModal();
                      }}>
                      <div
                        className="bg-white rounded-lg shadow-lg p-8 max-w-4xl w-full relative"
                        onClick={(e) => e.stopPropagation()}>
                        <button
                          onClick={handleCloseScheduleModal}
                          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700">
                          <XMarkIcon className="h-6 w-6" />
                        </button>
                        <PayrollScheduleTable />
                      </div>
                    </div>
                  )}

                  {isPayrollSlideOver && selectedAppointment && (
                    <PayrollSlideOver
                      isOpen={isPayrollSlideOver}
                      appointment={selectedAppointment}
                      onClose={() => {
                        setIsPayrollSlideOver(false);
                        fetchData(); // Refresh after SlideOver closes
                      }}
                      selectedPeriod={selectedPeriod.payPeriodNumber}
                      selectedPeriodDates={selectedPeriod.includeDates}
                      isHr={true}
                      onUpdateSuccess={() => {
                        console.log(
                          "Update success from PayrollSlideOver. Refreshing..."
                        );
                        fetchData(); // Refresh after updates
                      }}
                    />
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
