const payrollDates = [
  {
    payPeriodNumber: "2024.1",
    payPeriodEndingDate: "December 21, 2023",
    includeDates: "December 8, 2023  -  December 21, 2023",
    submissionDate: "December 26, 2023",
    paymentDate: "January 5, 2024",
  },
  {
    payPeriodNumber: "2024.2",
    payPeriodEndingDate: "January 4, 2024",
    includeDates: "December 22, 2023 - January 4, 2024",
    submissionDate: "January 9, 2024",
    paymentDate: "January 19, 2024",
  },
  {
    payPeriodNumber: "2024.3",
    payPeriodEndingDate: "January 18, 2024",
    includeDates: "January 5, 2024 - January 18, 2024",
    submissionDate: "January 23, 2024",
    paymentDate: "February 2, 2024",
  },
  {
    payPeriodNumber: "2024.4",
    payPeriodEndingDate: "February 1, 2024",
    includeDates: "January 19, 2024 - February 1, 2024",
    submissionDate: "February 6, 2024",
    paymentDate: "February 16, 2024",
  },
  {
    payPeriodNumber: "2024.5",
    payPeriodEndingDate: "February 15, 2024",
    includeDates: "February 2, 2024 - February 15, 2024",
    submissionDate: "February 20, 2024",
    paymentDate: "March 1, 2024",
  },
  {
    payPeriodNumber: "2024.6",
    payPeriodEndingDate: "February 29, 2024",
    includeDates: "February 16, 2024 - February 29, 2024",
    submissionDate: "March 5, 2024",
    paymentDate: "March 15, 2024",
  },
  {
    payPeriodNumber: "2024.7",
    payPeriodEndingDate: "March 14, 2024",
    includeDates: "March 1, 2024 - March 14, 2024",
    submissionDate: "March 19, 2024",
    paymentDate: "March 28, 2024",
  },
  {
    payPeriodNumber: "2024.8",
    payPeriodEndingDate: "March 28, 2024",
    includeDates: "March 15, 2024 - March 28, 2024",
    submissionDate: "April 2, 2024",
    paymentDate: "April 12, 2024",
  },
  {
    payPeriodNumber: "2024.9",
    payPeriodEndingDate: "April 11, 2024",
    includeDates: "March 29, 2024 - April 11, 2024",
    submissionDate: "April 16, 2024",
    paymentDate: "April 26, 2024",
  },
  {
    payPeriodNumber: "2024.10",
    payPeriodEndingDate: "April 25, 2024",
    includeDates: "April 12, 2024 - April 25, 2024",
    submissionDate: "April 30, 2024",
    paymentDate: "May 10, 2024",
  },
  {
    payPeriodNumber: "2024.11",
    payPeriodEndingDate: "May 9, 2024",
    includeDates: "April 26, 2024 - May 9, 2024",
    submissionDate: "May 14, 2024",
    paymentDate: "May 24, 2024",
  },
  {
    payPeriodNumber: "2024.12",
    payPeriodEndingDate: "May 23, 2024",
    includeDates: "May 10, 2024 - May 23, 2024",
    submissionDate: "May 28, 2024",
    paymentDate: "June 7, 2024",
  },
  {
    payPeriodNumber: "2024.13",
    payPeriodEndingDate: "June 6, 2024",
    includeDates: "May 24, 2024 - June 6, 2024",
    submissionDate: "June 11, 2024",
    paymentDate: "June 21, 2024",
  },
  {
    payPeriodNumber: "2024.14",
    payPeriodEndingDate: "June 20, 2024",
    includeDates: "June 7, 2024 - June 20, 2024",
    submissionDate: "June 25, 2024",
    paymentDate: "July 5, 2024",
  },
  {
    payPeriodNumber: "2024.15",
    payPeriodEndingDate: "July 4, 2024",
    includeDates: "June 21, 2024 - July 4, 2024",
    submissionDate: "July 9, 2024",
    paymentDate: "July 19, 2024",
  },
  {
    payPeriodNumber: "2024.16",
    payPeriodEndingDate: "July 18, 2024",
    includeDates: "July 5, 2024 - July 18, 2024",
    submissionDate: "July 23, 2024",
    paymentDate: "August 2, 2024",
  },
  {
    payPeriodNumber: "2024.17",
    payPeriodEndingDate: "August 1, 2024",
    includeDates: "July 19, 2024 - August 1, 2024",
    submissionDate: "August 6, 2024",
    paymentDate: "August 16, 2024",
  },
  {
    payPeriodNumber: "2024.18",
    payPeriodEndingDate: "August 15, 2024",
    includeDates: "August 2, 2024 - August 15, 2024",
    submissionDate: "August 20, 2024",
    paymentDate: "August 30, 2024",
  },
  {
    payPeriodNumber: "2024.19",
    payPeriodEndingDate: "August 29, 2024",
    includeDates: "August 16, 2024 - August 29, 2024",
    submissionDate: "September 3, 2024",
    paymentDate: "September 13, 2024",
  },
  {
    payPeriodNumber: "2024.20",
    payPeriodEndingDate: "September 12, 2024",
    includeDates: "August 30, 2024 - September 12, 2024",
    submissionDate: "September 17, 2024",
    paymentDate: "September 27, 2024",
  },
  {
    payPeriodNumber: "2024.21",
    payPeriodEndingDate: "September 26, 2024",
    includeDates: "September 13, 2024 - September 26, 2024",
    submissionDate: "October 1, 2024",
    paymentDate: "October 11, 2024",
  },
  {
    payPeriodNumber: "2024.22",
    payPeriodEndingDate: "October 10, 2024",
    includeDates: "September 27, 2024 - October 10, 2024",
    submissionDate: "October 15, 2024",
    paymentDate: "October 25, 2024",
  },
  {
    payPeriodNumber: "2024.23",
    payPeriodEndingDate: "October 24, 2024",
    includeDates: "October 11, 2024 - October 24, 2024",
    submissionDate: "October 29, 2024",
    paymentDate: "November 8, 2024",
  },
  {
    payPeriodNumber: "2024.24",
    payPeriodEndingDate: "November 7, 2024",
    includeDates: "October 25, 2024 - November 7, 2024",
    submissionDate: "November 12, 2024",
    paymentDate: "November 22, 2024",
  },
  {
    payPeriodNumber: "2024.25",
    payPeriodEndingDate: "November 21, 2024",
    includeDates: "November 8, 2024 - November 21, 2024",
    submissionDate: "November 26, 2024",
    paymentDate: "December 6, 2024",
  },
  {
    payPeriodNumber: "2024.26",
    payPeriodEndingDate: "December 5, 2024",
    includeDates: "November 22, 2024 - December 5, 2024",
    submissionDate: "December 10, 2024",
    paymentDate: "December 20, 2024",
  },
  {
    payPeriodNumber: "2025.1",
    payPeriodEndingDate: "December 19, 2024",
    includeDates: "December 06, 2024 - December 19, 2024",
    submissionDate: "December 24, 2024",
    paymentDate: "January 03, 2025",
  },
  {
    payPeriodNumber: "2025.2",
    payPeriodEndingDate: "January 02, 2025",
    includeDates: "December 20, 2024 - January 02, 2025",
    submissionDate: "January 7, 2025",
    paymentDate: "January 17, 2025",
  },
  {
    payPeriodNumber: "2025.3",
    payPeriodEndingDate: "January 16, 2025",
    includeDates: "January 03, 2025 - January 16, 2025",
    submissionDate: "January 21, 2025",
    paymentDate: "January 31, 2025",
  },
  {
    payPeriodNumber: "2025.4",
    payPeriodEndingDate: "January 30, 2025",
    includeDates: "January 17, 2025 - January 30, 2025",
    submissionDate: "February 4, 2025",
    paymentDate: "February 14, 2025",
  },
  {
    payPeriodNumber: "2025.5",
    payPeriodEndingDate: "February 13, 2025",
    includeDates: "January 31, 2025 - February 13, 2025",
    submissionDate: "February 18, 2025",
    paymentDate: "February 28, 2025",
  },
  {
    payPeriodNumber: "2025.6",
    payPeriodEndingDate: "February 27, 2025",
    includeDates: "February 14, 2025 - February 27, 2025",
    submissionDate: "March 3, 2025",
    paymentDate: "March 14, 2025",
  },
  {
    payPeriodNumber: "2025.7",
    payPeriodEndingDate: "March 13, 2025",
    includeDates: "February 28, 2025 - March 13, 2025",
    submissionDate: "March 18, 2025",
    paymentDate: "March 28, 2025",
  },
  {
    payPeriodNumber: "2025.8",
    payPeriodEndingDate: "March 27, 2025",
    includeDates: "March 14, 2025 - March 27, 2025",
    submissionDate: "April 1, 2025",
    paymentDate: "April 11, 2025",
  },
  {
    payPeriodNumber: "2025.9",
    payPeriodEndingDate: "April 10, 2025",
    includeDates: "March 28, 2025 - April 10, 2025",
    submissionDate: "April 15, 2025",
    paymentDate: "April 25, 2025",
  },
  {
    payPeriodNumber: "2025.10",
    payPeriodEndingDate: "April 24, 2025",
    includeDates: "April 11, 2025 - April 24, 2025",
    submissionDate: "April 29, 2025",
    paymentDate: "May 09, 2025",
  },
  {
    payPeriodNumber: "2025.11",
    payPeriodEndingDate: "May 08, 2025",
    includeDates: "April 25, 2025 - May 08, 2025",
    submissionDate: "May 13, 2025",
    paymentDate: "May 23, 2025",
  },
  {
    payPeriodNumber: "2025.12",
    payPeriodEndingDate: "May 22, 2025",
    includeDates: "May 09, 2025 - May 22, 2025",
    submissionDate: "May 27, 2025",
    paymentDate: "June 06, 2025",
  },
  {
    payPeriodNumber: "2025.13",
    payPeriodEndingDate: "June 05, 2025",
    includeDates: "May 23, 2025 - June 05, 2025",
    submissionDate: "June 10, 2025",
    paymentDate: "June 20, 2025",
  },
  {
    payPeriodNumber: "2025.14",
    payPeriodEndingDate: "June 19, 2025",
    includeDates: "June 06, 2025 - June 19, 2025",
    submissionDate: "June 24, 2025",
    paymentDate: "July 04, 2025",
  },
  {
    payPeriodNumber: "2025.15",
    payPeriodEndingDate: "July 03, 2025",
    includeDates: "June 20, 2025 - July 03, 2025",
    submissionDate: "July 8, 2025",
    paymentDate: "July 18, 2025",
  },
  {
    payPeriodNumber: "2025.16",
    payPeriodEndingDate: "July 17, 2025",
    includeDates: "July 04, 2025 - July 17, 2025",
    submissionDate: "July 22, 2025",
    paymentDate: "August 01, 2025",
  },
  {
    payPeriodNumber: "2025.17",
    payPeriodEndingDate: "July 31, 2025",
    includeDates: "July 18, 2025 - July 31, 2025",
    submissionDate: "August 5, 2025",
    paymentDate: "August 15, 2025",
  },
  {
    payPeriodNumber: "2025.18",
    payPeriodEndingDate: "August 14, 2025",
    includeDates: "August 01, 2025 - August 14, 2025",
    submissionDate: "August 19, 2025",
    paymentDate: "August 29, 2025",
  },
  {
    payPeriodNumber: "2025.19",
    payPeriodEndingDate: "August 28, 2025",
    includeDates: "August 15, 2025 - August 28, 2025",
    submissionDate: "September 2, 2025",
    paymentDate: "September 12, 2025",
  },
  {
    payPeriodNumber: "2025.20",
    payPeriodEndingDate: "September 11, 2025",
    includeDates: "August 29, 2025 - September 11, 2025",
    submissionDate: "September 16, 2025",
    paymentDate: "September 26, 2025",
  },
  {
    payPeriodNumber: "2025.21",
    payPeriodEndingDate: "September 25, 2025",
    includeDates: "September 12, 2025 - September 25, 2025",
    submissionDate: "September 30, 2025",
    paymentDate: "October 10, 2025",
  },
  {
    payPeriodNumber: "2025.22",
    payPeriodEndingDate: "October 09, 2025",
    includeDates: "September 26, 2025 - October 09, 2025",
    submissionDate: "October 14, 2025",
    paymentDate: "October 24, 2025",
  },
  {
    payPeriodNumber: "2025.23",
    payPeriodEndingDate: "October 23, 2025",
    includeDates: "October 10, 2025 - October 23, 2025",
    submissionDate: "October 28, 2025",
    paymentDate: "November 07, 2025",
  },
  {
    payPeriodNumber: "2025.24",
    payPeriodEndingDate: "November 06, 2025",
    includeDates: "October 24, 2025 - November 06, 2025",
    submissionDate: "November 11, 2025",
    paymentDate: "November 21, 2025",
  },
  {
    payPeriodNumber: "2025.25",
    payPeriodEndingDate: "November 20, 2025",
    includeDates: "November 07, 2025 - November 20, 2025",
    submissionDate: "November 25, 2025",
    paymentDate: "December 05, 2025",
  },
  {
    payPeriodNumber: "2025.26",
    payPeriodEndingDate: "December 04, 2025",
    includeDates: "November 21, 2025 - December 04, 2025",
    submissionDate: "December 9, 2025",
    paymentDate: "December 19, 2025",
  },
];

export default payrollDates;
