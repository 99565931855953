import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modifyUser } from "../../../store/thunks/userThunk";
import { fetchClinics } from "../../../store/thunks/clinicThunk";

const UserClinicInformation = ({ user = {}, onComplete }) => {
  const dispatch = useDispatch();
  const clinics = useSelector((state) => state.clinics.clinics);
  const [loading, setLoading] = useState(true);
  const [saveStatus, setSaveStatus] = useState("initial");

  // Local state for managing selected clinics and default location
  const [selectedClinics, setSelectedClinics] = useState(
    user.clinics ? user.clinics.map((c) => c.clinic) : []
  );
  const [defaultLocation, setDefaultLocation] = useState(
    user.defaultLocation || ""
  );

  useEffect(() => {
    dispatch(fetchClinics()).then(() => setLoading(false));
  }, [dispatch]);

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    setSelectedClinics((prevSelected) =>
      checked
        ? [...prevSelected, value]
        : prevSelected.filter((id) => id !== value)
    );

    // If a clinic is unchecked and it was the default location, clear the default location
    if (!checked && value === defaultLocation) {
      setDefaultLocation("");
    }
  };

  const handleDefaultLocationChange = (e) => {
    const { value } = e.target;
    // Only allow setting the default location if the clinic is already selected
    if (selectedClinics.includes(value)) {
      setDefaultLocation(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSaveStatus("saving");

    const updatedClinicianData = {
      clinics: selectedClinics.map((clinicId) => ({
        clinic: clinicId,
        isDefault: clinicId === defaultLocation,
      })),
      defaultLocation,
    };

    dispatch(
      modifyUser({
        userId: user._id || user.userId,
        userData: updatedClinicianData,
      })
    )
      .then((response) => {
        setSaveStatus("saved");
        onComplete(response.payload);

        // Reset the button text after 2 seconds
        setTimeout(() => {
          setSaveStatus("initial");
        }, 2000);
      })
      .catch((error) => {
        console.error("Error saving clinician information:", error);
        setSaveStatus("error");
      });
  };

  if (loading) return <div>Loading clinics...</div>;

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Clinics
        </label>
        <div className="mt-1">
          {clinics.map((clinic) => (
            <div key={clinic._id} className="flex items-center space-x-4">
              {/* Checkbox for selecting the clinic */}
              <input
                type="checkbox"
                id={`clinic-${clinic._id}`}
                value={clinic._id}
                checked={selectedClinics.includes(clinic._id)}
                onChange={handleCheckboxChange}
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              />
              <label htmlFor={`clinic-${clinic._id}`} className="text-sm">
                {clinic.name}
              </label>

              {/* Radio button for setting as default location */}
              <input
                type="radio"
                name="defaultLocation"
                value={clinic._id}
                checked={defaultLocation === clinic._id}
                onChange={handleDefaultLocationChange}
                disabled={!selectedClinics.includes(clinic._id)}
                className={`h-4 w-4 ${
                  selectedClinics.includes(clinic._id)
                    ? "text-indigo-600 focus:ring-indigo-500 border-gray-300"
                    : "text-gray-300 cursor-not-allowed"
                }`}
              />
              <label className="text-sm text-gray-500">
                Set as Default Location
              </label>
            </div>
          ))}
        </div>
      </div>

      <div className="pt-5 flex justify-end">
        <button
          type="submit"
          disabled={saveStatus === "saving"}
          className={`ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white 
            ${
              saveStatus === "saving"
                ? "bg-indigo-400"
                : saveStatus === "saved"
                ? "bg-green-600"
                : saveStatus === "error"
                ? "bg-red-600"
                : "bg-indigo-600 hover:bg-indigo-700"
            } 
            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
        >
          {saveStatus === "saving"
            ? "Saving..."
            : saveStatus === "saved"
            ? "Clinic Information Saved!"
            : saveStatus === "error"
            ? "Error Saving"
            : "Save Clinic Information"}
        </button>
      </div>
    </form>
  );
};

export default UserClinicInformation;
