import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { convertInvoiceToSplit, fetchInvoicesByAppointmentId } from "../../store/thunks/invoiceThunk";
import CreateInvoice from "./CreateInvoice";

export default function ConvertInvoice({ invoice, onClose }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [numSplits, setNumSplits] = useState(2);
  const invoiceRefs = useRef([]);

  // Initialize refs array when numSplits changes
  React.useEffect(() => {
    if (invoiceRefs.current.length < numSplits) {
      invoiceRefs.current = [
        ...invoiceRefs.current,
        ...Array(numSplits - invoiceRefs.current.length).fill(null).map(() => React.createRef()),
      ];
    } else if (invoiceRefs.current.length > numSplits) {
      invoiceRefs.current = invoiceRefs.current.slice(0, numSplits);
    }
    console.log("Updated invoiceRefs:", invoiceRefs.current);
  }, [numSplits]);
  

  const handleSubmit = async () => {
    console.log("Refs before getInvoiceData calls:", invoiceRefs.current);

    const invoiceDataArray = invoiceRefs.current
      .map((ref, index) => {
        try {
          const data = ref?.current?.getInvoiceData();
          console.log(`Invoice data for ref ${index}:`, data);
          return data;
        } catch (error) {
          console.error(`Error fetching data for ref ${index}:`, error);
          return null;
        }
      })
      .filter(Boolean);
  
    console.log("Final Invoice Data Array:", invoiceDataArray);
  
    if (invoiceDataArray.length !== numSplits) {
      console.error("Validation Error: Not all forms are filled.");
      return;
    }

    try {
      setIsLoading(true);
      const [childInvoiceData, ...parentInvoiceDataArray] = invoiceDataArray;

      const parentInvoices = parentInvoiceDataArray.map(parentData => ({
        ...parentData,
        splitBilling: {
          rootInvoiceId: invoice._id,
          isChild: false,
          isParent: true,
          isSplit: true,
        },
      }));

      const payload = {
        invoice,
        childInvoiceData: {
          ...childInvoiceData,
          splitBilling: {
            rootInvoiceId: invoice._id,
            isChild: true,
            isParent: false,
            isSplit: true,
          },
        },
        parentInvoiceDataArray: parentInvoices,
      };
      console.log("Payload:", payload);
      await dispatch(convertInvoiceToSplit(payload));
      await dispatch(fetchInvoicesByAppointmentId(invoice.appointmentId));
      setIsSuccess(true);
    } catch (error) {
      console.error("Error converting invoice to split:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <h3 className="text-lg font-semibold mb-4">Convert Invoice to Split</h3>

      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Number of Ways to Split:</label>
        <input
          type="number"
          min="2"
          max="10"
          className="mt-1 block w-48 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          value={numSplits}
          onChange={(e) => setNumSplits(Math.max(2, parseInt(e.target.value) || 2))}
        />
      </div>

      <div className="bg-white rounded-lg shadow-md p-6 mb-6">
        <h4 className="text-md font-medium mb-2">Child Invoice</h4>
        <CreateInvoice
          ref={invoiceRefs.current[0]}
          appointment={{ _id: invoice.appointmentId }}
          isSplit
          isChild={true}
        />
      </div>

      {Array.from({ length: numSplits - 1 }, (_, index) => (
        <div key={index} className="bg-white rounded-lg shadow-md p-6 mb-6">
          <h4 className="text-md font-medium mb-2">Parent Invoice {index + 1}</h4>
          <CreateInvoice
            ref={invoiceRefs.current[index + 1]}
            appointment={{ _id: invoice.appointmentId }}
            isSplit
            isParent={true}
            requireParentSelection={true}
          />
        </div>
      ))}

      <button
        onClick={handleSubmit}
        disabled={isLoading || isSuccess}
        className={`mt-6 px-4 py-2 rounded ${isLoading
            ? "bg-gray-400"
            : isSuccess
              ? "bg-green-600 hover:bg-green-600"
              : "bg-indigo-600 hover:bg-indigo-700"
          } text-white`}
      >
        {isLoading
          ? "Converting..."
          : isSuccess
            ? "Invoices Converted"
            : "Submit Split Invoice"}
      </button>
    </div>
  );
}
