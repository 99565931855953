import { createSlice } from "@reduxjs/toolkit";
import { fetchAllUsersTodos, fetchTodos } from "../thunks/todoThunk";

const initialState = {
  todos: {
    Treat: [],
    Soap: [],
    Confirm: [],
    Invoice: [],
    Payment: [],
    Schedule: [],
    Reschedule: []
  },
  allUsersTodos: {}, // Default to empty object
  selectedUserTodos: null,
  loadingAllUsersTodos: false,
  loadingSelectedUserTodos: false,
  error: null
};

const todoSlice = createSlice({
  name: "todos",
  initialState,
  reducers: {
    clearSelectedUserTodos: (state) => {
      state.selectedUserTodos = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllUsersTodos.pending, (state) => {
        state.loadingAllUsersTodos = true;
        state.error = null;
      })
      .addCase(fetchAllUsersTodos.fulfilled, (state, action) => {
        state.allUsersTodos = action.payload;
        state.loadingAllUsersTodos = false;
      })
      .addCase(fetchAllUsersTodos.rejected, (state, action) => {
        state.loadingAllUsersTodos = false;
        state.error = action.error.message;
      })
      .addCase(fetchTodos.pending, (state) => {
        state.loadingSelectedUserTodos = true;
        state.error = null;
      })
      .addCase(fetchTodos.fulfilled, (state, action) => {
        state.todos = action.payload; // General todos
        state.selectedUserTodos = { ...action.payload }; // Separate copy for selected user
        state.todos.Treat = action.payload.Treat?.length ? action.payload.Treat : null;
        state.todos.Soap = action.payload.Soap?.length ? action.payload.Soap : null;
        state.todos.Confirm = action.payload.Confirm?.length ? action.payload.Confirm : null;
        state.todos.Invoice = action.payload.Invoice?.length ? action.payload.Invoice : null;
        state.todos.Payment = action.payload.Payment?.length ? action.payload.Payment : null;
        state.todos.Schedule = action.payload.Schedule?.length ? action.payload.Schedule : null;
        state.todos.Reschedule = action.payload.Reschedule?.length ? action.payload.Reschedule : null;
        state.loadingSelectedUserTodos = false;
      })
      .addCase(fetchTodos.rejected, (state, action) => {
        state.loadingSelectedUserTodos = false;
        state.error = action.error.message;
      });
  },
});

export const { clearSelectedUserTodos } = todoSlice.actions;
export default todoSlice.reducer;
