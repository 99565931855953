import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { fetchClinicianHoursReport } from '../../../../store/thunks/reportsThunk';

function ClinicianHoursReport() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [reportData, setReportData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const dispatch = useDispatch();

  const handleGenerateReport = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const data = await dispatch(fetchClinicianHoursReport({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString()
      })).unwrap();
      setReportData(data);
    } catch (err) {
      setError(err.message || 'Failed to generate report');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="p-6 max-w-7xl mx-auto">
      <div className="bg-white rounded-lg shadow p-6 mb-6">
        <h2 className="text-2xl font-bold mb-4">Clinician Hours Report</h2>
        
        <div className="flex gap-4 mb-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Start Date</label>
            <DatePicker
              selected={startDate}
              onChange={date => setStartDate(date)}
              className="border rounded p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">End Date</label>
            <DatePicker
              selected={endDate}
              onChange={date => setEndDate(date)}
              className="border rounded p-2"
            />
          </div>
          <button
            onClick={handleGenerateReport}
            disabled={isLoading}
            className={`self-end px-4 py-2 rounded-md text-white ${
              isLoading ? 'bg-gray-400' : 'bg-indigo-600 hover:bg-indigo-700'
            }`}
          >
            {isLoading ? 'Generating...' : 'Generate Report'}
          </button>
        </div>

        {error && (
          <div className="text-red-600 text-sm mb-4">{error}</div>
        )}

        {reportData && (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Clinician</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Treatment Hours</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Cancelled</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">No Shows</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Dropoffs</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Future Appts</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {reportData.map((clinician, i) => (
                  <tr key={i} className={i % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{clinician.name}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{clinician.treatmentHours}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{clinician.cancelled}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{clinician.noShows}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{clinician.dropoffs}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{clinician.futureAppointments}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}

export default ClinicianHoursReport;
