import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { generateConfig } from "../../utils/api";
import { handleApiError } from "../../utils/apiErrorHandler";

// Fetch all hourly payrolls for pay period for all userId's
export const fetchAllHourlyPayrolls = createAsyncThunk(
  "hourlyPayroll/fetchAll",
  async (payPeriod, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.get(
        `${baseURL}/api/hourlyPayroll/all/${payPeriod}`,
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue);
    }
  }
);

// Fetch hourly payroll for userId and payPeriod
export const fetchHourlyPayroll = createAsyncThunk(
  "hourlyPayroll/fetch",
  async ({ userId, payPeriod }, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.get(
        `${baseURL}/api/hourlyPayroll/${userId}/${payPeriod}`,
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue);
    }
  }
);

export const createHourlyPayroll = createAsyncThunk(
  "hourlyPayroll/create",
  async ({ userId, payPeriod, hours }, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.post(
        `${baseURL}/api/hourlyPayroll`,
        {
          userId,
          payPeriod,
          hours,
          createdBy: userId,
        },
        config
      );

      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue);
    }
  }
);

export const updateHourlyPayroll = createAsyncThunk(
  "hourlyPayroll/update",
  async (
    { userId, payPeriod, hours, status, updatedBy },
    { rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.patch(
        `${baseURL}/api/hourlyPayroll/${userId}/${payPeriod}`,
        { hours, status, updatedBy },
        config
      );

      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue);
    }
  }
);

// Submit hourly payroll for userId and payPeriod
export const submitHourlyPayroll = createAsyncThunk(
  "hourlyPayroll/submit",
  async ({ userId, payPeriod, updatedBy }, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.patch(
        `${baseURL}/api/hourlyPayroll/submit/${userId}/${payPeriod}`,
        { updatedBy },
        config
      );

      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue);
    }
  }
);
