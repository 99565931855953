import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { generateConfig } from "../../../utils/api";
import SearchDropdown from "../SearchDropdown";
import { rescheduleAppointment } from "../../../store/store";

export default function RescheduleComponent({
  appointmentId,
  clientView,
  onReschedule,
  onClose,
}) {
  const dispatch = useDispatch();
  const [appointment, setAppointment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [rescheduleStatus, setRescheduleStatus] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");
  const [newDateTime, setNewDateTime] = useState("");
  const [availableSlots, setAvailableSlots] = useState([]);
  const [availability, setAvailability] = useState([]);
  const [clinicianAppointments, setClinicianAppointments] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [daysWithSlots, setDaysWithSlots] = useState(new Set());
  const { config, baseURL } = generateConfig();
  const [isEditingClinician, setIsEditingClinician] = useState(false);
  const [clinicianSearch, setClinicianSearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedClinician, setSelectedClinician] = useState(null);

  const handleClinicianSearch = async (e) => {
    setClinicianSearch(e.target.value);
    if (e.target.value.length >= 2) {
      try {
        const response = await axios.get(
          `${baseURL}/api/clinicians/search?query=${e.target.value}`,
          config
        );
        setSearchResults(response.data);
      } catch (err) {
        console.error("Error searching clinicians:", err);
      }
    } else {
      setSearchResults([]);
    }
  };

  const handleClinicianSelect = async (clinician) => {
    setSelectedClinician(clinician);
    setIsEditingClinician(false);
    setClinicianSearch("");
    setSearchResults([]);

    // Fetch new clinician's appointments and availability
    try {
      const clinicianAppointments = await axios.get(
        `${baseURL}/api/appointments/clinician/id/${clinician._id}`,
        config
      );
      setClinicianAppointments(clinicianAppointments.data);

      const clinicianResponse = await axios.get(
        `${baseURL}/api/clinicians/${clinician._id}`,
        config
      );
      const availability = clinicianResponse.data.availability.find(
        (a) => a.clinic === appointment.clinic._id
      );
      if (!availability) {
        setError("No availability found for this clinic");
        return;
      }
      setAvailability(availability.schedule);

      // Reset selected date and time
      setSelectedDate("");
      setNewDateTime("");
      setAvailableSlots([]);
    } catch (err) {
      setError("Unable to fetch clinician schedule");
    }
  };

  useEffect(() => {
    const fetchAppointment = async () => {
      if (!appointmentId) {
        setError("No appointment ID provided");
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(
          `${baseURL}/api/appointments/id/${appointmentId}`,
          config
        );
        if (!response.data) {
          setError("Appointment not found");
          setLoading(false);
          return;
        }
        setAppointment(response.data);
        setSelectedDate(
          new Date(response.data.start).toISOString().split("T")[0]
        );
        setLoading(false);

        // Fetch availability after appointment is loaded
        if (response.data?.clinician?._id) {
          try {
            const clinicianAppointments = await axios.get(
              `${baseURL}/api/appointments/clinician/id/${response.data.clinician._id}`,
              config
            );
            if (!clinicianAppointments.data) {
              setError("No availability data found");
              return;
            }
            setClinicianAppointments(clinicianAppointments.data);
          } catch (err) {
            setError("Unable to fetch clinician availability");
            setLoading(false);
          }
          try {
            const clinicianResponse = await axios.get(
              `${baseURL}/api/clinicians/${response.data.clinician._id}`,
              config
            );
            const availability = clinicianResponse.data.availability.find(
              (a) => a.clinic === response.data.clinic._id
            );
            if (!availability) {
              setError("No availability found for this clinic");
              setLoading(false);
              return;
            }
            setAvailability(availability.schedule);
          } catch (err) {
            setError("Unable to fetch clinician availability");
            setLoading(false);
          }
        }
      } catch (err) {
        setError("Unable to fetch appointment details");
        setLoading(false);
      }
    };

    fetchAppointment();
  }, [appointmentId]);

  useEffect(() => {
    if (!availability || !clinicianAppointments || !appointment) return;

    const availableDays = new Set();
    const year = currentMonth.getFullYear();
    const month = currentMonth.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate();

    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, month, day);
      const dayOfWeek = date.toLocaleDateString("en-US", { weekday: "long" });
      const dailySchedule = availability[dayOfWeek] || [];

      if (dailySchedule.length > 0) {
        const dateString = date.toISOString().split("T")[0];
        const appointmentsOnDate = clinicianAppointments.filter((apt) => {
          const aptDate = new Date(apt.start).toISOString().split("T")[0];
          return aptDate === dateString && apt._id !== appointment._id;
        });

        // Check if there are any available slots on this day
        let hasAvailableSlot = false;
        dailySchedule.forEach((schedule) => {
          const [startHour, startMinute] = schedule.start.split(":");
          let currentTime = new Date(date);
          currentTime.setHours(parseInt(startHour), parseInt(startMinute), 0);

          const [endHour, endMinute] = schedule.end.split(":");
          const endTime = new Date(date);
          endTime.setHours(parseInt(endHour), parseInt(endMinute), 0);

          const appointmentDuration =
            new Date(appointment.end).getTime() -
            new Date(appointment.start).getTime();

          while (currentTime < endTime) {
            const slotEndTime = new Date(
              currentTime.getTime() + appointmentDuration
            );

            const hasConflict = appointmentsOnDate.some((apt) => {
              const aptStart = new Date(apt.start);
              const aptEnd = new Date(apt.end);
              return (
                (currentTime >= aptStart && currentTime < aptEnd) ||
                (slotEndTime > aptStart && slotEndTime <= aptEnd) ||
                (currentTime <= aptStart && slotEndTime >= aptEnd)
              );
            });

            if (!hasConflict && slotEndTime <= endTime) {
              hasAvailableSlot = true;
              break;
            }
            currentTime = new Date(currentTime.getTime() + 15 * 60000); // 15 minute intervals
          }
        });

        if (hasAvailableSlot) {
          availableDays.add(dateString);
        }
      }
    }

    setDaysWithSlots(availableDays);
  }, [currentMonth, availability, clinicianAppointments, appointment]);

  useEffect(() => {
    if (
      !selectedDate ||
      !availability ||
      !clinicianAppointments ||
      !appointment
    )
      return;

    const [year, month, day] = selectedDate.split("-").map(Number);
    const selectedDateObj = new Date(year, month - 1, day);

    // Map day numbers to day names
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const dayOfWeek = daysOfWeek[selectedDateObj.getUTCDay()]; // Use UTC to align with EST clinic hours

    const dailySchedule = (availability[dayOfWeek] || []).filter(
      (schedule) => schedule.start && schedule.end
    );

    if (dailySchedule.length === 0) {
      console.warn(`No valid schedule found for ${dayOfWeek}`);
      setAvailableSlots([]);
      return;
    }

    // Filter appointments to the selected date
    const appointmentsOnDate = clinicianAppointments.filter((apt) => {
      const aptDateISO = new Date(apt.start).toISOString().split("T")[0];
      return aptDateISO === selectedDate && apt._id !== appointment._id;
    });

    const slots = [];
    dailySchedule.forEach((schedule) => {
      // Convert EST clinic hours to UTC
      const [startHour, startMinute] = schedule.start.split(":").map(Number);
      const [endHour, endMinute] = schedule.end.split(":").map(Number);

      let currentTime = new Date(selectedDateObj);
      currentTime.setUTCHours(startHour + 5, startMinute, 0); // Convert EST to UTC (+5 hours for standard time)

      const endTime = new Date(selectedDateObj);
      endTime.setUTCHours(endHour + 5, endMinute, 0);

      const appointmentDuration =
        new Date(appointment.end).getTime() -
        new Date(appointment.start).getTime();
      const intervalMs = 15 * 60 * 1000;

      while (currentTime < endTime) {
        const slotEndTime = new Date(
          currentTime.getTime() + appointmentDuration
        );

        // Check for conflicts with existing appointments
        const hasConflict = appointmentsOnDate.some((apt) => {
          const aptStart = new Date(apt.start).getTime();
          const aptEnd = new Date(apt.end).getTime();
          const slotStart = currentTime.getTime();
          const slotEnd = slotEndTime.getTime();

          return (
            (slotStart >= aptStart && slotStart < aptEnd) || // Slot starts during an appointment
            (slotEnd > aptStart && slotEnd <= aptEnd) || // Slot ends during an appointment
            (slotStart <= aptStart && slotEnd >= aptEnd) // Slot fully overlaps an appointment
          );
        });

        if (!hasConflict && slotEndTime <= endTime) {
          slots.push({ startTime: new Date(currentTime) });
        }

        currentTime = new Date(currentTime.getTime() + intervalMs);
      }
    });

    setAvailableSlots(slots);
  }, [selectedDate, availability, clinicianAppointments, appointment]);

  const handleReschedule = async () => {
    if (!newDateTime) {
      setError("Please select a new date and time");
      return;
    }

    const isSlotAvailable = availableSlots.some(
      (slot) =>
        new Date(slot.startTime).toISOString() ===
        new Date(newDateTime).toISOString()
    );

    if (!isSlotAvailable) {
      setError("Selected time slot is not available");
      return;
    }

    try {
      const newStartTime = new Date(newDateTime);
      const appointmentDuration =
        new Date(appointment.end).getTime() -
        new Date(appointment.start).getTime();
      const newEndTime = new Date(newStartTime.getTime() + appointmentDuration);

      // Create a copy of the appointment object and update relevant fields
      const payload = {
        ...appointment,
        start: newStartTime.toISOString(),
        end: newEndTime.toISOString(),
      };

      if (selectedClinician) {
        payload.clinician = selectedClinician._id;
      }

      dispatch(rescheduleAppointment(payload));
      setRescheduleStatus("success");
    } catch (err) {
      setRescheduleStatus("error");
    }
  };

  console.log("Selected Date", selectedDate);

  const renderCalendar = () => {
    const year = currentMonth.getFullYear();
    const month = currentMonth.getMonth();
    const firstDay = new Date(year, month, 1).getDay();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const weeks = [];
    let days = [];

    // Add empty cells for days before the first of the month
    for (let i = 0; i < firstDay; i++) {
      days.push(<td key={`empty-${i}`} className="p-2"></td>);
    }

    // Add days of the month
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, month, day).toISOString().split("T")[0];
      const isAvailable = daysWithSlots.has(date);
      const isSelected = date === selectedDate;

      days.push(
        <td key={day} className="p-2">
          <button
            onClick={() => setSelectedDate(date)}
            disabled={!isAvailable}
            className={`w-full p-2 rounded-full
                            ${isSelected ? "bg-yellow-600 text-white" : ""}
                            ${
                              isAvailable
                                ? "bg-green-50 hover:bg-yellow-100 border-2 border-green-500"
                                : "bg-gray-100 opacity-50 cursor-not-allowed"
                            }
                            ${
                              isAvailable
                                ? "text-green-700 font-medium"
                                : "text-gray-400"
                            }
                        `}>
            {day}
            {isAvailable && (
              <span className="block text-xs text-green-600">Available</span>
            )}
          </button>
        </td>
      );

      if ((firstDay + day) % 7 === 0 || day === daysInMonth) {
        weeks.push(<tr key={day}>{days}</tr>);
        days = [];
      }
    }

    return weeks;
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-yellow-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-xl font-semibold text-red-600 mb-2">Error</h2>
          <p className="text-gray-600">{error}</p>
        </div>
      </div>
    );
  }

  if (rescheduleStatus === "success") {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-md">
          <div className="text-center">
            <svg
              className="mx-auto h-12 w-12 text-green-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"></path>
            </svg>
            <h2 className="mt-4 text-2xl font-semibold text-gray-900">
              Appointment Rescheduled!
            </h2>
            <p className="mt-2 text-gray-600">
              Your appointment has been successfully rescheduled.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full">
      <div className="w-full bg-white p-8 rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold text-gray-900 mb-6">
          Reschedule Your Appointment
        </h2>

        {appointment && (
          <div className="space-y-6">
            <div className="grid grid-cols-2 gap-6">
              <div className="space-y-4">
                <div>
                  <p className="text-sm font-medium text-gray-500">Client</p>
                  <p className="mt-1 text-gray-900 font-medium">
                    {appointment.client.firstName} {appointment.client.lastName}
                  </p>
                </div>

                <div>
                  <p className="text-sm font-medium text-gray-500">
                    Appointment Type
                  </p>
                  <p className="mt-1 text-gray-900">
                    {appointment.service.description}
                  </p>
                </div>

                <div>
                  <p className="text-sm font-medium text-gray-500">
                    Current Date & Time
                  </p>
                  <p className="mt-1 text-gray-900">
                    {new Date(appointment.start).toLocaleDateString("en-US", {
                      weekday: "short",
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })}{" "}
                    at{" "}
                    {new Date(appointment.start).toLocaleTimeString("en-US", {
                      hour: "numeric",
                      minute: "2-digit",
                    })}
                  </p>
                </div>
              </div>

              <div>
                <p className="text-sm font-medium text-gray-500">Provider</p>
                {!clientView ? (
                  isEditingClinician ? (
                    <div className="mt-1">
                      <div className="max-w-md mt-2">
                        <SearchDropdown
                          prefix=":clinician:"
                          displayFields={[
                            "firstName",
                            "lastName",
                            "qualifications",
                          ]}
                          onSelection={handleClinicianSelect}
                          placeholder="Search for a clinician"
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="mt-1 flex items-center">
                      <p className="mr-2">
                        {selectedClinician
                          ? `${selectedClinician.firstName} ${selectedClinician.lastName}`
                          : `${appointment.clinician.firstName} ${appointment.clinician.lastName}`}
                      </p>
                      <button
                        onClick={() => setIsEditingClinician(true)}
                        className="text-yellow-600 hover:text-yellow-700">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor">
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                          />
                        </svg>
                      </button>
                    </div>
                  )
                ) : (
                  <div className="mt-1">
                    <p>
                      {appointment.clinician.firstName}{" "}
                      {appointment.clinician.lastName}
                    </p>
                  </div>
                )}
              </div>
            </div>

            <div className="border rounded-lg p-4">
              <div className="flex justify-between items-center mb-4">
                <button
                  onClick={() =>
                    setCurrentMonth(
                      new Date(
                        currentMonth.getFullYear(),
                        currentMonth.getMonth() - 1
                      )
                    )
                  }
                  className="p-2 hover:bg-gray-100 rounded-full">
                  &lt;
                </button>
                <h3 className="text-lg font-medium">
                  {currentMonth.toLocaleDateString("en-US", {
                    month: "short",
                    year: "numeric",
                  })}
                </h3>
                <button
                  onClick={() =>
                    setCurrentMonth(
                      new Date(
                        currentMonth.getFullYear(),
                        currentMonth.getMonth() + 1
                      )
                    )
                  }
                  className="p-2 hover:bg-gray-100 rounded-full">
                  &gt;
                </button>
              </div>
              <table className="w-full">
                <thead>
                  <tr>
                    {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map(
                      (day) => (
                        <th key={day} className="p-2 text-gray-600">
                          {day}
                        </th>
                      )
                    )}
                  </tr>
                </thead>
                <tbody>{renderCalendar()}</tbody>
              </table>
            </div>

            {selectedDate && (
              <div className="pt-4">
                <h4 className="text-sm font-medium text-gray-700 mb-3">
                  Available Time Slots
                </h4>
                <div className="grid grid-cols-4 gap-3">
                  {availableSlots.map((slot, index) => (
                    <button
                      key={index}
                      onClick={() =>
                        setNewDateTime(slot.startTime.toISOString())
                      }
                      className={`p-3 text-sm rounded-lg border-2 transition-colors
                                                ${
                                                  slot.startTime.toISOString() ===
                                                  newDateTime
                                                    ? "bg-yellow-600 text-white border-yellow-600"
                                                    : "bg-white text-gray-700 border-gray-300 hover:border-yellow-500"
                                                }
                                            `}>
                      {slot.startTime.toLocaleTimeString("en-US", {
                        hour: "numeric",
                        minute: "2-digit",
                      })}
                    </button>
                  ))}
                </div>
              </div>
            )}

            <div className="flex space-x-4">
              <button
                onClick={handleReschedule}
                disabled={!newDateTime}
                className="flex-1 bg-yellow-600 text-white py-2 px-4 rounded-md hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed">
                Reschedule Appointment
              </button>

              {!clientView && (
                <button
                  onClick={onClose}
                  className="flex-1 bg-gray-100 text-gray-700 py-2 px-4 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
                  Back
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
