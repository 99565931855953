import React from "react";
import { useSelector } from "react-redux";

const AppointmentButtonContainer = ({ appointment, onButtonClick }) => {
  const userRole = useSelector((state) => state.auth.user.role);

  // All possible statuses
  const statusEnums = [
    "Booked",
    "Changed",
    "Cancelled",
    "Cancelled - No Fee",
    "Cancelled - Charge Fee",
    "No Show",
    "No Show - No Fee",
    "No Show - Charge Fee",
    "Tentative",
    "Confirmed",
    "Treated",
    "Completed",
    "Invoiced",
    "Invoice Paid",
    "Soap Notes Saved",
    "Soap Notes Completed",
    "Soap Notes Updated",
    "Soap Notes Approved",
    "Comments",
    "Paid to Clinician",
    "Client notified via email",
    "Carryover",
    "Payment Posted to QuickBooks",
    "Invoice Posted to QuickBooks",
  ];

  // Define button rules based on conditions
  const buttonRules = [
    {
      label: "Cancel",
      action: "cancel",
      condition: () =>
        !appointment.status.some((status) =>
          [
            "Cancelled",
            "Cancelled - No Fee",
            "Cancelled - Charge Fee",
            "No Show",
            "No Show - No Fee",
            "No Show - Charge Fee",
            "Treated", // Added condition to exclude "Treated" status
            "Invoiced",
          ].includes(status)
        ),
      className: "bg-red-600 hover:bg-red-400",
    },
    {
      label: "No Show",
      action: "noShow",
      condition: () =>
        !appointment.status.some((status) =>
          [
            "Cancelled",
            "Cancelled - No Fee",
            "Cancelled - Charge Fee",
            "No Show",
            "No Show - No Fee",
            "No Show - Charge Fee",
            "Treated",
            "Invoiced",
          ].includes(status)
        ),
      className: "bg-red-600 hover:bg-red-400",
    },
    {
      label: "Modify Appointment",
      action: "modifyAppointment",
      condition: () =>
        !appointment.status.some((status) =>
          [
            "Completed",
            "Cancelled",
            "Cancelled - No Fee",
            "Cancelled - Charge Fee",
            "No Show",
            "No Show - No Fee",
            "No Show - Charge Fee",
            "Treated",
            "Invoiced",
          ].includes(status)
        ),
      className: "bg-indigo-600 hover:bg-indigo-500",
    },
    {
      label: "Reschedule Appointment",
      action: "rescheduleAppointment",
      condition: () =>
        appointment.status.some((status) =>
          ["Changed", "Booked", "Tentative", "Confirmed"].includes(status)
        ),
      className: "bg-indigo-600 hover:bg-indigo-500",
    },
    {
      label: "Modify Block",
      action: "modifyBlock",
      condition: () => true,
      className: "bg-indigo-600 hover:bg-indigo-500",
    },
    {
      label: "SOAP Notes",
      action: "createSOAPNote",
      condition: () =>
        appointment.history.some((entry) => entry.status === "Treated"),
      className: "bg-indigo-600 hover:bg-indigo-500",
      onClick: (action) => {
        const currentDate = new Date();
        const appointmentStart = new Date(appointment.start);

        if (currentDate < appointmentStart) {
          // Format the appointment start date for display
          const formattedStartDate = `${appointmentStart.toLocaleDateString(
            "en-US",
            {
              year: "numeric",
              month: "long",
              day: "numeric",
            }
          )}, ${appointmentStart.toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: true,
          })}`;

          // Show warning alert
          const proceed = window.confirm(
            `Warning: Appointment start date of ${formattedStartDate} has not been reached yet. Are you sure you want to continue?`
          );

          if (!proceed) {
            return; // User clicked Cancel, do not proceed
          }
        }

        onButtonClick(action); // Proceed with the action
      },
    },
    {
      label: "Revert Treated",
      action: "revertTreated",
      condition: () =>
        appointment.history.some((entry) => entry.status === "Treated") &&
        !appointment.history.some((entry) => entry.status === "Invoiced"),
      className: "bg-yellow-300 hover:bg-yellow-200",
    },
  ];

  // Status transitions for Phase 1 buttons
  const statusTransitions = {
    Booked: ["Tentative"],
    Tentative: ["Confirmed"],
    Confirmed: ["Treated"],
    Carryover: ["Tentative"],
    Treated: [],
    Cancelled: ["Tentative", "Confirmed"],
    NoShow: [],
    Changed: ["Tentative"],
    Completed: [],
  };

  // Get next possible statuses based on the current status
  const nextStatuses = statusTransitions[appointment.status] || [];

  return (
    <div className="flex flex-col space-y-2">
      {/* Main Buttons */}
      <div className="flex space-x-2 overflow-x-auto">
        {buttonRules.map(
          ({ label, action, condition, className, onClick }, index) =>
            condition() && (
              <button
                key={index}
                className={`rounded-md px-2 py-1 text-sm font-semibold text-white shadow-sm ${className}`}
                onClick={() => {
                  if (onClick) {
                    onClick(action);
                  } else {
                    onButtonClick(action);
                  }
                }}>
                {label}
              </button>
            )
        )}
      </div>

      {/* Status Transition Buttons */}
      {nextStatuses.length > 0 && (
        <div className="flex space-x-2 overflow-x-auto">
          {nextStatuses.map((status, index) => (
            <button
              key={index}
              className="rounded-md bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
              onClick={() => onButtonClick(`status-${status}`)}>
              Mark as: {status}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default AppointmentButtonContainer;
