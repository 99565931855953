import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import RescheduleComponent from "./appointments/RescheduleComponent";

export default function RescheduleModal({
  isOpen,
  onClose,
  onReschedule,
  rescheduleStatus,
  rescheduleError,
  appointmentId,
  handleReschedule,
}) {
  if (!isOpen) return null;

  return (
    <div className="modal fixed inset-0 z-60 overflow-auto bg-black bg-opacity-50 flex">
      <div className="bg-white rounded-lg p-6 mx-auto my-auto max-w-7xl w-full relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <h3 className="text-lg font-semibold mb-4">Reschedule Appointment</h3>
        <RescheduleComponent
          appointmentId={appointmentId}
          onReschedule={handleReschedule}
          clientView={false}
        />
      </div>
    </div>
  );
}
