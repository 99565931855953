import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../../applicationUi/components/Table";
import { format } from "date-fns";
import { fetchClientDropoffReport } from "../../../../store/thunks/reportsThunk";
import ClientModal from "../../../../modals/ClientModal";

const ClientDropoffReport = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: null
  });
  const [selectedClient, setSelectedClient] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const itemsPerPage = 10;
  const { data: clients, loading, error } = useSelector((state) => state.reports.clientDropoff);
  console.log(clients)
  useEffect(() => {
    dispatch(fetchClientDropoffReport());
  }, [dispatch]);

  const columns = [
    {
      header: "Client Name",
      accessor: "name",
      sortable: true
    },
    {
      header: "Last Appointment", 
      accessor: (row) => row.lastAppointment ? format(new Date(row.lastAppointment), 'MM/dd/yyyy') : 'Never',
      sortable: true
    },
    {
      header: "Days Since Last Visit",
      accessor: "daysSinceLastVisit",
      cell: (row) => row.daysSinceLastVisit || 'N/A',
      sortable: true
    },
    {
      header: "Future Appointments",
      accessor: (row) => row.hasFutureAppointment ? "Yes" : "No",
      sortable: false
    }
  ];

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  // Calculate pagination
  const totalItems = Array.isArray(clients) ? clients.length : 0;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;

  // Sort data
  let sortedData = [...(clients || [])];
  if (sortConfig.key && sortConfig.direction) {
    sortedData.sort((a, b) => {
      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];

      if (sortConfig.key === 'lastAppointment') {
        aValue = a.lastAppointment ? new Date(a.lastAppointment) : new Date(0);
        bValue = b.lastAppointment ? new Date(b.lastAppointment) : new Date(0);
      }

      if (sortConfig.key === 'daysSinceLastVisit') {
        aValue = a.daysSinceLastVisit || Number.MAX_SAFE_INTEGER;
        bValue = b.daysSinceLastVisit || Number.MAX_SAFE_INTEGER;
      }

      if (aValue < bValue) return sortConfig.direction === 'ascending' ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === 'ascending' ? 1 : -1;
      return 0;
    });
  }

  const paginatedData = sortedData.slice(startIndex, startIndex + itemsPerPage);

  const handleRowClick = (row) => {
    console.log("row", row);
    setSelectedClient(row);
    setIsModalOpen(true);
  };

  return (
    <div className="space-y-4">
      <div className="bg-white shadow-sm rounded-lg">
        <Table
          columns={columns}
          data={paginatedData}
          title="Client Dropoff Report"
          description="View clients and their appointment history"
          showPagination={true}
          currentPage={currentPage}
          totalPages={totalPages}
          totalResults={totalItems}
          onPageChange={handlePageChange}
          sortConfig={sortConfig}
          onSortChange={handleSort}
          onRowClick={(row) => handleRowClick(row)}
        />
      </div>
      {selectedClient && (
        <ClientModal
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setSelectedClient(null);
          }}
          clientId={selectedClient.id}
        />
      )}
    </div>
  );
};

export default ClientDropoffReport;
