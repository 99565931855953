import FutureAppointments from "../../applicationUi/components/FutureAppointments";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedAppointment } from "../../store/slices/appointmentsSlice";
import { fetchAppointmentsByClientId } from "../../store/thunks/appointmentsThunk";
import { useState, useEffect } from "react";
import AppointmentSummary from "../../applicationUi/components/appointments/AppointmentSummary";

const ClientAppointments = () => {
  const dispatch = useDispatch();
  const clientAppointments = useSelector((state) => state.appointments.clientAppointments);
  const client = useSelector((state) => state.clients.selectedClient);
  const selectedAppointment = useSelector((state) => state.appointments.selectedAppointment);

  useEffect(() => {
    if (client?._id) {
      dispatch(fetchAppointmentsByClientId(client._id));
    }
  }, [dispatch, client]);

  return (
    <>
      {/* Future Appointments Component */}
      <FutureAppointments
        appointments={clientAppointments}
      />
    </>
  );
};

export default ClientAppointments;
