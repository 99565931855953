import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import MultiSelect from "../applicationUi/components/MultiSelect";
import { fetchClinicians } from "../store/thunks/cliniciansThunk";
import {
  checkMeetingConflicts,
  createMeeting,
  updateMeetingThunk,
  deleteMeetingThunk,
} from "../store/thunks/meetingsThunk";
import "react-datepicker/dist/react-datepicker.css";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Dialog } from "@headlessui/react";

// Helper function to round time to nearest 5 minutes
const roundTimeToNearest5Minutes = (time) => {
  const [hours, minutes] = time.split(":").map(Number);
  const roundedMinutes = Math.round(minutes / 5) * 5;
  return `${hours.toString().padStart(2, "0")}:${roundedMinutes
    .toString()
    .padStart(2, "0")}`;
};

const QuickAddMeeting = ({
  isOpen,
  onClose,
  // For new meetings
  initialDate,
  initialTime,
  initialClinicianFname,
  initialClinicianLname,
  selectedClinician,
  selectedClinic,
  // For existing meetings
  selectedMeeting,
}) => {
  const dispatch = useDispatch();
  const clinicians = useSelector((state) => state.clinicians.clinicians);
  const user = useSelector((state) => state.auth.user);
  const isUpdating = Boolean(selectedMeeting);

  console.log(
    "[QUICKADDMEETING] - This is the selected meeting:",
    selectedMeeting
  );

  // Initialize state based on whether we're creating or updating
  const [selectedDate, setSelectedDate] = useState(
    isUpdating
      ? new Date(selectedMeeting.date)
      : initialDate
      ? new Date(initialDate)
      : new Date()
  );

  const [selectedClinicians, setSelectedClinicians] = useState(
    isUpdating
      ? selectedMeeting.attendees.map((attendee) => ({
          value: attendee._id,
          label: `${attendee.firstName} ${attendee.lastName}`,
        }))
      : selectedClinician
      ? [
          {
            value: selectedClinician._id,
            label: `${selectedClinician.firstName} ${selectedClinician.lastName}`,
          },
        ]
      : []
  );

  const [meetingTitle, setMeetingTitle] = useState(
    isUpdating ? selectedMeeting.title : ""
  );
  const [meetingDescription, setMeetingDescription] = useState(
    isUpdating ? selectedMeeting.description : ""
  );
  const [meetingDuration, setMeetingDuration] = useState(
    isUpdating ? selectedMeeting.duration : 60
  );
  const [meetingTime, setMeetingTime] = useState(
    isUpdating
      ? new Date(selectedMeeting.start).toLocaleTimeString("en-US", {
          hour12: false,
          hour: "2-digit",
          minute: "2-digit",
        })
      : initialTime
      ? roundTimeToNearest5Minutes(initialTime)
      : ""
  );

  useEffect(() => {
    dispatch(fetchClinicians());
  }, [dispatch]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleClinicianChange = (selectedOptions) => {
    setSelectedClinicians(selectedOptions);
  };

  const handleTimeChange = (e) => {
    const roundedTime = roundTimeToNearest5Minutes(e.target.value);
    setMeetingTime(roundedTime);
  };

  const handleRemoveClinician = (clinicianId) => {
    setSelectedClinicians((prev) =>
      prev.filter((c) => c.value !== clinicianId)
    );
  };

  const handleBookAllDay = (event) => {
    event.preventDefault(); // Prevent unintended form submissions
    setMeetingTime("08:00"); // Set start time to 08:00
    setMeetingDuration(720); // Set duration to 12 hours
  };

  const handleSave = async (event) => {
    event.preventDefault();

    const clinicId = isUpdating ? selectedMeeting.clinic : selectedClinic?._id;

    if (!clinicId) {
      console.error("Clinic is not selected or invalid.");
      alert("Please select a clinic before saving the meeting.");
      return;
    }

    const meetingData = {
      clinic: clinicId,
      title: meetingTitle,
      date: selectedDate,
      startTime: meetingTime,
      duration: meetingDuration,
      attendees: selectedClinicians.map((clinician) => clinician.value),
      description: meetingDescription,
      createdBy: user._id,
    };

    const conflictCheckResult = await dispatch(
      checkMeetingConflicts(meetingData)
    );

    if (conflictCheckResult.payload?.conflicts?.length > 0) {
      if (
        !window.confirm(
          `${conflictCheckResult.payload.message}. Do you want to proceed?`
        )
      ) {
        return;
      }
    }

    try {
      if (isUpdating) {
        await dispatch(
          updateMeetingThunk({
            id: selectedMeeting._id,
            updatedData: meetingData,
          })
        );
      } else {
        await dispatch(createMeeting(meetingData));
      }
      onClose();
    } catch (error) {
      console.error(
        `Failed to ${isUpdating ? "update" : "create"} meeting:`,
        error
      );
    }
  };

  const handleDelete = async () => {
    if (!isUpdating) return;

    if (window.confirm("Are you sure you want to delete this meeting?")) {
      try {
        await dispatch(deleteMeetingThunk(selectedMeeting._id));
        onClose();
      } catch (error) {
        console.error("Failed to delete meeting:", error);
      }
    }
  };

  if (!isOpen) return null;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className="fixed inset-0 z-60 overflow-y-auto">
      <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
        <Dialog.Overlay className="fixed inset-0 bg-gray-900 opacity-50" />
        <div className="relative transform overflow-hidden rounded-lg bg-white px-6 pb-6 pt-5 text-left shadow-xl transition-all sm:my-12 sm:w-full sm:max-w-4xl sm:p-8">
          <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
            <button
              type="button"
              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={onClose}>
              <span className="sr-only">Close</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="sm:flex sm:items-start">
            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
              <Dialog.Title
                as="h3"
                className="text-xl font-semibold leading-6 text-gray-900 mb-6">
                {isUpdating ? "Update Meeting" : "Create New Meeting"}
              </Dialog.Title>
              <div className="mt-4">
                <form onSubmit={handleSave}>
                  <div className="space-y-12">
                    <div className="border-b border-gray-900/10 pb-12">
                      <div className="mt-10 grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-6">
                        <div className="sm:col-span-6">
                          <label
                            htmlFor="attendees"
                            className="block text-sm font-medium leading-6 text-gray-900">
                            Attendees
                          </label>
                          <div className="mt-2 flex flex-wrap gap-2">
                            {selectedClinicians.map((clinician) => (
                              <span
                                key={clinician.value}
                                className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-indigo-100 text-indigo-800">
                                {clinician.label}
                                <button
                                  type="button"
                                  className="ml-2 text-red-500 hover:text-red-700"
                                  onClick={() =>
                                    handleRemoveClinician(clinician.value)
                                  }>
                                  &times;
                                </button>
                              </span>
                            ))}
                          </div>
                        </div>
                        <div className="sm:col-span-6">
                          <label
                            htmlFor="clinicians"
                            className="block text-sm font-medium leading-6 text-gray-900">
                            Clinicians
                          </label>
                          <div className="mt-2">
                            <MultiSelect
                              options={clinicians.map((clinician) => ({
                                value: clinician._id,
                                label: `${clinician.firstName} ${clinician.lastName}`,
                              }))}
                              selectedValues={selectedClinicians}
                              onChange={handleClinicianChange}
                              placeholder="Select clinicians..."
                              className="w-full"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="meeting-title"
                            className="block text-sm font-medium leading-6 text-gray-900">
                            Meeting Title
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              name="meeting-title"
                              id="meeting-title"
                              value={meetingTitle}
                              onChange={(e) => setMeetingTitle(e.target.value)}
                              className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="meeting-description"
                            className="block text-sm font-medium leading-6 text-gray-900">
                            Description
                          </label>
                          <div className="mt-2">
                            <textarea
                              name="meeting-description"
                              id="meeting-description"
                              value={meetingDescription}
                              onChange={(e) =>
                                setMeetingDescription(e.target.value)
                              }
                              rows="4"
                              className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>

                        {/* Date Column */}
                        <div className="sm:col-span-2">
                          <label
                            htmlFor="meeting-date"
                            className="block text-sm font-medium leading-6 text-gray-900">
                            Date
                          </label>
                          <div className="mt-2">
                            <DatePicker
                              selected={selectedDate}
                              onChange={handleDateChange}
                              dateFormat="MMMM d, yyyy"
                              className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>

                          <button
                            type="button" // Prevent default form submission behavior
                            className={`rounded-md bg-blue-500 px-4 py-2 text-sm font-semibold text-white mt-4 hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600`}
                            onClick={handleBookAllDay}
                            disabled={!selectedDate}>
                            Book All Day (8AM - 8PM)
                          </button>
                        </div>

                        {/* Time Column */}
                        <div className="sm:col-span-2">
                          <label
                            htmlFor="meeting-time"
                            className="block text-sm font-medium leading-6 text-gray-900">
                            Time (nearest 5 minutes)
                          </label>
                          <div className="mt-2">
                            <input
                              type="time"
                              name="meeting-time"
                              id="meeting-time"
                              value={meetingTime}
                              onChange={handleTimeChange}
                              className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              step="300" // 5-minute increments
                            />
                          </div>
                        </div>

                        {/* Duration Column */}
                        <div className="sm:col-span-2">
                          <label
                            htmlFor="meeting-duration"
                            className="block text-sm font-medium leading-6 text-gray-900">
                            Duration (nearest 5 minutes)
                          </label>
                          <div className="mt-2">
                            <input
                              type="number"
                              name="meeting-duration"
                              id="meeting-duration"
                              value={meetingDuration}
                              onChange={(e) =>
                                setMeetingDuration(
                                  Math.max(
                                    5,
                                    Math.round(e.target.value / 5) * 5
                                  )
                                )
                              }
                              className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              step="5"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-8 flex items-center justify-between gap-x-6">
                    {isUpdating && (
                      <button
                        type="button"
                        onClick={handleDelete}
                        className="rounded-md bg-red-400 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-600">
                        Delete Meeting
                      </button>
                    )}
                    <div
                      className={`flex items-center gap-x-6 ${
                        !isUpdating ? "ml-auto" : ""
                      }`}>
                      <button
                        type="button"
                        onClick={onClose}
                        className="text-sm font-semibold leading-6 text-gray-900">
                        Cancel
                      </button>
                      <button
                        type="submit" // This ensures the form dispatches only on submit
                        className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500">
                        {isUpdating ? "Update Meeting" : "Create Meeting"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default QuickAddMeeting;
