import React from "react";
import Pagination from "./Pagination";
import { TrashIcon } from "@heroicons/react/24/outline";
import { ChevronUpIcon, ChevronDownIcon, ArrowsUpDownIcon } from "@heroicons/react/24/outline";

const Table = ({
  columns,
  data = [], // Default to an empty array if data is undefined or null
  onRowClick,
  onDeleteRow, // New prop for delete functionality
  actions = [], // Default to an empty array if actions is undefined or null
  title,
  description,
  showPagination,
  currentPage,
  totalPages,
  onPageChange,
  onSortChange,
  totalResults,
  sortConfig,
}) => {

  // Helper function to get nested property
  const getNestedProperty = (obj, path) => {
    if (typeof path === "function") {
      // If the accessor is a function, call it with the object (row)
      return path(obj);
    }

    // If the accessor is a string, use it to get the nested property
    return path
      .split(/[\.\[\]]/)
      .filter(Boolean)
      .reduce((acc, part) => acc && acc[part], obj);
  };

  // Helper function to render cell content
  const renderCellContent = (item, column) => {
    const value = getNestedProperty(item, column.accessor);
    if (column.accessor === "color") {
      return (
        <div className="flex items-center">
          <div
            className="w-6 h-6 rounded-full mr-2"
            style={{ backgroundColor: value }}></div>
        </div>
      );
    }
    return value;
  };

  // Sorting function
  const requestSort = (key, sortable) => {
    console.log("[TABLE] Requesting sort for:", key, "with sortable:", sortable);
    if (!sortable || !onSortChange) return;
  
    let direction = "ascending";
    if (sortConfig?.key === key && sortConfig?.direction === "ascending") {
      direction = "descending";
    }
  
    onSortChange(key); // Only pass the key, let parent handle direction logic
  };
  

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            {title}
          </h1>
          <p className="mt-2 text-sm text-gray-700">{description}</p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none flex space-x-2">
          {actions.map((action, index) => (
            <button
              key={index}
              type="button"
              onClick={action.onClick}
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              {action.label}
            </button>
          ))}
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    {columns.map((column) => (
                      <th
                        key={column.accessor}
                        scope="col"
                        className={`px-3 py-3.5 text-left text-sm font-semibold text-gray-900 ${column.sortable ? 'cursor-pointer group hover:bg-gray-100' : ''}`}
                        onClick={() => requestSort(column.accessor, column.sortable)}>
                        <div className="flex items-center">
                          {column.header}
                          {column.sortable && (
                            <span className="ml-2 flex-none rounded text-gray-400">
                              {sortConfig?.key === column.accessor ? (
                                sortConfig.direction === "ascending" ? (
                                  <ChevronUpIcon className="h-4 w-4" />
                                ) : (
                                  <ChevronDownIcon className="h-4 w-4" />
                                )
                              ) : (
                                <ArrowsUpDownIcon className="h-4 w-4" />
                              )}
                            </span>
                          )}
                        </div>
                      </th>
                    ))}
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {data.map((item) => (
                    <tr
                      key={item._id}
                      className="cursor-pointer hover:bg-gray-100">
                      {columns.map((column) => (
                        <td
                          key={column.accessor}
                          onClick={() => onRowClick(item._id)}
                          className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {renderCellContent(item, column)}
                        </td>
                      ))}
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <button
                          onClick={() => onRowClick(item._id)}
                          className="text-indigo-600 hover:text-indigo-900 mr-4">
                          View
                          <span className="sr-only">, {item._id}</span>
                        </button>
                        {onDeleteRow && (
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              onDeleteRow(item._id);
                            }}
                            className="text-red-600 hover:text-red-900">
                            <TrashIcon className="h-5 w-5" aria-hidden="true" />
                            <span className="sr-only">Delete {item._id}</span>
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {showPagination && (
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={onPageChange}
                  totalResults={totalResults}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
