import React from "react";

const HourlyPayrollCalendar = ({ includeDates, dailyHours, onHoursChange }) => {
  const [startDateString, endDateString] = includeDates.split("-");
  const startDate = new Date(Date.parse(startDateString.trim()));
  const endDate = new Date(Date.parse(endDateString.trim()));

  const days = [];
  for (
    let date = new Date(startDate);
    date <= endDate;
    date.setDate(date.getDate() + 1)
  ) {
    days.push(new Date(date));
  }

  const startDayOfWeek = startDate.getDay();
  const weeks = [];
  let currentWeek = new Array(startDayOfWeek).fill(null);

  days.forEach((date) => {
    currentWeek.push(date);
    if (currentWeek.length === 7) {
      weeks.push(currentWeek);
      currentWeek = [];
    }
  });

  if (currentWeek.length > 0) {
    while (currentWeek.length < 7) {
      currentWeek.push(null);
    }
    weeks.push(currentWeek);
  }

  const weekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const calculateTotalHours = () => {
    return Object.values(dailyHours).reduce(
      (total, day) => total + (day.regular || 0),
      0
    );
  };

  const totalHours = calculateTotalHours();

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden">
      <div className="bg-indigo-600 text-white px-6 py-3">
        {/* <h3 className="text-lg font-semibold">Payroll Calendar</h3> */}
        <p className="text-md font-bold">{includeDates}</p>
      </div>
      <table className="min-w-full border-t border-gray-200">
        <thead className="bg-gray-50">
          <tr>
            {weekDays.map((day) => (
              <th
                key={day}
                className="py-3 px-4 text-center text-sm font-semibold text-gray-600 uppercase tracking-wide">
                {day}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {weeks.map((week, weekIndex) => (
            <tr key={weekIndex}>
              {week.map((date, dayIndex) => (
                <td
                  key={dayIndex}
                  className={`py-4 px-4 text-center align-top ${
                    date ? "bg-white" : "bg-gray-50"
                  } border border-gray-200 hover:bg-gray-100 transition-colors duration-150`}>
                  {date ? (
                    <div>
                      <div className="text-sm font-semibold text-gray-800">
                        {date.getDate()}
                      </div>
                      <input
                        type="number"
                        placeholder="Hours"
                        className="mt-2 block w-full text-sm px-2 py-1 border rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                        value={
                          dailyHours[new Date(date).toISOString().split("T")[0]]
                            ?.regular || ""
                        }
                        onChange={(e) =>
                          onHoursChange(date, "regular", e.target.value)
                        }
                      />
                      <input
                        type="text"
                        placeholder="Notes"
                        className="mt-2 block w-full text-sm px-2 py-1 border rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                        value={
                          dailyHours[new Date(date).toISOString().split("T")[0]]
                            ?.notes || ""
                        }
                        onChange={(e) =>
                          onHoursChange(date, "notes", e.target.value)
                        }
                      />
                    </div>
                  ) : (
                    <div className="h-16"></div>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="bg-gray-50 px-6 py-4 text-right">
        <p className="text-sm font-medium text-gray-700">
          Total Hours:{" "}
          <span className="text-indigo-600 font-bold">{totalHours}</span>
        </p>
      </div>
    </div>
  );
};

export default HourlyPayrollCalendar;
