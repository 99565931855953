import React from "react";
import ReportButton from "../../applicationUi/components/ReportButton"; // Adjust the path if necessary
import { DocumentIcon, CreditCardIcon, ChartBarIcon } from '@heroicons/react/24/outline';

function ReportList({ onSelectReport }) {
    return (
        <div className="p-4 space-y-4">
            <section id="revenue" className="bg-white p-4 rounded-lg shadow-md">
                <h2 className="text-xl font-semibold mb-2">Revenue</h2>
                <p>This section includes reports related to clinic revenue.</p>
                <ReportButton
                    reportName="Revenue Report"
                    icon={<DocumentIcon className="h-5 w-5" />}
                    onClick={() => onSelectReport("Revenue Report")}
                />
            </section>

            <section id="clients" className="bg-white p-4 rounded-lg shadow-md">
                <h2 className="text-xl font-semibold mb-2">Clients</h2>
                <p>This section includes reports related to clinic clients.</p>
                <div className="flex flex-wrap gap-4">
                    <div className="flex-shrink-0">
                        <ReportButton
                            reportName="Clients Report"
                            icon={<DocumentIcon className="h-5 w-5" />}
                            onClick={() => onSelectReport("Clients Report")}
                        />
                    </div>
                    <div className="flex-shrink-0">
                        <ReportButton
                            reportName="Active Clients Report"
                            icon={<DocumentIcon className="h-5 w-5" />}
                            onClick={() => onSelectReport("Active Clients Report")}
                        />
                    </div>
                    <div className="flex-shrink-0">
                        <ReportButton
                            reportName="Clients Dropoff Report"
                            icon={<DocumentIcon className="h-5 w-5" />}
                            onClick={() => onSelectReport("Clients Dropoff Report")}
                        />
                    </div>
                    <div className="flex-shrink-0">
                        <ReportButton
                            reportName="Block Therapy Report"
                            icon={<DocumentIcon className="h-5 w-5" />}
                            onClick={() => onSelectReport("Block Therapy Report")}
                        />
                    </div>
                    <div className="flex-shrink-0">
                        <ReportButton
                            reportName="Client Statement"
                            icon={<DocumentIcon className="h-5 w-5" />}
                            onClick={() => onSelectReport("Client Statement")}
                        />
                    </div>
                </div>
            </section>

            <section id="clinicians" className="bg-white p-4 rounded-lg shadow-md">
                <h2 className="text-xl font-semibold mb-2">Clinicians</h2>
                <p>This section includes various clinician-related reports.</p>
                <div className="flex flex-wrap gap-2">
                    <ReportButton
                        reportName="Clinician Schedule Report"
                        icon={<DocumentIcon className="h-5 w-5" />}
                        onClick={() => onSelectReport("Clinician Schedule Report")}
                    />
                    <ReportButton
                        reportName="Clinician Payroll Report"
                        icon={<DocumentIcon className="h-5 w-5" />}
                        onClick={() => onSelectReport("Clinician Payroll Report")}
                    />
                    <ReportButton
                        reportName="Clinician Hours Report"
                        icon={<DocumentIcon className="h-5 w-5" />}
                        onClick={() => onSelectReport("Clinician Hours Report")}
                    />
                </div>
            </section>

            <section id="collections" className="bg-white p-4 rounded-lg shadow-md">
                <h2 className="text-xl font-semibold mb-2">Collections</h2>
                <p>This section includes reports related to clinic collections.</p>
                <ReportButton
                    reportName="Collections Report"
                    icon={<CreditCardIcon className="h-5 w-5" />}
                    onClick={() => onSelectReport("Collections Report")}
                />
            </section>

            <section id="clinic-performance" className="bg-white p-4 rounded-lg shadow-md">
                <h2 className="text-xl font-semibold mb-2">Clinic Performance</h2>
                <p>This section includes reports related to overall clinic performance.</p>
                <ReportButton
                    reportName="Clinic Performance Report"
                    icon={<ChartBarIcon className="h-5 w-5" />}
                    onClick={() => onSelectReport("Clinic Performance Report")}
                />
            </section>
        </div>
    );
}

export default ReportList;
