import { createSlice } from "@reduxjs/toolkit";
import {
  fetchHourlyPayroll,
  createHourlyPayroll,
  updateHourlyPayroll,
  submitHourlyPayroll,
} from "../thunks/hourlyPayrollThunk";

const hourlyPayrollSlice = createSlice({
  name: "hourlyPayroll",
  initialState: {
    payroll: null,
    loading: false,
    error: null,
  },
  reducers: {
    resetHourlyPayroll: (state) => {
      state.payroll = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHourlyPayroll.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchHourlyPayroll.fulfilled, (state, action) => {
        state.loading = false;
        state.payroll = action.payload;
      })
      .addCase(fetchHourlyPayroll.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createHourlyPayroll.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createHourlyPayroll.fulfilled, (state, action) => {
        state.loading = false;
        state.payroll = action.payload;
      })
      .addCase(createHourlyPayroll.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateHourlyPayroll.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateHourlyPayroll.fulfilled, (state, action) => {
        state.loading = false;
        state.payroll = action.payload;
      })
      .addCase(updateHourlyPayroll.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(submitHourlyPayroll.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(submitHourlyPayroll.fulfilled, (state, action) => {
        state.loading = false;
        state.payroll = action.payload;
      })
      .addCase(submitHourlyPayroll.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { resetHourlyPayroll } = hourlyPayrollSlice.actions;

export default hourlyPayrollSlice.reducer;
