import React, { useState, useEffect } from "react";
import Table from "../../applicationUi/components/Table";
import { logInfo } from "../../utils/logger";
import InvoiceGenerator from "../../applicationUi/components/InvoiceGenerator";
import { useDispatch, useSelector } from "react-redux";
import { fetchInvoiceById } from "../../store/thunks/invoiceThunk";
import { clearCurrentInvoice } from "../../store/slices/invoiceSlice";
import { fetchAppointmentById } from "../../store/thunks/appointmentsThunk";
import { fetchPaymentsByInvoice } from "../../store/store";
import ViewInvoicePDFModal from "../../modals/ViewInvoicePDFModal";

const ClientInvoices = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [showInvoiceGenerator, setShowInvoiceGenerator] = useState(false);
  const itemsPerPage = 10; // Adjust as needed
  const dispatch = useDispatch();
  const currentInvoice = useSelector((state) => state.invoices.currentInvoice);
  const client = useSelector((state) => state.clients.selectedClient);

  if (!client) {
    return (
      <div className="p-4 text-gray-600">Loading client information...</div>
    );
  }

  const invoices = client.invoices || [];
  console.log("invoices", invoices);
  const totalInvoices = invoices.length;
  const totalPages = Math.ceil(totalInvoices / itemsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    logInfo("Invoice page changed", { newPage });
  };

  const handleInvoiceGeneratorClose = () => {
    setShowInvoiceGenerator(false);
    dispatch(clearCurrentInvoice());
  };

  const handleRowClick = async (clickedInvoice) => {
    console.log("Clicked invoice", clickedInvoice);
    const invoice = await dispatch(fetchInvoiceById(clickedInvoice)).unwrap();
    console.log("Current invoice", invoice);
    console.log("Current invoice appointmentId", invoice.appointmentId);
  
    await dispatch(fetchAppointmentById(invoice.appointmentId));
    await dispatch(fetchPaymentsByInvoice(invoice._id));
    setShowInvoiceGenerator(true);
  };

  const paginatedInvoices = invoices.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <>
      <Table
        columns={[
          {
            header: "Invoice ID",
            accessor: "invoiceId",
          },
          {
            header: "Service Date",
            accessor: (row) => {
              const date = new Date(row.serviceDate);
              return date.toLocaleDateString("en-US", {
                weekday: 'short',
                year: "numeric",
                month: "short",
                day: "numeric",
              });
            },
          },
          {
            header: "Invoice Date",
            accessor: (row) => {
              const date = new Date(row.createdAt);
              return date.toLocaleDateString("en-US", {
                year: "numeric",
                weekday: 'short',
                month: "short",
                day: "numeric",
              });
            },
          },
          {
            header: "Client Name",
            accessor: () =>
              `${client.firstName || "Unknown"} ${client.lastName || ""}`,
          },
          {
            header: "Clinician",
            accessor: (row) =>
              row.clinician
                ? `${row.clinician.firstName || "Unknown"} ${
                    row.clinician.lastName || ""
                  }`
                : "Unknown",
          },
          {
            header: "Amount",
            accessor: (row) => `$${row.amount?.toFixed(2) || "0.00"}`,
          },
          {
            header: "Split Invoice?",
            accessor: (row) =>
              row.splitBilling && row.splitBilling.isSplit ? "Yes" : "No",
          },
          {
            header: "Status",
            accessor: "status",
          },
        ]}
        data={paginatedInvoices}
        onRowClick={(clickedInvoice) => handleRowClick(clickedInvoice)}
        title="Client Invoices"
        description={`List of invoices for ${client.firstName} ${client.lastName} (${totalInvoices} total)`}
        showPagination={true}
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
      {showInvoiceGenerator && currentInvoice && (
        <ViewInvoicePDFModal 
        onClose={handleInvoiceGeneratorClose} 
        source="invoice" 
        data={{ invoiceId: currentInvoice._id }} 
      />
      
      )}
    </>
  );
};

export default ClientInvoices;
