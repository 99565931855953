import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import payrollDates from "./payrollDates";
import HourlyPayrollCalendar from "./HourlyPayrollCalendar";
import { Listbox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import {
  fetchHourlyPayroll,
  createHourlyPayroll,
  updateHourlyPayroll,
  submitHourlyPayroll,
} from "../../store/thunks/hourlyPayrollThunk";

export default function HourlyPayrollPage() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [currentDate] = useState(new Date());
  const [selectedPeriod, setSelectedPeriod] = useState(findCurrentPayPeriod());
  const [dailyHours, setDailyHours] = useState({});
  const [timeRemaining, setTimeRemaining] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isNewPayroll, setIsNewPayroll] = useState(false);
  const [payrollStatus, setPayrollStatus] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await dispatch(
          fetchHourlyPayroll({
            userId: user._id,
            payPeriod: selectedPeriod.payPeriodNumber,
          })
        );

        if (result?.payload) {
          // Handle when payroll exists
          setDailyHours(result.payload.hours || {});
          setPayrollStatus(result.payload.status || "");
          setIsNewPayroll(false); // Existing payroll entry found
        } else if (result.meta.response.status === 204) {
          // Handle 204 No Content response
          setDailyHours({});
          setPayrollStatus("");
          setIsNewPayroll(true); // No existing payroll entry
        } else {
          console.error("Unexpected response:", result);
          setDailyHours({});
          setPayrollStatus("");
          setIsNewPayroll(true);
        }
      } catch (error) {
        console.error("Error fetching payroll data:", error);
        setDailyHours({});
        setPayrollStatus("");
        setIsNewPayroll(true);
      }
    };

    fetchData();
  }, [dispatch, user._id, selectedPeriod]);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const submissionDate = new Date(
        Date.parse(selectedPeriod.submissionDate)
      );
      submissionDate.setHours(23, 59, 59, 999);

      const timeDiff = submissionDate - now;

      if (timeDiff > 0) {
        const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        setTimeRemaining(`${days} days ${hours} hours ${minutes} minutes`);
      } else {
        setTimeRemaining("0 days 0 hours 0 minutes");
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [selectedPeriod]);

  function findCurrentPayPeriod() {
    let closestPeriod = payrollDates[0];
    payrollDates.forEach((period) => {
      const [startDateString, endDateString] = period.includeDates.split("-");
      const startDate = new Date(Date.parse(`${startDateString.trim()}`));
      const endDate = new Date(Date.parse(`${endDateString.trim()}`));
      endDate.setHours(23, 59, 59, 999);

      if (currentDate >= startDate && currentDate <= endDate) {
        closestPeriod = period;
      }
    });
    return closestPeriod;
  }
  const handleHoursChange = (date, type, value) => {
    const formattedDate = new Date(date).toISOString().split("T")[0];

    setDailyHours((prev) => ({
      ...prev,
      [formattedDate]: {
        ...prev[formattedDate],
        [type]:
          type === "regular" ? (value === "" ? "" : parseFloat(value)) : value,
      },
    }));
  };

  const handleCreatePayroll = async () => {
    const simplifiedDailyHours = transformKeysToDateFormat(dailyHours);
    try {
      await dispatch(
        createHourlyPayroll({
          userId: user._id,
          payPeriod: selectedPeriod.payPeriodNumber,
          hours: simplifiedDailyHours,
        })
      ).unwrap();

      setSuccessMessage("Payroll successfully created!");
      setTimeout(() => setSuccessMessage(""), 3000);
      setIsNewPayroll(false); // Payroll now exists
    } catch (error) {
      console.error("Failed to create payroll:", error);
      setSuccessMessage("Error: Could not create payroll.");
      setTimeout(() => setSuccessMessage(""), 3000);
    }
  };

  const handleUpdatePayroll = async () => {
    const simplifiedDailyHours = transformKeysToDateFormat(dailyHours);
    try {
      await dispatch(
        updateHourlyPayroll({
          userId: user._id,
          payPeriod: selectedPeriod.payPeriodNumber,
          hours: simplifiedDailyHours,
          updatedBy: user._id,
        })
      ).unwrap();

      setSuccessMessage("Payroll successfully updated!");
      setTimeout(() => setSuccessMessage(""), 3000);

      // Refresh payroll data
      const refreshedData = await dispatch(
        fetchHourlyPayroll({
          userId: user._id,
          payPeriod: selectedPeriod.payPeriodNumber,
        })
      ).unwrap();

      setDailyHours(refreshedData.hours || {});
      setPayrollStatus(refreshedData.status || "");
    } catch (error) {
      console.error("Failed to update payroll:", error);
      setSuccessMessage("Error: Could not update payroll.");
      setTimeout(() => setSuccessMessage(""), 3000);
    }
  };

  const handleSubmitPayroll = async () => {
    const confirmation = window.confirm(
      "Are you sure you want to submit this payroll? Once submitted, it cannot be edited."
    );
    if (!confirmation) return;

    const result = await dispatch(
      submitHourlyPayroll({
        userId: user._id,
        payPeriod: selectedPeriod.payPeriodNumber,
        updatedBy: user._id,
      })
    );
    if (submitHourlyPayroll.fulfilled.match(result)) {
      setSuccessMessage("Payroll submitted successfully!");
      setTimeout(() => setSuccessMessage(""), 3000);
      // Refresh payroll data
      const refreshedData = await dispatch(
        fetchHourlyPayroll({
          userId: user._id,
          payPeriod: selectedPeriod.payPeriodNumber,
        })
      ).unwrap();
      setDailyHours(refreshedData.hours || {});
      setPayrollStatus(refreshedData.status || ""); // Update status
    } else {
      console.error("Failed to submit payroll");
    }
  };

  const isSaveEnabled = () => {
    const submissionDate = new Date(Date.parse(selectedPeriod.submissionDate));
    submissionDate.setHours(23, 59, 59, 999);

    const now = new Date();
    return now <= submissionDate;
  };

  const isSubmitEnabled = () => {
    const submissionDate = new Date(Date.parse(selectedPeriod.submissionDate));
    submissionDate.setHours(23, 59, 59, 999);

    const now = new Date();
    return now <= submissionDate;
  };

  const transformKeysToDateFormat = (hours) => {
    const transformedHours = {};
    Object.keys(hours).forEach((key) => {
      const date = new Date(key).toISOString().split("T")[0];
      transformedHours[date] = hours[key];
    });
    return transformedHours;
  };

  return (
    <div className="p-6 max-w-5xl mx-auto">
      <h1 className="text-2xl font-bold mb-4">Hourly Payroll Entry</h1>
      <p className="mb-2 text-lg">
        Currently viewing{" "}
        <strong>
          Pay Period {selectedPeriod.payPeriodNumber.split(".")[1]} of{" "}
          {selectedPeriod.payPeriodNumber.split(".")[0]}
        </strong>
      </p>
      <div className="mb-6">
        <Listbox value={selectedPeriod} onChange={setSelectedPeriod}>
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left border focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
              <span className="block truncate">
                {selectedPeriod.includeDates}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {payrollDates.map((period) => (
                <Listbox.Option
                  key={period.payPeriodNumber}
                  value={period}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? "bg-amber-100 text-amber-900" : "text-gray-900"
                    }`
                  }>
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}>
                        {period.includeDates}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </div>
        </Listbox>
      </div>
      <HourlyPayrollCalendar
        includeDates={selectedPeriod.includeDates}
        dailyHours={dailyHours}
        onHoursChange={handleHoursChange}
      />
      <div className="flex flex-col items-end mt-4 space-y-4">
        {payrollStatus === "Submitted" ? (
          <>
            <p className="text-sm text-green-600 font-semibold">
              Payroll has been successfully submitted. HR will process it at the
              end of the pay period.
            </p>
          </>
        ) : (
          <>
            <div className="flex items-center space-x-4">
              {isNewPayroll ? (
                <button
                  onClick={handleCreatePayroll}
                  disabled={!isSaveEnabled()}
                  className={`px-4 py-2 rounded-md text-sm font-semibold shadow-sm focus:outline-none ${
                    isSaveEnabled()
                      ? "bg-indigo-600 text-white hover:bg-indigo-500 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      : "bg-gray-300 text-gray-500 cursor-not-allowed"
                  }`}>
                  Create Payroll
                </button>
              ) : (
                <div className="flex justify-between items-center w-full gap-x-4">
                  <button
                    onClick={handleSubmitPayroll}
                    disabled={!isSubmitEnabled()}
                    className={`px-4 py-2 rounded-md text-sm font-semibold shadow-sm focus:outline-none ${
                      isSubmitEnabled()
                        ? "bg-green-600 text-white hover:bg-green-500 focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                        : "bg-gray-300 text-gray-500 cursor-not-allowed"
                    }`}>
                    Submit Payroll
                  </button>
                  <button
                    onClick={handleUpdatePayroll}
                    disabled={!isSaveEnabled()}
                    className={`px-4 py-2 rounded-md text-sm font-semibold shadow-sm focus:outline-none ${
                      isSaveEnabled()
                        ? "bg-indigo-600 text-white hover:bg-indigo-500 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        : "bg-gray-300 text-gray-500 cursor-not-allowed"
                    }`}>
                    Update Payroll
                  </button>
                </div>
              )}
            </div>
            <div className="mt-2 text-sm text-gray-600">
              {isSaveEnabled() || isSubmitEnabled() ? (
                <div className="flex flex-col justify-end items-end mt-2 gap-2 text-sm text-gray-600">
                  <div>
                    Updating and Submitting is permitted until{" "}
                    <strong>{selectedPeriod.submissionDate}</strong>.
                  </div>
                  <div>
                    Time remaining:{" "}
                    <span className="text-indigo-600 font-bold">
                      {timeRemaining}
                    </span>
                  </div>
                </div>
              ) : (
                <span className="text-red-600 font-bold">
                  Submission period has ended. HR will process your payroll.
                </span>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
