import React from 'react';
import { useDispatch } from 'react-redux';
import ClientOverview from '../pages/clientsPage/ClientOverview';
import { clearSelectedClient } from '../store/slices/clientsSlice';

const ClientModal = ({ isOpen, onClose, children }) => {
    const dispatch = useDispatch();

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
            <div className="fixed inset-0 bg-black opacity-50" onClick={onClose}></div>
            <div className="relative bg-white rounded-lg shadow-xl p-6 w-[90vw] h-[90vh] overflow-auto">
                <button
                    className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
                    onClick={() => {
                        dispatch(clearSelectedClient());
                        onClose();
                    }}
                >
                    <svg
                        className="h-6 w-6"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path d="M6 18L18 6M6 6l12 12"></path>
                    </svg>
                </button>
                <div className="p-6">
                    <ClientOverview isModal={true} />
                </div>
            </div>
        </div>
    );
};

export default ClientModal;
