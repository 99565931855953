// src/store/slices/appointmentsSlice.js

import { createSlice } from "@reduxjs/toolkit";
import {
  fetchAppointmentsList,
  fetchAppointmentById,
  fetchAppointmentsByClientId,
  fetchAppointmentsWithSoapByClientId,
  fetchAppointmentsByClinicianId,
  fetchAppointmentsByClinicianAndDateRange,
  fetchAppointmentsByClinicianDateRangeAndStatus,
  saveSoapNote,
  updateSoapNote,
  updateAppointmentStatus,
  updateAppointment,
  approveSoapNote,
  fetchAppointmentsByDate,
  fetchAppointmentsByDateRange,
  fetchAllAppointmentsByDateRange,
  fetchAppointmentsByDateRangeOnly,
  saveAppointment,
  createAppointment,
  notifyClientByEmail,
  checkForConflicts,
  revertTreated,
} from "../thunks/appointmentsThunk";

const initialState = {
  selectedAppointment: null,
  scheduleAppointments: [],
  sevenDayAppointments: [],
  payrollAppointments: [],
  allPayrollAppointments: [], // New state for all payroll appointments. For
  // payrollPendingAppointments: [],
  // payrollCarryoverAppointments: [],
  appointments: [],
  clientAppointments: [],
  clientAppointmentsWithSoap: [],
  appointmentUpdated: false,
  loading: false,
  error: null,
};

const appointmentsSlice = createSlice({
  name: "appointments",
  initialState,
  reducers: {
    setSelectedAppointment: (state, action) => {
      state.selectedAppointment = action.payload;
    },
    clearSelectedAppointment: (state) => {
      state.selectedAppointment = null;
    },
    clearClientAppointments: (state) => {
      state.clientAppointments = [];
      state.clientAppointmentsWithSoap = [];
    },
    setAppointmentUpdated: (state) => {
      state.appointmentUpdated = true;
    },
    clearAppointmentUpdated: (state) => {
      state.appointmentUpdated = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Appointments List
      .addCase(fetchAppointmentsList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAppointmentsList.fulfilled, (state, action) => {
        state.loading = false;
        state.appointments = action.payload;
      })
      .addCase(fetchAppointmentsList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Fetch Appointments by Client ID
      .addCase(fetchAppointmentsByClientId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAppointmentsByClientId.fulfilled, (state, action) => {
        state.loading = false;
        state.clientAppointments = action.payload;
      })
      .addCase(fetchAppointmentsByClientId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Fetch Appointments with SOAPs by ClientId
      .addCase(fetchAppointmentsWithSoapByClientId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchAppointmentsWithSoapByClientId.fulfilled,
        (state, action) => {
          state.loading = false;
          state.clientAppointmentsWithSoap = action.payload;
        }
      )
      .addCase(
        fetchAppointmentsWithSoapByClientId.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        }
      )

      // Fetch Appointment by ID
      .addCase(fetchAppointmentById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAppointmentById.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedAppointment = action.payload;
      })
      .addCase(fetchAppointmentById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Fetch Appointments by Clinician ID
      .addCase(fetchAppointmentsByClinicianId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAppointmentsByClinicianId.fulfilled, (state, action) => {
        state.loading = false;
        state.appointments = action.payload;
      })
      .addCase(fetchAppointmentsByClinicianId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Fetch Appointments by Clinician ID and date range (for payroll)
      // for clinicianpayrolloverviewpage exclusively
      .addCase(fetchAppointmentsByClinicianAndDateRange.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.payrollAppointments = [];
      })
      .addCase(
        fetchAppointmentsByClinicianAndDateRange.fulfilled,
        (state, action) => {
          state.loading = false;
          state.payrollAppointments = action.payload;
        }
      )
      .addCase(
        fetchAppointmentsByClinicianAndDateRange.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
          state.payrollAppointments = [];
        }
      )

      // fetchAllAppointmentsByDateRange (for hrpayrolloverviewpage exclusively)
      .addCase(fetchAllAppointmentsByDateRange.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.payrollAppointments = [];
      })
      .addCase(fetchAllAppointmentsByDateRange.fulfilled, (state, action) => {
        state.loading = false;
        state.allPayrollAppointments = action.payload;
      })
      .addCase(fetchAllAppointmentsByDateRange.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.payrollAppointments = [];
      })

      .addCase(
        fetchAppointmentsByClinicianDateRangeAndStatus.pending,
        (state) => {
          state.loading = true;
          state.error = null;
        }
      )
      .addCase(
        fetchAppointmentsByClinicianDateRangeAndStatus.fulfilled,
        (state, action) => {
          state.loading = false;
          state.appointments = action.payload;
        }
      )
      .addCase(
        fetchAppointmentsByClinicianDateRangeAndStatus.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        }
      )

      // Save SOAP Note
      .addCase(saveSoapNote.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveSoapNote.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.appointments.findIndex(
          (appointment) => appointment._id === action.payload._id
        );
        if (index !== -1) {
          state.appointments[index] = action.payload;
        }
      })
      .addCase(saveSoapNote.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Update SOAP Note
      .addCase(updateSoapNote.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateSoapNote.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.appointments.findIndex(
          (appointment) => appointment._id === action.payload._id
        );
        if (index !== -1) {
          state.appointments[index] = action.payload;
        }
      })
      .addCase(updateSoapNote.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Approve SOAP Note
      .addCase(approveSoapNote.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(approveSoapNote.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.appointments.findIndex(
          (appointment) => appointment._id === action.payload._id
        );
        if (index !== -1) {
          state.appointments[index] = action.payload;
        }
      })
      .addCase(approveSoapNote.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Fetch Appointments by Date
      .addCase(fetchAppointmentsByDate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAppointmentsByDate.fulfilled, (state, action) => {
        state.loading = false;
        state.appointments = action.payload;
        state.scheduleAppointments = action.payload;
      })
      .addCase(fetchAppointmentsByDate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Fetch Appointments by Date Range
      .addCase(fetchAppointmentsByDateRange.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAppointmentsByDateRange.fulfilled, (state, action) => {
        state.loading = false;
        state.appointments = action.payload;
      })
      .addCase(fetchAppointmentsByDateRange.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Fetch Appointments by Date Range
      .addCase(fetchAppointmentsByDateRangeOnly.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAppointmentsByDateRangeOnly.fulfilled, (state, action) => {
        state.loading = false;
        state.appointments = action.payload;
        state.sevenDayAppointments = action.payload;
      })
      .addCase(fetchAppointmentsByDateRangeOnly.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Save Appointment
      .addCase(saveAppointment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveAppointment.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.appointments.findIndex(
          (appointment) => appointment._id === action.payload._id
        );
        if (index !== -1) {
          state.appointments[index] = action.payload;
        } else {
          state.appointments.push(action.payload);
        }
      })
      .addCase(saveAppointment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Create Appointment
      .addCase(createAppointment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createAppointment.fulfilled, (state, action) => {
        state.loading = false;
        state.scheduleAppointments.push(action.payload);
        state.appointmentUpdated = true;
        state.appointments.push(action.payload);
      })
      .addCase(createAppointment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Update Appointment
      .addCase(updateAppointment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateAppointment.fulfilled, (state, action) => {
        console.log(
          "in appointments slice, this is the action in updateAppointment.fulfilled",
          action
        );
        // Update the selected appointment if it's the one being updated
        if (state.selectedAppointment?._id === action.payload._id) {
          state.selectedAppointment = action.payload;
        }

        // Also update the appointment in the appointments list
        const index = state.appointments.findIndex(
          (appointment) => appointment._id === action.payload._id
        );
        if (index !== -1) {
          state.appointments[index] = action.payload;
        }
      })
      .addCase(updateAppointment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // notifyClientByEmail
      .addCase(notifyClientByEmail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(notifyClientByEmail.fulfilled, (state, action) => {
        state.loading = false;
        state.appointments.push(action.payload);
      })
      .addCase(notifyClientByEmail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(checkForConflicts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(checkForConflicts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.conflicts = action.payload;
      })
      .addCase(checkForConflicts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // Revert Treated Status
      .addCase(revertTreated.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(revertTreated.fulfilled, (state, action) => {
        state.loading = false;
        if (state.selectedAppointment?._id === action.payload._id) {
          state.selectedAppointment = action.payload;
        }
        const index = state.appointments.findIndex(
          (appointment) => appointment._id === action.payload._id
        );
        if (index !== -1) {
          state.appointments[index] = action.payload;
        }
      })
      .addCase(revertTreated.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Update Appointment Status
      .addCase(updateAppointmentStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateAppointmentStatus.fulfilled, (state, action) => {
        state.loading = false;
        console.log(
          "in appointments slice, this is the action in updateAppointmentStatus",
          action
        );
        state.selectedAppointment = action.payload;
      })
      .addCase(updateAppointmentStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  setSelectedAppointment,
  clearSelectedAppointment,
  clearClientAppointments,
  setAppointmentUpdated,
  clearAppointmentUpdated,
} = appointmentsSlice.actions;

export default appointmentsSlice.reducer;
