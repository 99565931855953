import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ChartModal from '../../../modals/ChartModal';
import { fetchWaagData } from '../../../store/thunks/waagThunk';

const WaagChart = () => {
  const dispatch = useDispatch();
  const waagData = useSelector((state) => state.waag.data);
  const [metrics, setMetrics] = useState([]);
  const [selectedMetric, setSelectedMetric] = useState(null);

  useEffect(() => {
    dispatch(fetchWaagData());
  }, [dispatch]);

  useEffect(() => {
    if (waagData && waagData.revenue) {
      // Transform waagData into the format expected by the component
      const transformedMetrics = [
        { 
          name: 'Total Revenue', 
          thisWeek: `$${waagData.revenue.thisWeekRevenue?.toFixed(2) || '0.00'}`,
          lastWeek: `$${waagData.revenue.lastWeekRevenue?.toFixed(2) || '0.00'}`,
          prevWeek: `$${waagData.revenue.prevWeekRevenue?.toFixed(2) || '0.00'}`,
          mtd: `$${waagData.revenue.mtdRevenue?.toFixed(2) || '0.00'}`,
          lastMonth: `$${waagData.revenue.lastMonthRevenue?.toFixed(2) || '0.00'}`,
          target: '$85,000',
          percentCurr: `${((waagData.revenue.mtdRevenue / waagData.revenue.target) * 100).toFixed(0)}%`,
          percentLast: `${((waagData.revenue.lastMonthRevenue / waagData.revenue.target) * 100).toFixed(0)}%`,
          weeklyData: waagData.revenue.weeklyData?.map(week => ({
            weekNumber: week.weekNumber,
            year: week.year,
            value: week.value,
            date: week.date
          })) || []
        },
        { 
          name: 'Total Appointments',
          thisWeek: waagData.appointments?.thisWeek?.toString() || '0', 
          lastWeek: waagData.appointments?.lastWeek?.toString() || '0',
          prevWeek: waagData.appointments?.prevWeek?.toString() || '0',
          mtd: waagData.appointments?.mtd?.toString() || '0',
          lastMonth: waagData.appointments?.lastMonth?.toString() || '0',
          target: waagData.appointments?.target?.toString() || '0',
          percentCurr: waagData.appointments?.mtd && waagData.appointments?.target 
            ? `${((waagData.appointments.mtd / waagData.appointments.target) * 100).toFixed(0)}%`
            : 'N/A',
          percentLast: waagData.appointments?.lastMonth && waagData.appointments?.target
            ? `${((waagData.appointments.lastMonth / waagData.appointments.target) * 100).toFixed(0)}%`
            : 'N/A',
          weeklyData: waagData.appointments?.weeklyData?.map(week => ({
            weekNumber: week.weekNumber,
            year: week.year,
            value: week.value,
            date: week.date
          })) || []
        },
        { 
          name: 'Total Cancelled Appointments',
          thisWeek: waagData.cancelledAppointments?.thisWeek?.toString() || '0', 
          lastWeek: waagData.cancelledAppointments?.lastWeek?.toString() || '0',
          prevWeek: waagData.cancelledAppointments?.prevWeek?.toString() || '0',
          mtd: waagData.cancelledAppointments?.mtd?.toString() || '0',
          lastMonth: waagData.cancelledAppointments?.lastMonth?.toString() || '0',
          target: '0',
          percentCurr: 'N/A',
          percentLast: 'N/A',
          weeklyData: waagData.cancelledAppointments?.weeklyData?.map(week => ({
            weekNumber: week.weekNumber,
            year: week.year,
            value: week.value,
            date: week.date
          })) || []
        },
        { 
          name: 'Total No Show Appointments',
          thisWeek: waagData.noShowAppointments?.thisWeek?.toString() || '0', 
          lastWeek: waagData.noShowAppointments?.lastWeek?.toString() || '0',
          prevWeek: waagData.noShowAppointments?.prevWeek?.toString() || '0',
          mtd: waagData.noShowAppointments?.mtd?.toString() || '0',
          lastMonth: waagData.noShowAppointments?.lastMonth?.toString() || '0',
          target: '0',
          percentCurr: 'N/A',
          percentLast: 'N/A',
          weeklyData: waagData.noShowAppointments?.weeklyData?.map(week => ({
            weekNumber: week.weekNumber,
            year: week.year,
            value: week.value,
            date: week.date
          })) || []
        },
        { 
          name: 'Treated Appointment Hours',
          thisWeek: waagData.treatedHours?.thisWeek?.toString() || '0', 
          lastWeek: waagData.treatedHours?.lastWeek?.toString() || '0',
          prevWeek: waagData.treatedHours?.prevWeek?.toString() || '0',
          mtd: waagData.treatedHours?.mtd?.toString() || '0',
          lastMonth: waagData.treatedHours?.lastMonth?.toString() || '0',
          target: waagData.treatedHours?.target?.toString() || '0',
          percentCurr: waagData.treatedHours?.mtd && waagData.treatedHours?.target
            ? `${((waagData.treatedHours.mtd / waagData.treatedHours.target) * 100).toFixed(0)}%`
            : 'N/A',
          percentLast: waagData.treatedHours?.lastMonth && waagData.treatedHours?.target
            ? `${((waagData.treatedHours.lastMonth / waagData.treatedHours.target) * 100).toFixed(0)}%`
            : 'N/A',
          weeklyData: waagData.treatedHours?.weeklyData?.map(week => ({
            weekNumber: week.weekNumber,
            year: week.year,
            value: week.value,
            date: week.date
          })) || []
        },
        {
          name: 'Active Clients (30 Days)',
          thisWeek: waagData.activeClients?.weeklyData?.[0]?.active30Days?.toString() || '0',
          lastWeek: waagData.activeClients?.weeklyData?.[1]?.active30Days?.toString() || '0',
          prevWeek: waagData.activeClients?.weeklyData?.[2]?.active30Days?.toString() || '0',
          mtd: waagData.activeClients?.weeklyData?.[0]?.active30Days?.toString() || '0',
          lastMonth: waagData.activeClients?.weeklyData?.[4]?.active30Days?.toString() || '0',
          target: '0',
          percentCurr: 'N/A',
          percentLast: 'N/A',
          weeklyData: waagData.activeClients?.weeklyData?.map(week => ({
            weekNumber: week.weekNumber,
            year: week.year,
            value: week.active30Days,
            date: week.date
          })) || []
        },
        {
          name: 'Active Clients (60 Days)',
          thisWeek: waagData.activeClients?.weeklyData?.[0]?.active60Days?.toString() || '0', 
          lastWeek: waagData.activeClients?.weeklyData?.[1]?.active60Days?.toString() || '0',
          prevWeek: waagData.activeClients?.weeklyData?.[2]?.active60Days?.toString() || '0',
          mtd: waagData.activeClients?.weeklyData?.[0]?.active60Days?.toString() || '0',
          lastMonth: waagData.activeClients?.weeklyData?.[4]?.active60Days?.toString() || '0',
          target: '0',
          percentCurr: 'N/A',
          percentLast: 'N/A',
          weeklyData: waagData.activeClients?.weeklyData?.map(week => ({
            weekNumber: week.weekNumber,
            year: week.year,
            value: week.active60Days,
            date: week.date
          })) || []
        },
        {
          name: 'New Clients',
          thisWeek: waagData.newClients?.thisWeekNewClients?.toString() || '0',
          lastWeek: waagData.newClients?.lastWeekNewClients?.toString() || '0',
          prevWeek: waagData.newClients?.prevWeekNewClients?.toString() || '0',
          mtd: waagData.newClients?.mtdNewClients?.toString() || '0',
          lastMonth: waagData.newClients?.lastMonthNewClients?.toString() || '0',
          target: '0',
          percentCurr: 'N/A',
          percentLast: 'N/A',
          weeklyData: waagData.newClients?.weeklyData?.map(week => ({
            weekNumber: week.weekNumber,
            year: week.year,
            value: week.value,
            date: week.date
          })) || []
        },
        {
          name: 'Client Dropoff',
          thisWeek: waagData.clientDropOff?.thisWeekDropoffs?.toString() || '0',
          lastWeek: waagData.clientDropOff?.lastWeekDropoffs?.toString() || '0',
          prevWeek: waagData.clientDropOff?.prevWeekDropoffs?.toString() || '0',
          mtd: waagData.clientDropOff?.mtdDropoffs?.toString() || '0',
          lastMonth: waagData.clientDropOff?.lastMonthDropoffs?.toString() || '0',
          target: '0',
          percentCurr: 'N/A',
          percentLast: 'N/A',
          weeklyData: waagData.clientDropOff?.weeklyData?.map(week => ({
            weekNumber: week.weekNumber,
            year: week.year,
            value: week.value,
            date: week.date
          })) || []
        },
        {
          name: 'Intake',
          thisWeek: waagData.appointmentTypes?.thisWeek?.[0]?.intake?.count?.toString() || '0',
          lastWeek: waagData.appointmentTypes?.lastWeek?.[0]?.intake?.count?.toString() || '0',
          prevWeek: waagData.appointmentTypes?.prevWeek?.[0]?.intake?.count?.toString() || '0',
          mtd: waagData.appointmentTypes?.mtd?.[0]?.intake?.count?.toString() || '0',
          lastMonth: waagData.appointmentTypes?.lastMonth?.[0]?.intake?.count?.toString() || '0',
          target: '0',
          percentCurr: 'N/A',
          percentLast: 'N/A',
          weeklyData: waagData.appointmentTypes?.weeklyTrendIntake || []
        },
        {
          name: 'Therapy',
          thisWeek: waagData.appointmentTypes?.thisWeek?.[0]?.therapy?.count?.toString() || '0',
          lastWeek: waagData.appointmentTypes?.lastWeek?.[0]?.therapy?.count?.toString() || '0',
          prevWeek: waagData.appointmentTypes?.prevWeek?.[0]?.therapy?.count?.toString() || '0',
          mtd: waagData.appointmentTypes?.mtd?.[0]?.therapy?.count?.toString() || '0',
          lastMonth: waagData.appointmentTypes?.lastMonth?.[0]?.therapy?.count?.toString() || '0',
          target: '0',
          percentCurr: 'N/A',
          percentLast: 'N/A',
          weeklyData: waagData.appointmentTypes?.weeklyTrendTherapy || []
        },
        {
          name: 'Assessment',
          thisWeek: waagData.appointmentTypes?.thisWeek?.[0]?.assessment?.count?.toString() || '0',
          lastWeek: waagData.appointmentTypes?.lastWeek?.[0]?.assessment?.count?.toString() || '0',
          prevWeek: waagData.appointmentTypes?.prevWeek?.[0]?.assessment?.count?.toString() || '0',
          mtd: waagData.appointmentTypes?.mtd?.[0]?.assessment?.count?.toString() || '0',
          lastMonth: waagData.appointmentTypes?.lastMonth?.[0]?.assessment?.count?.toString() || '0',
          target: '0',
          percentCurr: 'N/A',
          percentLast: 'N/A',
          weeklyData: waagData.appointmentTypes?.weeklyTrendAssessment || []
        },
        {
          name: 'Consult',
          thisWeek: waagData.appointmentTypes?.thisWeek?.[0]?.consult?.count?.toString() || '0',
          lastWeek: waagData.appointmentTypes?.lastWeek?.[0]?.consult?.count?.toString() || '0',
          prevWeek: waagData.appointmentTypes?.prevWeek?.[0]?.consult?.count?.toString() || '0',
          mtd: waagData.appointmentTypes?.mtd?.[0]?.consult?.count?.toString() || '0',
          lastMonth: waagData.appointmentTypes?.lastMonth?.[0]?.consult?.count?.toString() || '0',
          target: '0',
          percentCurr: 'N/A',
          percentLast: 'N/A',
          weeklyData: waagData.appointmentTypes?.weeklyTrendConsult || []
        }
      ];
      setMetrics(transformedMetrics);
    }
  }, [waagData]);

  if (!waagData || !waagData.revenue) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="overflow-x-auto bg-white shadow-md rounded-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Metric</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">This Week</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Last Week</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Prev Week</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">MTD</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Last Month</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Target</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">% curr</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">% last</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {metrics.map((metric) => (
              <tr 
                key={metric.name} 
                onClick={() => setSelectedMetric(metric.name)}
                className="cursor-pointer hover:bg-gray-50"
              >
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{metric.name}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{metric.thisWeek}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{metric.lastWeek}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{metric.prevWeek}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{metric.mtd}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{metric.lastMonth}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{metric.target}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{metric.percentCurr}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{metric.percentLast}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {selectedMetric && (
        <ChartModal
          metric={metrics.find(m => m.name === selectedMetric)}
          onClose={() => setSelectedMetric(null)}
        />
      )}
    </>
  );
};

export default WaagChart;
