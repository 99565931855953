import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchClinicians } from "../../store/thunks/cliniciansThunk";
import { fetchAppointmentsByDateRangeOnly } from "../../store/thunks/appointmentsThunk";
import { fetchMeetingsForCurrentWeek } from "../../store/thunks/meetingsThunk";
import { setSelectedAppointment } from "../../store/slices/appointmentsSlice";
import { setSelectedMeeting } from "../../store/slices/meetingSlice";
import "./SevenDayCalendarV2.css";
import AppointmentSummary from "../../applicationUi/components/appointments/AppointmentSummary";
import QuickAddMeeting from "../../modals/QuickAddMeeting";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { format, addDays, startOfWeek, parseISO, addMinutes } from "date-fns";
import { toDate } from "date-fns-tz";
import MultiSelect from "../../applicationUi/components/MultiSelect";

export default function SevenDayCalendarV2() {
  const dispatch = useDispatch();
  const sevenDayAppointments = useSelector(
    (state) => state.appointments.sevenDayAppointments
  );
  const sevenDayMeetings = useSelector((state) => state.meetings.meetings);
  const stateClinicians = useSelector(
    (state) => state.clinicians.clinicians || []
  );
  const user = useSelector((state) => state.auth.user);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedClinician, setSelectedClinician] = useState(null);
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [filteredMeetings, setFilteredMeetings] = useState([]);

  const clinicians = stateClinicians.filter(
    (clinician) => clinician.userStatus === "active"
  );

  const inactiveClinicians = stateClinicians.filter(
    (clinician) => clinician.userStatus === "inactive"
  );

  const container = useRef(null);
  const containerNav = useRef(null);
  const containerOffset = useRef(null);
  const [showWorkingHoursOnly, setShowWorkingHoursOnly] = useState(true);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [hoveredItem, setHoveredItem] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  const [isQuickAddMeetingOpen, setIsQuickAddMeetingOpen] = useState(false);
  const [isAppointmentSummaryOpen, setIsAppointmentSummaryOpen] =
    useState(false);

  const startDate = startOfWeek(currentDate, { weekStartsOn: 0 });

  // Generate week days
  const weekDays = Array.from({ length: 7 }, (_, index) => {
    const date = addDays(startDate, index);
    const isToday =
      format(date, "yyyy-MM-dd") === format(new Date(), "yyyy-MM-dd");

    return {
      shortDay: format(date, "E"), // Mon, Tue, etc.
      fullDay: format(date, "EEE"), // Monday, Tuesday, etc.
      date: format(date, "d"), // 1-31
      isToday,
    };
  });

  // Calculate the hours to display based on the toggle
  const hoursToDisplay = showWorkingHoursOnly ? [8, 20] : [0, 24]; // 8 AM to 8 PM or 12 AM to 12 AM

  // Adjust the rendering of the time slots
  const renderTimeSlots = () => {
    const timeSlots = [];
    for (let hour = hoursToDisplay[0]; hour < hoursToDisplay[1]; hour++) {
      timeSlots.push(
        <div key={hour} className="time-slot">
          {hour}:00
        </div>
      );
    }
    return timeSlots;
  };

  useEffect(() => {
    const currentMinute = new Date().getHours() * 60;
    container.current.scrollTop =
      ((container.current.scrollHeight -
        containerNav.current.offsetHeight -
        containerOffset.current.offsetHeight) *
        currentMinute) /
      1440;
  }, []);

  const handlePreviousWeek = () => {
    setCurrentDate((prevDate) => addDays(prevDate, -7));
  };

  const handleNextWeek = () => {
    setCurrentDate((prevDate) => addDays(prevDate, 7));
  };

  const handleToday = () => {
    setCurrentDate(new Date());
  };

  const getCurrentWeek = (date) => {
    const week = [];
    const startOfWeek = new Date(date);
    startOfWeek.setDate(date.getDate() - date.getDay());
    startOfWeek.setHours(0, 1, 0, 0); // Set to 12:01 AM of the start of the week
    startOfWeek.setHours(0, 1, 0, 0); // Set to 12:01 AM of the start of the week

    for (let i = 0; i < 7; i++) {
      const day = new Date(startOfWeek);
      day.setDate(startOfWeek.getDate() + i);
      week.push(day);
    }
    return week;
  };

  useEffect(() => {
    const currentWeek = getCurrentWeek(currentDate);
    const startDate = new Date(currentWeek[0]);
    startDate.setHours(0, 1, 0, 0); // Ensure startDate is at 12:01 AM

    const endDate = new Date(currentWeek[6]);
    endDate.setHours(23, 59, 59, 999); // Ensure endDate is at 11:59 PM

    dispatch(fetchAppointmentsByDateRangeOnly({ startDate, endDate }));
    dispatch(fetchMeetingsForCurrentWeek({ startDate, endDate }));
    dispatch(fetchClinicians());
  }, [dispatch, currentDate, isQuickAddMeetingOpen, isAppointmentSummaryOpen]);

  const calculateAppointmentGridPosition = (startTime, endTime) => {
    // Convert UTC to local time
    const localStart = toDate(parseISO(startTime), {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    const localEnd = toDate(parseISO(endTime), {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

    // Calculate grid row (each hour = 12 rows, each row = 5 minutes)
    const startHour = localStart.getHours();
    const startMinutes = localStart.getMinutes();
    const startRow = showWorkingHoursOnly
      ? (startHour - 8) * 12 + Math.floor(startMinutes / 5) + 1 // Adjust for working hours
      : startHour * 12 + Math.floor(startMinutes / 5) + 1; // Full 24 hours

    // Calculate duration in 5-minute increments
    const durationMinutes = (localEnd - localStart) / (1000 * 60);
    const span = Math.ceil(durationMinutes / 5);
    return { startRow, span };
  };

  const calculateMeetingGridPosition = (date, startTime, duration) => {
    if (!date || !startTime || startTime === "Invalid Date") {
      console.error("Invalid date or startTime:", { date, startTime });
      return { startRow: 0, span: 0 };
    }

    const fullDateTime = parseISO(date.split("T")[0] + "T" + startTime);
    const localStart = toDate(fullDateTime, {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

    const startHour = localStart.getHours();
    const startMinutes = localStart.getMinutes();
    const startRow = showWorkingHoursOnly
      ? (startHour - 8) * 12 + Math.floor(startMinutes / 5) + 1
      : startHour * 12 + Math.floor(startMinutes / 5) + 1;

    const span = Math.ceil(duration / 5);
    return { startRow, span };
  };

  // Add this helper function to find overlapping events
  const findOverlappingEvents = (currentEvent, allEvents) => {
    const currentStart = parseISO(currentEvent.start || currentEvent.date);
    const currentEnd = currentEvent.end
      ? parseISO(currentEvent.end)
      : addMinutes(currentStart, currentEvent.duration || 0);

    return allEvents.filter((event) => {
      if (event._id === currentEvent._id) return false;

      const eventStart = parseISO(event.start || event.date);
      const eventEnd = event.end
        ? parseISO(event.end)
        : addMinutes(eventStart, event.duration || 0);

      return currentStart < eventEnd && currentEnd > eventStart;
    });
  };

  const handleEventClick = (event) => {
    if ("client" in event) {
      dispatch(setSelectedAppointment(event));
      setSelectedEvent(event);
      setIsAppointmentSummaryOpen(true);
    } else {
      dispatch(setSelectedMeeting(event));
      setSelectedEvent(event);
      setIsQuickAddMeetingOpen(true);
    }
  };

  // Add this component for the enhanced tooltip
  const EnhancedTooltip = ({
    mainEvent,
    overlappingEvents,
    onEventHover,
    onEventLeave,
  }) => {
    const allEvents = [mainEvent, ...overlappingEvents].sort((a, b) => {
      const aStart = parseISO(a.start || a.date);
      const bStart = parseISO(b.start || b.date);
      return aStart - bStart;
    });

    return (
      <div
        className="absolute z-50"
        style={{
          top: "50%", // Center vertically relative to the event
          left: "-235px", // Position to the left of the event (adjust as needed)
          transform: "translateY(-50%)", // Center the tooltip vertically
        }}>
        <div
          className="absolute z-50 bg-white p-2 rounded-lg shadow-lg border border-gray-200 min-w-[227px]" // Reduced padding and min-width
          style={{
            top: "0", // Align the top of the tooltip with the top of the event
            left: "100%", // Position the tooltip to the right of the left edge
            marginLeft: "8px", // Add some space between the tooltip and the event
          }}>
          <div className="text-sm font-semibold mb-1">Events</div>{" "}
          {/* Reduced margin */}
          <div className="space-y-1">
            {" "}
            {/* Reduced spacing between events */}
            {allEvents.map((event) => {
              const isAppointment = "client" in event;
              const start = parseISO(event.start || event.date);

              return (
                <div
                  key={event._id}
                  className="p-1 rounded-md relative group cursor-pointer" // Reduced padding
                  style={{
                    backgroundColor: isAppointment
                      ? `${event.clinician.calendarColor}20`
                      : "#6B728020",
                    border: isAppointment ? "none" : "1px dashed #6B7280",
                  }}
                  onMouseEnter={() => onEventHover(event)}
                  onMouseLeave={onEventLeave}>
                  <div className="flex justify-between items-start">
                    <div>
                      <div
                        className="font-medium text-sm"
                        style={{
                          color: isAppointment
                            ? event.clinician.calendarColor
                            : "#374151",
                        }}>
                        {isAppointment
                          ? format(
                              parseISO(event.start || event.date),
                              "h:mm a"
                            ) + ` - ${event.clinician.calendarDisplayName}`
                          : `${format(
                              parseISO(
                                event.date.split("T")[0] + "T" + event.startTime
                              ),
                              "h:mm a"
                            )} - Meeting`}
                      </div>
                      <div className="text-xs">
                        {" "}
                        {/* Reduced font size */}
                        {isAppointment
                          ? `${event.client.firstName} ${event.client.lastName}`
                          : event.attendees.map((attendee, index) => (
                              <span key={attendee._id}>
                                {`${attendee.firstName} ${attendee.lastName}`}
                                {index < event.attendees.length - 1 ? ", " : ""}
                              </span>
                            ))}
                      </div>
                      <div className="text-xs text-gray-600">{event.title}</div>{" "}
                      {/* Reduced font size */}
                      {event.blockNumber && (
                        <div
                          className="text-xs"
                          style={{
                            color: isAppointment
                              ? event.clinician.calendarColor
                              : "#374151",
                          }}>
                          Block #{event.blockNumber}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const isUserClinician = clinicians.find(
    (clinician) => clinician._id === user._id
  );

  const [selectedClinicians, setSelectedClinicians] = useState(
    isUserClinician ? [isUserClinician] : []
  );

  const handleClinicianChange = (selectedOptions) => {
    const chosenClinicians = selectedOptions
      .map((option) => option.value)
      .filter(Boolean);

    setSelectedClinicians((prevSelected) => {
      const combined = [
        ...prevSelected.filter(
          (prevClinician) =>
            !chosenClinicians.some(
              (newClinician) => newClinician._id === prevClinician._id
            )
        ),
        ...chosenClinicians,
      ];
      return combined;
    });
  };

  const handleRemoveClinician = (clinicianId) => {
    setSelectedClinicians((prev) =>
      prev.filter((clinician) => clinician._id !== clinicianId)
    );
  };

  const calculateEventPosition = (event, allEvents) => {
    const eventStart = parseISO(event.start || event.date);
    const eventEnd = event.end
      ? parseISO(event.end)
      : addMinutes(eventStart, event.duration || 0);

    // Find all events that overlap with this event's time range
    const overlappingEvents = allEvents.filter((otherEvent) => {
      if (otherEvent._id === event._id) return false;

      const otherStart = parseISO(otherEvent.start || otherEvent.date);
      const otherEnd = otherEvent.end
        ? parseISO(otherEvent.end)
        : addMinutes(otherStart, otherEvent.duration || 0);

      // Check if the events overlap in time
      return eventStart < otherEnd && eventEnd > otherStart;
    });

    const allOverlappingEvents = [event, ...overlappingEvents].sort((a, b) => {
      // Sort by start time first
      const aStart = parseISO(a.start || a.date);
      const bStart = parseISO(b.start || b.date);
      if (aStart.getTime() !== bStart.getTime()) {
        return aStart - bStart;
      }
      // If start times are equal, sort by ID for consistency
      return a._id.localeCompare(b._id);
    });

    const position = allOverlappingEvents.findIndex((e) => e._id === event._id);
    const total = allOverlappingEvents.length;

    return {
      width: `${100 / total}%`,
      left: `${(position * 100) / total}%`,
      overlappingEvents,
    };
  };

  // Effect to filter appointments and meetings based on selected clinician
  useEffect(() => {
    if (selectedClinicians.length > 0) {
      // Check if there are selected clinicians
      const filteredAppointments = sevenDayAppointments.filter((appointment) =>
        selectedClinicians.some(
          (clinician) => clinician._id === appointment.clinician._id
        )
      );
      const filteredMeetings = sevenDayMeetings.filter((meeting) =>
        meeting.attendees.some((attendee) =>
          selectedClinicians.some((clinician) => clinician._id === attendee._id)
        )
      );

      setFilteredAppointments(filteredAppointments);
      setFilteredMeetings(filteredMeetings);
    } else {
      // If no clinician is selected, reset to original lists
      setFilteredAppointments(sevenDayAppointments);
      setFilteredMeetings(sevenDayMeetings);
    }
  }, [selectedClinicians, sevenDayAppointments, sevenDayMeetings]);

  const renderHorizontalLines = () => {
    const lines = [];
    const startHour = showWorkingHoursOnly ? 8 : 0; // Start at 8 AM if working hours only
    const endHour = showWorkingHoursOnly ? 20 : 24; // End at 8 PM if working hours only

    for (let hour = startHour; hour < endHour; hour++) {
      lines.push(
        <div key={hour}>
          <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs/5 text-gray-400">
            {hour % 12 === 0 ? 12 : hour % 12} {hour < 12 ? "AM" : "PM"}
          </div>
        </div>
      );
      lines.push(<div key={`empty-${hour}`} />); // Empty div for spacing
    }

    return lines;
  };

  return (
    <div className="flex h-full w-full flex-col seven-day-calendar">
      <header className="sticky top-0 z-30 bg-white shadow ring-1 ring-black/5">
        <div className="flex flex-none items-center justify-between border-b border-gray-200 px-6 py-4">
          <div className="flex items-center">
            <h1 className="text-lg font-semibold text-gray-900">
              <time dateTime={format(startDate, "yyyy-MM")}>
                {format(startDate, "MMMM yyyy")}
              </time>
            </h1>
            <div className="ml-6 flex items-center space-x-2">
              <div className="relative">
                <MultiSelect
                  options={clinicians.map((clinician) => ({
                    value: clinician,
                    _id: clinician._id,
                    label: `${clinician.firstName} ${clinician.lastName}`,
                  }))}
                  selectedValues={selectedClinicians}
                  onChange={handleClinicianChange}
                  placeholder="Filter clinicians"
                  className="w-64"
                />
              </div>
              <div className="flex flex-wrap gap-1">
                {selectedClinicians.map((clinician) => (
                  <span
                    key={clinician._id}
                    className="inline-flex items-center px-2 py-1 rounded-md text-xs font-medium bg-primary/10 text-primary"
                    style={{
                      backgroundColor: `${clinician.calendarColor}20`,
                      color: clinician.calendarColor,
                    }}>
                    {clinician.firstName} {clinician.lastName}
                    <button
                      type="button"
                      className="ml-1 hover:text-red-500"
                      onClick={() => handleRemoveClinician(clinician._id)}>
                      ×
                    </button>
                  </span>
                ))}
                {selectedClinicians.length === 0 && (
                  <span className="inline-flex items-center px-2 py-1 rounded-md text-xs font-medium bg-gray-100 text-gray-600">
                    All clinicians
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2 items-center">
            <div className="flex items-center">
              <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
                <button
                  type="button"
                  onClick={handlePreviousWeek}
                  className="flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50">
                  <span className="sr-only">Previous week</span>
                  <ChevronLeftIcon className="size-5" aria-hidden="true" />
                </button>
                <button
                  type="button"
                  onClick={handleToday}
                  className="hidden border-y border-gray-300 px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative md:block">
                  Today
                </button>
                <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
                <button
                  type="button"
                  onClick={handleNextWeek}
                  className="flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50">
                  <span className="sr-only">Next week</span>
                  <ChevronRightIcon className="size-5" aria-hidden="true" />
                </button>
              </div>
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                checked={showWorkingHoursOnly}
                onChange={() => setShowWorkingHoursOnly(!showWorkingHoursOnly)}
                className="mr-2 appearance-none rounded border border-gray-300 bg-white checked:border-indigo-600 checked:bg-indigo-600 indeterminate:border-indigo-600 indeterminate:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
              />
              <p className="text-sm font-medium">Working Hours</p>
            </div>
          </div>
        </div>
        <div
          ref={containerNav}
          className="sticky top-0 z-30 flex-none bg-white shadow ring-1 ring-black/5 sm:pr-8">
          <div className="grid grid-cols-7 text-sm/6 text-gray-500 sm:hidden">
            {weekDays.map((day) => (
              <button
                key={day.shortDay}
                type="button"
                className="flex flex-col items-center pb-3 pt-2">
                {day.shortDay}{" "}
                <span
                  className={`mt-1 flex size-8 items-center justify-center font-semibold
                    ${
                      day.isToday
                        ? "rounded-full bg-indigo-600 text-white"
                        : "text-gray-900"
                    }`}>
                  {day.date}
                </span>
              </button>
            ))}
          </div>

          <div className="-mr-px hidden grid-cols-7 divide-x divide-gray-100 border-r border-gray-100 text-sm/6 text-gray-500 sm:grid">
            <div className="col-end-1 w-14" />
            {weekDays.map((day) => (
              <div
                key={day.fullDay}
                className="flex items-center justify-center py-1.5">
                <span className="flex items-baseline">
                  {day.fullDay}
                  <span
                    className={`ml-1.5 flex items-center justify-center font-semibold
                      ${
                        day.isToday
                          ? "size-8 rounded-full bg-indigo-600 text-white"
                          : "text-gray-900"
                      }`}>
                    {day.date}
                  </span>
                </span>
              </div>
            ))}
          </div>
        </div>
      </header>
      <div
        ref={container}
        className="isolate flex flex-auto flex-col overflow-auto bg-white">
        <div
          style={{ width: "165%" }}
          className="flex max-w-full flex-none flex-col sm:max-w-none md:max-w-full">
          <div
            ref={containerNav}
            className="sticky top-0 z-30 flex-none bg-white shadow ring-1 ring-black/5 sm:pr-8"></div>
          <div className="flex flex-auto">
            <div className="sticky left-0 z-10 w-14 flex-none bg-white ring-1 ring-gray-100" />
            <div className="grid flex-auto grid-cols-1 grid-rows-1">
              {/* Horizontal lines */}
              <div
                className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100"
                style={{ gridTemplateRows: "repeat(48, minmax(3.5rem, 1fr))" }}>
                <div ref={containerOffset} className="row-end-1 h-8"></div>
                {renderHorizontalLines()}
              </div>

              {/* Vertical lines */}
              <div className="col-start-1 col-end-2 row-start-1 hidden grid-cols-7 grid-rows-1 divide-x divide-gray-100 sm:grid sm:grid-cols-7">
                <div className="col-start-1 row-span-full" />
                <div className="col-start-2 row-span-full" />
                <div className="col-start-3 row-span-full" />
                <div className="col-start-4 row-span-full" />
                <div className="col-start-5 row-span-full" />
                <div className="col-start-6 row-span-full" />
                <div className="col-start-7 row-span-full" />
                <div className="col-start-8 row-span-full w-8" />
              </div>

              {/* Render Events (Appts / Meetings)*/}
              <ol
                className="mt-4 col-start-1 col-end-2 row-start-1 grid grid-cols-1 sm:grid-cols-7 sm:pr-8 seven-day-calendar"
                style={{
                  gridTemplateRows: "1.75rem repeat(288, minmax(0, 1fr)) auto",
                  overflow: "auto", // Enable scrolling
                }}>
                {/* Render appointments */}
                {filteredAppointments
                  .filter(
                    (appointment) =>
                      selectedClinicians.length === 0 ||
                      selectedClinicians.some(
                        (c) => c._id === appointment.clinician._id
                      )
                  )
                  .map((appointment) => {
                    const { startRow, span } = calculateAppointmentGridPosition(
                      appointment.start,
                      appointment.end
                    );
                    const { width, left, overlappingEvents } =
                      calculateEventPosition(appointment, [
                        ...filteredAppointments,
                        ...filteredMeetings,
                      ]);
                    const localStart = toDate(parseISO(appointment.start), {
                      timeZone:
                        Intl.DateTimeFormat().resolvedOptions().timeZone,
                    });
                    const dayOfWeek = localStart.getDay() + 1;

                    const handleMouseEnter = (e) => {
                      const overlappingEvents = findOverlappingEvents(
                        appointment,
                        [...filteredAppointments, ...filteredMeetings]
                      );

                      setHoveredItem({
                        type: "appointment",
                        data: appointment,
                        overlappingEvents: overlappingEvents,
                      });
                    };

                    const handleMouseLeave = () => {
                      setHoveredItem(null);
                    };

                    return (
                      <li
                        key={appointment._id}
                        className="relative flex"
                        style={{
                          gridRow: `${startRow} / span ${span}`,
                          gridColumnStart: dayOfWeek,
                          position: "relative",
                        }}>
                        <span
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                          onClick={() => handleEventClick(appointment)}
                          className="group absolute flex flex-col overflow-y-auto rounded-lg px-1.5 py-1 text-xs/5 cursor-pointer"
                          style={{
                            backgroundColor: `${appointment.clinician.calendarColor}20`,
                            width,
                            left,
                            top: "0px",
                            bottom: "0px",
                            zIndex: 2,
                          }}>
                          <p
                            style={{
                              color: appointment.clinician.calendarColor
                                ? appointment.clinician.calendarColor
                                : "#3b82f6", // Default to blue if calendarColor is null/undefined
                            }}>
                            <time dateTime={appointment.start}>
                              {format(localStart, "h:mm a")}
                            </time>
                            <strong>
                              {" - "}
                              {appointment.clinician.calendarDisplayName}
                            </strong>
                          </p>
                          <p
                            style={{
                              color: appointment.clinician.calendarColor
                                ? appointment.clinician.calendarColor
                                : "#3b82f6", // Default to blue if calendarColor is null/undefined
                            }}>
                            {appointment.title}
                          </p>
                          <p
                            style={{
                              color: appointment.clinician.calendarColor
                                ? appointment.clinician.calendarColor
                                : "#3b82f6", // Default to blue if calendarColor is null/undefined
                            }}>
                            {appointment.client.firstName}{" "}
                            {appointment.client.lastName}
                          </p>
                          {appointment.blockNumber && (
                            <p
                              style={{
                                color: appointment.clinician.calendarColor
                                  ? appointment.clinician.calendarColor
                                  : "#3b82f6", // Default to blue if calendarColor is null/undefined
                              }}>
                              Block #{appointment.blockNumber}
                            </p>
                          )}
                        </span>
                        {hoveredItem &&
                          hoveredItem.data._id === appointment._id && (
                            <div
                              className="enhanced-tooltip absolute z-50"
                              onMouseEnter={() =>
                                setHoveredItem({
                                  type: "appointment",
                                  data: appointment,
                                  overlappingEvents,
                                })
                              }
                              onMouseLeave={(e) => {
                                // Only leave if not entering another event
                                if (!e.relatedTarget?.closest(".group")) {
                                  setHoveredItem(null);
                                }
                              }}>
                              <EnhancedTooltip
                                mainEvent={hoveredItem.data}
                                overlappingEvents={
                                  hoveredItem.overlappingEvents
                                }
                                onEventHover={(event) => {
                                  setHoveredItem({
                                    type:
                                      "client" in event
                                        ? "appointment"
                                        : "meeting",
                                    data: event,
                                    overlappingEvents:
                                      hoveredItem.overlappingEvents,
                                  });
                                }}
                                onEventLeave={() => {}} // Remove the leave handler to keep tooltip persistent
                              />
                            </div>
                          )}
                      </li>
                    );
                  })}

                {/* Render meetings */}
                {filteredMeetings
                  .filter(
                    (meeting) =>
                      selectedClinicians.length === 0 ||
                      meeting.attendees.some((attendee) =>
                        selectedClinicians.some((c) => c._id === attendee._id)
                      )
                  )
                  .map((meeting) => {
                    // Validate date and time
                    if (
                      !meeting.date ||
                      !meeting.startTime ||
                      meeting.startTime === "Invalid Date"
                    ) {
                      console.error(
                        "Invalid meeting date or start time:",
                        meeting
                      );
                      return null; // Skip rendering invalid meetings
                    }

                    const { startRow, span } = calculateMeetingGridPosition(
                      meeting.date,
                      meeting.startTime,
                      meeting.duration
                    );

                    const { width, left, overlappingEvents } =
                      calculateEventPosition(meeting, [
                        ...filteredAppointments,
                        ...filteredMeetings,
                      ]);

                    const localStart = toDate(parseISO(meeting.date), {
                      timeZone:
                        Intl.DateTimeFormat().resolvedOptions().timeZone,
                    });

                    // Get the day of week (1-7) for the meeting
                    const dayOfWeek = localStart.getDay() + 1; // Sunday will be column 1, Saturday will be column 7

                    const handleMouseEnter = (e) => {
                      const overlappingEvents = findOverlappingEvents(meeting, [
                        ...filteredAppointments,
                        ...filteredMeetings,
                      ]);

                      setHoveredItem({
                        type: "meeting",
                        data: meeting,
                        overlappingEvents: overlappingEvents,
                      });
                    };

                    const handleMouseLeave = () => {
                      setHoveredItem(null);
                    };

                    return (
                      <li
                        key={meeting._id}
                        className="relative flex"
                        style={{
                          gridRow: `${startRow} / span ${span}`,
                          gridColumnStart: dayOfWeek,
                          position: "relative",
                        }}>
                        <span
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                          onClick={() => handleEventClick(meeting)}
                          className="group absolute flex flex-col overflow-y-auto rounded-lg px-1.5 py-1 text-xs/5 cursor-pointer"
                          style={{
                            backgroundColor: "#6B728020",
                            border: "1px dashed #6B7280",
                            width,
                            left,
                            top: "0px",
                            bottom: "0px",
                            zIndex: 1,
                          }}>
                          <p className="font-semibold text-gray-700">
                            <time
                              dateTime={`${meeting.date.split("T")[0]}T${
                                meeting.startTime
                              }`}>
                              {format(
                                parseISO(
                                  `${meeting.date.split("T")[0]}T${
                                    meeting.startTime
                                  }`
                                ),
                                "h:mm a"
                              )}
                            </time>
                            {" - Meeting"}
                          </p>
                          <p className="text-gray-600">{meeting.title}</p>
                          <p className="text-gray-600">
                            Attendees:{" "}
                            {meeting.attendees
                              .map((a) => `${a.firstName} ${a.lastName}`)
                              .join(", ")}
                          </p>
                          {meeting.description && (
                            <p className="text-gray-600 italic">
                              {meeting.description}
                            </p>
                          )}
                        </span>
                        {hoveredItem &&
                          hoveredItem.data._id === meeting._id && (
                            <div
                              className="enhanced-tooltip absolute z-50"
                              onMouseEnter={() =>
                                setHoveredItem({
                                  type: "meeting",
                                  data: meeting,
                                  overlappingEvents,
                                })
                              }
                              onMouseLeave={(e) => {
                                // Only leave if not entering another event
                                if (!e.relatedTarget?.closest(".group")) {
                                  setHoveredItem(null);
                                }
                              }}>
                              <EnhancedTooltip
                                mainEvent={hoveredItem.data}
                                overlappingEvents={
                                  hoveredItem.overlappingEvents
                                }
                                onEventHover={(event) => {
                                  setHoveredItem({
                                    type:
                                      "client" in event
                                        ? "appointment"
                                        : "meeting",
                                    data: event,
                                    overlappingEvents:
                                      hoveredItem.overlappingEvents,
                                  });
                                }}
                                onEventLeave={() => {}} // Remove the leave handler to keep tooltip persistent
                              />
                            </div>
                          )}
                      </li>
                    );
                  })}
              </ol>
            </div>
          </div>
        </div>

        {/* Quick Add Meeting Modal */}
        {isQuickAddMeetingOpen && (
          <QuickAddMeeting
            isOpen={isQuickAddMeetingOpen}
            onClose={() => setIsQuickAddMeetingOpen(false)}
            selectedMeeting={selectedEvent}
          />
        )}

        {/* Appointment Summary Modal */}
        {isAppointmentSummaryOpen && (
          <AppointmentSummary
            open={isAppointmentSummaryOpen}
            onClose={() => setIsAppointmentSummaryOpen(false)}
            appointment={selectedEvent}
          />
        )}
      </div>
    </div>
  );
}
