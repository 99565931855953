import React, { useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { createPayroll, updatePayroll } from "../../store/thunks/payrollThunk";
import { Listbox } from "@headlessui/react";
import {
  CheckIcon,
  ChevronUpDownIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";

const ManualPayrollOverride = ({
  isOpen,
  onClose,
  appointment,
  invoices,
  payrollDates,
  payroll = null,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user || []);

  const [selectedInvoice, setSelectedInvoice] = useState(
    invoices?.length > 0 ? invoices[0] : null
  );

  console.log("received this invoices", invoices);
  console.log("selectedinvoice", selectedInvoice);

  const [payPercentage, setPayPercentage] = useState(
    appointment?.clinician?.defaultPayPercentage
  );
  const [calculatedAmount, setCalculatedAmount] = useState(
    selectedInvoice ? (selectedInvoice.amount * payPercentage) / 100 : 0
  );
  const [overrideReason, setOverrideReason] = useState("");
  const [payrollStatus, setPayrollStatus] = useState("Pending");

  const [error, setError] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    if (Array.isArray(invoices) && invoices.length > 0) {
      setSelectedInvoice(invoices[0]);
    }
  }, [invoices]);

  console.log("received this payroll in manualpayrolloverride", payroll);

  // Prepopulate fields if a payroll prop is provided
  useEffect(() => {
    if (payroll) {
      setSelectedInvoice(payroll.invoiceId || invoices[0]);
      setPayPercentage(payroll.percentReceived || 45);
      setCalculatedAmount(payroll.payrollAmount || 0);
      setOverrideReason(payroll.overrideReason || "");
      setPayrollStatus(payroll.status || "Pending");
    } else if (invoices?.length > 0) {
      setSelectedInvoice(invoices[0]);
    }
  }, [payroll, invoices]);

  // Recalculate payroll amount when invoice or percentage changes
  useEffect(() => {
    if (selectedInvoice) {
      setCalculatedAmount((selectedInvoice.amount * payPercentage) / 100);
      console.log("selectedinvoice", selectedInvoice);
    }
  }, [selectedInvoice, payPercentage]);

  const findUpperBoundPayPeriod = () => {
    return payrollDates.find((period) => {
      const [startDateString] = period.includeDates.split("-");
      const startDate = new Date(Date.parse(startDateString.trim()));
      const paymentDate = new Date(Date.parse(period.paymentDate.trim()));

      paymentDate.setHours(23, 59, 59, 999); // Include the entire payment date

      return currentDate >= startDate && currentDate <= paymentDate;
    });
  };

  const [selectedPeriod, setSelectedPeriod] = useState(
    findUpperBoundPayPeriod()
  );

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    return new Date(dateString).toLocaleString(undefined, options);
  };

  const handleInvoiceChange = (invoice) => {
    setSelectedInvoice(invoice);
  };

  const handlePercentageChange = (e) => {
    const percentage = parseFloat(e.target.value);
    setPayPercentage(isNaN(percentage) ? 0 : percentage);
  };

  const handleSubmit = async () => {
    if (!selectedInvoice) {
      setError("Please select a valid invoice.");
      return;
    }
    if (!selectedPeriod) {
      setError("Please select a valid payroll period.");
      return;
    }
    if (!payPercentage || payPercentage <= 0) {
      setError("Pay percentage must be a number and greater than 0.");
      return;
    }
    if (!overrideReason.trim()) {
      setError("Override reason is required.");
      return;
    }

    const confirmation = window.confirm(
      payroll
        ? "You are about to update this payroll. Please ensure all fields are correct before proceeding. Do you want to continue?"
        : "You are about to manually create a payroll document. Please ensure all fields are correct before proceeding. Do you want to continue?"
    );

    if (!confirmation) {
      return; // Exit if the user cancels
    }

    setError(null); // Clear errors before submitting

    const payrollData = {
      appointmentId: appointment?._id || null,
      clinicianId: appointment?.clinician?._id || null,
      clientOrCompany: appointment?.client?._id || null,
      invoiceId: selectedInvoice._id,
      originalAmount: selectedInvoice.amount,
      percentReceived: payPercentage,
      payrollAmount: calculatedAmount,
      description: selectedInvoice.description,
      payPeriod: selectedPeriod.payPeriodNumber,
      payPeriodDates: selectedPeriod.includeDates,
      payBy: selectedPeriod.paymentDate,
      submitBy: selectedPeriod.submissionDate,
      status: payrollStatus || null,
      overrideReason: overrideReason || null,
      createdBy: user._id,
    };

    try {
      if (payroll) {
        // Update payroll
        await dispatch(
          updatePayroll({ id: payroll._id, updateData: payrollData })
        ).unwrap();
        alert("Payroll updated successfully!");
      } else {
        // Create new payroll
        await dispatch(createPayroll(payrollData)).unwrap();
        alert("Payroll created successfully!");
      }
      onClose();
    } catch (error) {
      if (error === "Payroll entry already exists for this appointment") {
        setError("Payroll entry already exists for this invoice.");
      } else {
        setError(error || "An error occurred while processing the payroll.");
      }
    }
  };

  return (
    <Transition appear show={isOpen} as={React.Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        {/* Background overlay */}
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-lg bg-white p-6 align-middle shadow-xl transition-all border-4 border-red-600">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-bold leading-6 text-gray-900">
                  {payroll ? "Update Payroll" : "Manual Payroll Override"}
                </Dialog.Title>

                <div className="my-2">
                  <p className="text-sm text-gray-600">
                    {payroll
                      ? "Manually update a payroll document."
                      : "Manually create a payroll document. An invoice must be selected."}
                  </p>
                </div>

                {/* Appointment Details */}
                <div className="my-8 space-y-2 text-sm">
                  <p>
                    <span className="font-medium">Service:</span>{" "}
                    {appointment?.title || "No description available"}
                  </p>
                  <p>
                    <span className="font-medium">Clinician:</span>{" "}
                    {`${appointment?.clinician?.firstName || ""} ${
                      appointment?.clinician?.lastName || ""
                    }`}
                  </p>
                  <p>
                    <span className="font-medium">Client:</span>{" "}
                    {`${appointment?.client?.firstName || ""} ${
                      appointment?.client?.lastName || ""
                    }`}
                  </p>
                  <p>
                    <span className="font-medium">Service Date:</span>{" "}
                    {formatDate(appointment?.start)}
                  </p>
                </div>

                {/* Payroll Period Selection */}
                <div className="mt-4">
                  <Listbox value={selectedPeriod} onChange={setSelectedPeriod}>
                    <div className="flex flex-col">
                      <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                        Select Payroll Period
                      </Listbox.Label>
                      <div className="relative mt-2 w-full">
                        <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                          <span className="block truncate">
                            {`Pay Period ${
                              selectedPeriod?.payPeriodNumber || ""
                            } (${selectedPeriod?.includeDates || ""})`}
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>
                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {payrollDates.map((period) => (
                            <Listbox.Option
                              key={period.payPeriodNumber}
                              value={period}
                              className={({ active, selected }) =>
                                `relative cursor-default select-none py-2 pl-3 pr-9 ${
                                  active
                                    ? "bg-indigo-600 text-white"
                                    : "text-gray-900"
                                }`
                              }>
                              {({ selected }) => (
                                <>
                                  <span
                                    className={`block truncate ${
                                      selected ? "font-semibold" : "font-normal"
                                    }`}>
                                    {`Pay Period ${period.payPeriodNumber} (${period.includeDates})`}
                                  </span>
                                  {selected && (
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600">
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  )}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </div>
                    </div>
                  </Listbox>
                </div>

                {/* Invoice Selection */}
                <div className="mt-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Select Invoice
                  </label>
                  <select
                    value={selectedInvoice?._id || ""}
                    onChange={(e) => {
                      const selected = invoices?.find(
                        (inv) => inv._id === e.target.value
                      );
                      handleInvoiceChange(selected);
                    }}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    disabled={!invoices?.length}>
                    {invoices?.length > 0 ? (
                      invoices.map((invoice) => (
                        <option key={invoice._id} value={invoice._id}>
                          {invoice.invoiceId || "No Description"} - $
                          {invoice.amount.toFixed(2)} - {invoice.status}
                        </option>
                      ))
                    ) : (
                      <option value="" disabled>
                        No invoices available
                      </option>
                    )}
                  </select>
                </div>

                {/* Payroll Status Selection */}
                <div className="mt-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Select Payroll Status
                  </label>
                  <select
                    value={payrollStatus || "Select one"}
                    onChange={(e) => setPayrollStatus(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                    {["Pending", "Ready", "Approved", "Paid to Clinician"].map(
                      (status) => (
                        <option key={status} value={status}>
                          {status}
                        </option>
                      )
                    )}
                  </select>
                </div>

                {/* Pay Percentage and Calculations */}
                <div className="mt-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Percent Received (Clinician's default is{" "}
                    {appointment?.clinician?.defaultPayPercentage !== undefined
                      ? `${appointment.clinician.defaultPayPercentage}%`
                      : "N/A"}
                    )
                  </label>
                  <input
                    type="number"
                    min="0"
                    // max="100"
                    value={payPercentage}
                    onChange={handlePercentageChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                  <div className="mt-2 text-sm text-gray-600 space-y-1">
                    <p>
                      <span className="font-medium">Invoice Amount:</span> $
                      {selectedInvoice?.amount?.toFixed(2) || "0.00"}
                    </p>
                    <p>
                      <span className="font-medium">Percent Received:</span>{" "}
                      {payPercentage}%
                    </p>
                    <p>
                      <span className="font-medium">Payroll Amount:</span>{" "}
                      <span className="font-semibold">
                        ${calculatedAmount.toFixed(2)}
                      </span>
                    </p>
                  </div>
                </div>

                {/* Override Reason */}
                <div className="mt-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Override Reason (Required)
                  </label>
                  <textarea
                    value={overrideReason}
                    onChange={(e) => setOverrideReason(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"></textarea>
                </div>

                {/* Error Message */}
                {error && <p className="mt-2 text-sm text-red-600">{error}</p>}

                {/* Actions */}
                <div className="mt-6 flex justify-end space-x-3">
                  <button
                    type="button"
                    className="rounded-md bg-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-400"
                    onClick={onClose}>
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="rounded-md bg-red-600 px-4 py-2 text-sm font-medium text-white hover:bg-red-700"
                    onClick={handleSubmit}>
                    {payroll ? "Update Payroll" : "Create Payroll"}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ManualPayrollOverride;
