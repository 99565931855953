import UserCards from "../../../applicationUi/components/UserCards";
import AppointmentList from "../../../applicationUi/components/AppointmentList";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchAppointmentsByClinicianId } from "../../../store/thunks/appointmentsThunk";
import ClinicianToDoList from "./ClinicianToDoList";
import { fetchTodos } from "../../../store/thunks/todoThunk";
import { logInfo, logError } from "../../../utils/logger";

export default function ClinicianDashboardComponent({
  clinician,
  handleClientClick,
  handleAppointmentClick,
  handleReschedule,
}) {
  const dispatch = useDispatch();
  const appointments = useSelector(
    (state) => state.appointments.appointments || []
  );
  const user = useSelector((state) => state.auth.user || {});

  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (clinician?._id) {
      dispatch(
        fetchTodos({ userRole: "clinician", clinicianId: clinician._id })
      )
        .unwrap()
        .then(() => {
          logInfo("Todos fetched successfully", {
            componentName: "ClinicianDashboardComponent",
            action: "fetchTodos",
            clinicianId: clinician._id,
          });
        })
        .catch((error) => {
          logError("Failed to fetch todos", {
            componentName: "ClinicianDashboardComponent",
            action: "fetchTodos",
            clinicianId: clinician._id,
            error: error.message,
          });
        });
    }
  }, [dispatch, refresh, clinician]);

  useEffect(() => {
    if (appointments && user._id) {
      const filtered = appointments.filter(
        (appointment) => appointment.clinician?._id === user._id
      );
      setFilteredAppointments(filtered);
      logInfo("Filtered appointments updated", {
        componentName: "ClinicianDashboardComponent",
        action: "updateFilteredAppointments",
        appointmentsCount: appointments.length,
        filteredCount: filtered.length,
      });
    }
  }, [appointments, user]);

  const handleFilterAppointments = (filterFunction) => {
    const filtered = filterFunction(appointments);
    setFilteredAppointments(filtered);
    logInfo("Appointments filtered", {
      componentName: "ClinicianDashboardComponent",
      action: "filterAppointments",
      filteredCount: filtered.length,
    });
  };

  useEffect(() => {
    dispatch(fetchAppointmentsByClinicianId(clinician._id))
      .unwrap()
      .then(() => {
        logInfo("Appointments fetched successfully", {
          componentName: "ClinicianDashboardComponent",
          action: "fetchAppointmentsByClinicianId",
          clinicianId: clinician._id,
        });
      })
      .catch((error) => {
        logError("Failed to fetch appointments", {
          componentName: "ClinicianDashboardComponent",
          action: "fetchAppointmentsByClinicianId",
          clinicianId: clinician._id,
          error: error.message,
        });
      });
  }, [dispatch, clinician, refresh]);

  const handleWideSidePanelClose = () => {
    setRefresh((prevRefresh) => !prevRefresh);
    logInfo("Wide side panel closed", {
      componentName: "ClinicianDashboardComponent",
      action: "closeWideSidePanel",
    });
  };

  useEffect(() => {
    logInfo("ClinicianDashboardComponent rendered", {
      componentName: "ClinicianDashboardComponent",
      clinicianId: clinician._id,
      appointmentsCount: appointments.length,
      filteredAppointmentsCount: filteredAppointments.length,
      refresh: refresh,
    });
  }, [clinician, appointments, filteredAppointments, refresh]);

  return (
    <div className="grid grid-cols-3 gap-6 pb-20">
      {/* Left Column */}
      <div className="col-span-2">
        {/* User Cards */}
        <UserCards
          appointments={appointments}
          onFilterAppointments={handleFilterAppointments}
        />

        {/* Appointment List aka Recent Activity */}
        <AppointmentList
          appointments={filteredAppointments}
          onAppointmentClick={handleAppointmentClick}
          onClientClick={handleClientClick}
          onReschedule={handleReschedule}
        />
      </div>

      {/* Right Column */}
      <div className="col-span-1">
        <ClinicianToDoList
          onReschedule={handleReschedule}
          onWideSidePanelClose={handleWideSidePanelClose}
        />
      </div>
    </div>
  );
}
