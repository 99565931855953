import { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Table from "../../applicationUi/components/Table";
import { fetchInsurers, createInsurer, updateInsurer } from "../../store/thunks/insurerThunk";
import GoogleAutoComplete from "../../applicationUi/components/GoogleAutoComplete";

export default function InsurerPage() {
  const dispatch = useDispatch();
  const { insurers, loading, error } = useSelector((state) => state.insurer);
  const [selectedInsurer, setSelectedInsurer] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    fax: "",
    address: {
      street: "",
      city: "",
      province: "", 
      postalCode: "",
      aptNumber: "",
    },
    contactPerson: "",
    email: "",
    notes: "",
  });

  const columns = [
    { header: "Name", accessor: "name" },
    { header: "Phone", accessor: "phone" },
    { header: "Contact Person", accessor: "contactPerson" },
    { header: "Email", accessor: "email" },
    { header: "City", accessor: "address.city" },
  ];

  useEffect(() => {
    dispatch(fetchInsurers());
  }, [dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate complete address
    if (!formData.address.street || !formData.address.city || !formData.address.province || !formData.address.postalCode) {
      alert("Please provide complete address information");
      return;
    }

    try {
      if (selectedInsurer) {
        await dispatch(updateInsurer({ id: selectedInsurer._id, data: formData }));
      } else {
        await dispatch(createInsurer(formData));
      }
      setIsModalOpen(false);
      resetForm();
      dispatch(fetchInsurers());
    } catch (error) {
      console.error("Error saving insurer:", error);
    }
  };

  const handleEdit = (insurerId) => {
    console.log("Insurer ID:", insurerId);
    
    // Find the full insurer object from state
    const insurer = insurers.find(ins => ins._id === insurerId);
    
    if (!insurer || !insurer.address) {
      console.error("Invalid insurer data:", insurer);
      return;
    }

    setSelectedInsurer(insurer);
    setFormData({
      name: insurer.name || "",
      phone: insurer.phone || "",
      fax: insurer.fax || "",
      address: {
        street: insurer.address.street || "",
        city: insurer.address.city || "", 
        province: insurer.address.province || "",
        postalCode: insurer.address.postalCode || "",
        aptNumber: insurer.address.aptNumber || "",
      },
      contactPerson: insurer.contactPerson || "",
      email: insurer.email || "",
      notes: insurer.notes || "",
    });
    setIsModalOpen(true);
  };

  const resetForm = () => {
    setSelectedInsurer(null);
    setFormData({
      name: "",
      phone: "",
      fax: "",
      address: {
        street: "",
        city: "",
        province: "",
        postalCode: "",
        aptNumber: "",
      },
      contactPerson: "",
      email: "",
      notes: "",
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">

      <Table
        columns={columns}
        data={insurers}
        onRowClick={(row) => handleEdit(row)}
        onSortChange={(key, direction) => {
          console.log("Sort changed", { key, direction });
        }}
        actions={[
          {
            label: "Add Insurer",
            onClick: () => {
              resetForm();
              setIsModalOpen(true);
            },
          },
        ]}
        title="Insurers"
        description="A list of all insurers."
      />

      <Transition.Root show={isModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setIsModalOpen(false)}
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => setIsModalOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <form onSubmit={handleSubmit} className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Insurer Name
                    </label>
                    <input
                      type="text"
                      required
                      value={formData.name}
                      onChange={(e) =>
                        setFormData({ ...formData, name: e.target.value })
                      }
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Phone
                    </label>
                    <input
                      type="tel"
                      required
                      value={formData.phone}
                      onChange={(e) =>
                        setFormData({ ...formData, phone: e.target.value })
                      }
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Fax
                    </label>
                    <input
                      type="tel"
                      value={formData.fax}
                      onChange={(e) =>
                        setFormData({ ...formData, fax: e.target.value })
                      }
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>

                  <div className="space-y-4">
                    <label className="block text-sm font-medium text-gray-700">
                      Address <span className="text-red-500">*</span>
                    </label>
                    <GoogleAutoComplete
                      inputId="address-autocomplete"
                      onPlaceSelected={(place) => {
                        // Auto-populate address fields from Google result
                        const addressComponents = place.address_components || [];
                        const streetNumber = addressComponents.find(c => c.types.includes('street_number'))?.long_name || '';
                        const route = addressComponents.find(c => c.types.includes('route'))?.long_name || '';
                        const city = addressComponents.find(c => c.types.includes('locality'))?.long_name || '';
                        const province = addressComponents.find(c => c.types.includes('administrative_area_level_1'))?.short_name || '';
                        const postalCode = addressComponents.find(c => c.types.includes('postal_code'))?.long_name || '';

                        setFormData({
                          ...formData,
                          address: {
                            ...formData.address,
                            street: streetNumber ? `${streetNumber} ${route}` : route,
                            city,
                            province,
                            postalCode
                          }
                        });
                      }}
                    />
                    
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                      <div>
                        <label className="block text-sm font-medium text-gray-700">Apt/Suite</label>
                        <input
                          type="text"
                          value={formData.address.aptNumber}
                          onChange={(e) => setFormData({
                            ...formData,
                            address: { ...formData.address, aptNumber: e.target.value }
                          })}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">Street <span className="text-red-500">*</span></label>
                        <input
                          type="text"
                          required
                          value={formData.address.street}
                          onChange={(e) => setFormData({
                            ...formData,
                            address: { ...formData.address, street: e.target.value }
                          })}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
                      <div>
                        <label className="block text-sm font-medium text-gray-700">City <span className="text-red-500">*</span></label>
                        <input
                          type="text"
                          required
                          value={formData.address.city}
                          onChange={(e) => setFormData({
                            ...formData,
                            address: { ...formData.address, city: e.target.value }
                          })}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">Province <span className="text-red-500">*</span></label>
                        <input
                          type="text"
                          required
                          value={formData.address.province}
                          onChange={(e) => setFormData({
                            ...formData,
                            address: { ...formData.address, province: e.target.value }
                          })}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">Postal Code <span className="text-red-500">*</span></label>
                        <input
                          type="text"
                          required
                          value={formData.address.postalCode}
                          onChange={(e) => setFormData({
                            ...formData,
                            address: { ...formData.address, postalCode: e.target.value }
                          })}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Contact Person
                    </label>
                    <input
                      type="text"
                      value={formData.contactPerson}
                      onChange={(e) =>
                        setFormData({ ...formData, contactPerson: e.target.value })
                      }
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Email
                    </label>
                    <input
                      type="email"
                      value={formData.email}
                      onChange={(e) =>
                        setFormData({ ...formData, email: e.target.value })
                      }
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Notes
                    </label>
                    <textarea
                      value={formData.notes}
                      onChange={(e) =>
                        setFormData({ ...formData, notes: e.target.value })
                      }
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>

                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="submit"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                    >
                      {selectedInsurer ? "Update" : "Create"}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={() => setIsModalOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
