import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import PropTypes from "prop-types";

export default function Pagination({
  currentPage,
  totalPages,
  onPageChange,
  totalResults,
}) {
  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const handlePageClick = (page) => {
    onPageChange(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5;
    const ellipsis = "...";

    if (totalPages <= maxPagesToShow + 2) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(
          <a
            key={i}
            href="#"
            onClick={() => handlePageClick(i)}
            aria-current={i === currentPage ? "page" : undefined}
            className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
              i === currentPage ? "bg-indigo-600 text-white" : "text-gray-900"
            } ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0`}>
            {i}
          </a>
        );
      }
    } else {
      let startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
      let endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);

      if (startPage > 1) {
        pageNumbers.push(
          <a
            key={1}
            href="#"
            onClick={() => handlePageClick(1)}
            className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
              currentPage === 1 ? "bg-indigo-600 text-white" : "text-gray-900"
            } ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0`}>
            1
          </a>
        );
        if (startPage > 2) {
          pageNumbers.push(
            <span
              key="ellipsis-start"
              className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700">
              {ellipsis}
            </span>
          );
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(
          <a
            key={i}
            href="#"
            onClick={() => handlePageClick(i)}
            aria-current={i === currentPage ? "page" : undefined}
            className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
              i === currentPage ? "bg-indigo-600 text-white" : "text-gray-900"
            } ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0`}>
            {i}
          </a>
        );
      }

      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          pageNumbers.push(
            <span
              key="ellipsis-end"
              className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700">
              {ellipsis}
            </span>
          );
        }
        pageNumbers.push(
          <a
            key={totalPages}
            href="#"
            onClick={() => handlePageClick(totalPages)}
            className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
              currentPage === totalPages
                ? "bg-indigo-600 text-white"
                : "text-gray-900"
            } ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0`}>
            {totalPages}
          </a>
        );
      }
    }

    return pageNumbers;
  };

  return (
    <div className="w-full flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
      <div className="flex flex-1 justify-between sm:hidden">
        <a
          href="#"
          onClick={handlePrevious}
          className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
          Previous
        </a>
        <a
          href="#"
          onClick={handleNext}
          className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
          Next
        </a>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing{" "}
            <span className="font-medium">{(currentPage - 1) * 10 + 1}</span> to{" "}
            <span className="font-medium">
              {Math.min(currentPage * 10, totalResults)}
            </span>{" "}
            of <span className="font-medium">{totalResults}</span> results
          </p>
        </div>
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination">
            <a
              href="#"
              onClick={handlePrevious}
              className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </a>
            {renderPageNumbers()}
            <a
              href="#"
              onClick={handleNext}
              className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </a>
          </nav>
        </div>
      </div>
    </div>
  );
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  totalResults: PropTypes.number.isRequired,
};
