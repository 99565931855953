import React, { useState } from "react";
import { useDispatch } from "react-redux";
import SearchDropdown from "../../applicationUi/components/SearchDropdown";
import { createPayroll } from "../../store/thunks/payrollThunk";

export default function CustomPayrollModal({
  clinician,
  selectedPeriod,
  isOpen,
  onClose,
}) {
  const [appointment, setAppointment] = useState("");
  const [clientOrCompany, setClientOrCompany] = useState("");
  const [payrollAmount, setPayrollAmount] = useState("");
  const [description, setDescription] = useState("");
  const [successMessage, setSuccessMessage] = useState(null); // Success message state
  const [errorMessage, setErrorMessage] = useState(null); // Error message state
  const [isLoading, setIsLoading] = useState(false); // Loading state

  const dispatch = useDispatch();

  if (!isOpen) return null;

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setSuccessMessage(null);
    setErrorMessage(null);

    const payrollData = {
      appointmentId: appointment ? appointment._id : null,
      clinicianId: clinician._id,
      clientOrCompany: clientOrCompany,
      payrollAmount: payrollAmount ? parseFloat(payrollAmount) : 0,
      description: description || "",
      isCustom: true,
      status: "Pending",
      payPeriod: selectedPeriod.payPeriodNumber,
      payPeriodDates: selectedPeriod.includeDates,
      submitBy: selectedPeriod.submissionDate,
      payBy: selectedPeriod.paymentDate,
    };

    console.log("Dispatching create payroll with this data", payrollData);

    try {
      const response = await dispatch(createPayroll(payrollData)).unwrap();
      setSuccessMessage(response.message || "Payroll created successfully!");
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage(
          error.message || "Failed to create payroll. Please try again."
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-500"
          aria-label="Close modal"
        >
          <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <div className="mb-6">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Add Custom Payroll Item
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            All fields are optional.
          </p>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="space-y-6">
            <div>
              <label
                htmlFor="clinician"
                className="block text-sm font-medium leading-6 text-gray-900">
                Clinician
              </label>
              <div className="">
                <p className="block w-full py-1.5 text-gray-900 sm:text-sm">
                  {clinician.firstName} {clinician.lastName}
                </p>
              </div>
            </div>

            {/* <div>
              <label
                htmlFor="appointment"
                className="block text-sm font-medium leading-6 text-gray-900">
                Appointment
              </label>
              <div className="mt-2">
                <input
                  id="appointment"
                  name="appointment"
                  type="text"
                  value={appointment}
                  onChange={(e) => setAppointment(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
                />
              </div>
            </div> */}

            <div>
              <label
                htmlFor="clientOrCompany"
                className="block text-sm font-medium leading-6 text-gray-900">
                Client
              </label>
              <SearchDropdown
                prefix=":client:"
                displayFields={[
                  "firstName",
                  "lastName",
                  "email",
                  "phoneNumbers",
                ]}
                onSelection={(client) => setClientOrCompany(client._id)}
                placeholder={"Search for an existing client"}
              />
            </div>

            <div>
              <label
                htmlFor="amount"
                className="block text-sm font-medium leading-6 text-gray-900">
                Amount
              </label>
              <div className="mt-2">
                <input
                  id="amount"
                  name="amount"
                  type="number"
                  step="0.01"
                  value={payrollAmount}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*\.?\d{0,2}$/.test(value)) {
                      // Allow numbers with up to 2 decimal places
                      setPayrollAmount(value);
                    }
                  }}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="description"
                className="block text-sm font-medium leading-6 text-gray-900">
                Description
              </label>
              <div className="mt-2">
                <textarea
                  id="description"
                  name="description"
                  rows={3}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm"
                />
              </div>
            </div>

            {/* Display success or error messages */}
            {successMessage && (
              <div className="text-green-600 text-sm mt-2">
                {successMessage}
              </div>
            )}
            {errorMessage && (
              <div className="text-red-600 text-sm mt-2">{errorMessage}</div>
            )}

            {/* Loading state */}
            {isLoading && (
              <div className="text-gray-600 text-sm">Loading...</div>
            )}
          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
              type="button"
              onClick={onClose}
              className="text-sm font-semibold leading-6 text-gray-900">
              Cancel
            </button>
            <button
              type="submit"
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              disabled={isLoading} // Disable the button while loading
            >
              {isLoading ? "Submitting..." : "Add Item"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
