import React, { useRef, useState, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSplitInvoice, fetchInvoicesByAppointmentId } from "../../store/thunks/invoiceThunk";
import CreateInvoice from "./CreateInvoice";

export default function CreateSplitInvoice({ appointment }) {
  const dispatch = useDispatch();
  const globalStatus = useSelector((state) => state.invoices.status);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [numSplits, setNumSplits] = useState(2);
  const invoiceRefs = useRef([]);

  // Initialize refs array when numSplits changes
  React.useEffect(() => {
    invoiceRefs.current = Array(numSplits).fill().map((_, i) => invoiceRefs.current[i] || React.createRef());
  }, [numSplits]);

  const handleSubmit = async () => {
    const invoiceDataArray = invoiceRefs.current.map(ref => ref?.current?.getInvoiceData()).filter(Boolean);

    if (invoiceDataArray.length !== numSplits) {
      console.error("All invoice forms must be filled out.");
      return;
    }

    try {
      setIsLoading(true);
      // First invoice is child, rest are parents
      const [childInvoiceData, ...parentInvoiceData] = invoiceDataArray;
      await dispatch(createSplitInvoice({ childInvoiceData, parentInvoiceData }));
      await dispatch(fetchInvoicesByAppointmentId(appointment._id));
      setIsSuccess(true);
    } catch (error) {
      console.error("Error creating split invoices:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <h3 className="text-lg font-semibold mb-4">Create Split Invoice</h3>

      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Number of Ways to Split:</label>
        <input
          type="number"
          min="2"
          max="10"
          className="mt-1 block w-48 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          value={numSplits}
          onChange={(e) => setNumSplits(Math.max(2, parseInt(e.target.value) || 2))}
        />
      </div>

      <div className="bg-white rounded-lg shadow-md p-6 mb-6">
        <h4 className="text-md font-medium mb-2">Child Invoice</h4>
        <CreateInvoice 
          ref={invoiceRefs.current[0]} 
          appointment={appointment} 
          isSplit 
          isChild={true}
        />
      </div>

      {Array.from({ length: numSplits - 1 }, (_, index) => (
        <div key={index} className="bg-white rounded-lg shadow-md p-6 mb-6">
          <h4 className="text-md font-medium mb-2">Parent Invoice {index + 1}</h4>
          <CreateInvoice 
            ref={invoiceRefs.current[index + 1]} 
            appointment={null}
            isSplit 
            isParent={true}
            requireParentSelection={true}
          />
        </div>
      ))}

      <button
        onClick={handleSubmit}
        disabled={isLoading || isSuccess}
        className={`mt-6 px-4 py-2 rounded ${isLoading
            ? "bg-gray-400"
            : isSuccess
              ? "bg-green-600 hover:bg-green-600"
              : "bg-indigo-600 hover:bg-indigo-700"
          } text-white`}
      >
        {isLoading
          ? "Creating..."
          : isSuccess
            ? "Invoices Created"
            : "Submit Split Invoice"}
      </button>
    </div>
  );
}
