import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../../applicationUi/components/Table";
import { format } from "date-fns";
import { fetchActiveClientsReport } from "../../../../store/thunks/reportsThunk";

const ActiveClientsReport = () => {
  const dispatch = useDispatch();
  const [daysBack, setDaysBack] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const { data: clients, loading, error } = useSelector((state) => state.reports.activeClients);

  useEffect(() => {
    dispatch(fetchActiveClientsReport({ daysBack }));
  }, [dispatch, daysBack]);

  const columns = [
    {
      header: "Client Name",
      accessor: "name",
    },
    {
      header: "Last Appointment",
      accessor: (row) => row.lastAppointment ? format(new Date(row.lastAppointment), 'MM/dd/yyyy') : 'Never'
    },
    {
      header: "Next Appointment", 
      accessor: (row) => row.nextAppointment ? format(new Date(row.nextAppointment), 'MM/dd/yyyy') : 'None Scheduled'
    }
  ];

  const handleDaysChange = (e) => {
    setDaysBack(Number(e.target.value));
    setCurrentPage(1); // Reset to first page when filter changes
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  // Calculate pagination
  const totalItems = Array.isArray(clients) ? clients.length : 0;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedData = clients?.slice(startIndex, startIndex + itemsPerPage) || [];

  return (
    <div className="space-y-4">
      <div className="mb-4">
        <label htmlFor="daysBack" className="block text-sm font-medium text-gray-700">
          Show clients with appointments in the last:
        </label>
        <select
          id="daysBack"
          value={daysBack}
          onChange={handleDaysChange}
          className="mt-1 block w-48 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        >
          <option value={0}>Future appointments only</option>
          <option value={14}>14 days</option>
          <option value={30}>30 days</option>
          <option value={60}>60 days</option>
          <option value={90}>90 days</option>
        </select>
      </div>

      <div className="bg-white shadow-sm rounded-lg">
        <Table
          columns={columns}
          data={paginatedData}
          title="Active Clients Report"
          description="View active clients and their appointment history"
          showPagination={true}
          currentPage={currentPage}
          totalPages={totalPages}
          totalResults={totalItems}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default ActiveClientsReport;
