import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import ToDoItem from "../../../applicationUi/components/toDoItem";
import Accordion from "../../../applicationUi/components/Accordion";
import { logInfo, logError } from "../../../utils/logger";

function ClinicianToDoList({ onReschedule, onWideSidePanelClose }) {
  const { todos, loading, error } = useSelector((state) => state.todos);

  useEffect(() => {
    logInfo("ClinicianToDoList rendered", {
      componentName: "ClinicianToDoList",
      todosCount: todos ? Object.values(todos).flat().length : 0,
      loading,
      hasError: !!error,
    });
  }, [todos, loading, error]);

  if (loading) {
    logInfo("ClinicianToDoList loading", {
      componentName: "ClinicianToDoList",
    });
    return <div>Loading...</div>;
  }

  if (error) {
    logError("Error in ClinicianToDoList", {
      componentName: "ClinicianToDoList",
      error: error.message || error,
    });
    return <div>Error: {error}</div>;
  }

  if (!todos || typeof todos !== "object") {
    logInfo("No todos found in ClinicianToDoList", {
      componentName: "ClinicianToDoList",
    });
    return <div>No To-Do items found.</div>;
  }

  // Filter out categories with no todos
  const nonEmptyTodos = Object.entries(todos).filter(
    ([_, todosInCategory]) =>
      Array.isArray(todosInCategory) && todosInCategory.length > 0
  );

  // Calculate total todos from non-empty categories only
  const totalTodos = nonEmptyTodos.reduce(
    (total, [_, todosInCategory]) => total + todosInCategory.length,
    0
  );

  return (
    <div>
      <h2 className="mx-auto mt-8 max-w-6xl text-lg font-medium leading-6 text-gray-900">
        To Do List - {totalTodos} items
      </h2>
      <div className="mt-2 mx-auto max-w-8xl max-h-112 overflow-y-auto divide-y divide-gray-200">
        {nonEmptyTodos.map(([category, todosInCategory]) => {
          logInfo("Rendering todo category", {
            componentName: "ClinicianToDoList",
            category,
            todoCount: todosInCategory.length,
          });
          return (
            <Accordion
              key={category}
              title={`${category} (${todosInCategory.length})`}>
              <ul>
                {todosInCategory.map((todo) => (
                  <ToDoItem
                    key={todo._id}
                    todo={todo}
                    onReschedule={onReschedule}
                    onWideSidePanelClose={onWideSidePanelClose}
                  />
                ))}
              </ul>
            </Accordion>
          );
        })}
      </div>
    </div>
  );
}

export default ClinicianToDoList;
