import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import Table from "../Table";
import QuickBooksLogo from "../../../assets/quickbooks-1.svg";
import InvoiceModal from "../InvoiceModal";
import { setCurrentInvoice } from "../../../store/slices/invoiceSlice";
import { setCurrentPayment } from "../../../store/slices/paymentsSlice";

export const AppointmentPayments = () => {
  const dispatch = useDispatch();
  const rawPayments = useSelector((state) => state.payments.appointmentPayments);
  const invoices = useSelector((state) => state.invoices.appointmentInvoices);
  const isLoading = useSelector((state) => state.payments.loading);
  const clinic = useSelector((state) => state.clinics.selectedClinic);

  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [key, setKey] = useState(0);
  const [payments, setPayments] = useState([]);

  // Normalize payments structure
  useEffect(() => {
    const normalizedPayments = rawPayments.map((payment) => {
      if (payment.paymentDate) {
        // Case: Loaded from appointment, create a consistent `payment` object
        return {
          payment: {
            paymentDate: payment.paymentDate,
            paymentMethod: payment.paymentMethod,
            amount: payment.amount,
            invoiceId: payment.invoiceId,
            QBOPaymentId: payment.QBOPaymentId,
          },
        };
      }
      // Case: Already in `payment` object structure
      return payment;
    });
    setPayments(normalizedPayments);
  }, [rawPayments]);

  const handleRowClick = (payment) => {
    const invoiceId = payment?.payment?.invoiceId?._id;

    if (!invoiceId) {
      console.error("Invoice ID is missing or invalid:", payment);
      return;
    }

    const invoice = invoices.find((inv) => inv._id === invoiceId);

    if (!invoice) {
      console.error("No matching invoice found for payment:", payment);
      return;
    }

    dispatch(setCurrentPayment(payment));
    dispatch(setCurrentInvoice(invoice));
    setShowInvoiceModal(true);
  };

  return (
    <>
      <div className="border border-gray-200 rounded-md px-4 py-4">
        <div className="mt-2">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Payments
          </h3>
          <div className="border-t border-gray-100 px-2 py-2 sm:px-0">
            {isLoading ? (
              <div className="flex justify-center items-center py-4">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
              </div>
            ) : payments && payments.length > 0 ? (
              <Table
                key={key}
                columns={[
                  {
                    header: "Payment Date",
                    accessor: (row) => {
                      const date = new Date(row.payment.paymentDate);
                      return date.toLocaleDateString("en-US", {
                        weekday: 'short',
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      });
                    },
                  },
                  {
                    header: "Payment Method",
                    accessor: (row) => {
                      const paymentMethod = clinic?.paymentMethods?.find(
                        (method) => method.value === row.payment.paymentMethod
                      );
                      return paymentMethod?.englishDescription || row.payment.paymentMethod;
                    },
                  },
                  {
                    header: "Amount",
                    accessor: (row) => `$${
                      row.payment.amount?.toFixed(2) || "0.00"
                    }`,
                  },
                  {
                    header: "Invoice",
                    accessor: (row) => row.payment.invoiceId?.invoiceId || "N/A",
                  },
                  {
                    header: "QuickBooks",
                    accessor: (row) => (
                      <div className="flex items-center">
                        {row.payment.QBOPaymentId && (
                          <img
                            src={QuickBooksLogo}
                            alt="QuickBooks"
                            className="ml-2 h-5 w-5"
                            title="Loaded to QuickBooks"
                          />
                        )}
                      </div>
                    ),
                  },
                ]}
                data={payments}
                onRowClick={handleRowClick}
              />
            ) : (
              <p className="text-sm text-gray-500 py-4">No payments found</p>
            )}
          </div>
        </div>
      </div>
      {showInvoiceModal && (
        <InvoiceModal
          onClose={() => setShowInvoiceModal(false)}
        />
      )}
    </>
  );
};
