import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { generateConfig } from '../../utils/api';

export default function ConfirmAppointment() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [appointment, setAppointment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [confirmationStatus, setConfirmationStatus] = useState(null);

  useEffect(() => {
    const fetchAppointment = async () => {
      try {
        const { config, baseURL } = generateConfig();
        const response = await axios.get(`${baseURL}/api/appointments/${id}`, config);
        setAppointment(response.data);
        setLoading(false);
      } catch (err) {
        setError('Unable to fetch appointment details');
        setLoading(false);
      }
    };

    fetchAppointment();
  }, [id]);

  const handleConfirm = async () => {
    try {
      const { config, baseURL } = generateConfig();
      await axios.post(`${baseURL}/api/appointments/${id}/confirm`, {}, config);
      setConfirmationStatus('success');
    } catch (err) {
      setConfirmationStatus('error');
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-indigo-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-xl font-semibold text-red-600 mb-2">Error</h2>
          <p className="text-gray-600">{error}</p>
        </div>
      </div>
    );
  }

  if (confirmationStatus === 'success') {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-md">
          <div className="text-center">
            <svg className="mx-auto h-12 w-12 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
            </svg>
            <h2 className="mt-4 text-2xl font-semibold text-gray-900">Appointment Confirmed!</h2>
            <p className="mt-2 text-gray-600">Your appointment has been successfully confirmed.</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold text-gray-900 mb-6">Confirm Your Appointment</h2>
        
        {appointment && (
          <div className="space-y-4">
            <div>
              <p className="text-sm font-medium text-gray-500">Date & Time</p>
              <p className="mt-1">{new Date(appointment.startTime).toLocaleString()}</p>
            </div>
            
            <div>
              <p className="text-sm font-medium text-gray-500">Provider</p>
              <p className="mt-1">{appointment.clinicianName}</p>
            </div>

            <div>
              <p className="text-sm font-medium text-gray-500">Service</p>
              <p className="mt-1">{appointment.serviceName}</p>
            </div>

            <div className="pt-4">
              <button
                onClick={handleConfirm}
                className="w-full bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Confirm Appointment
              </button>
            </div>

            <div className="pt-2">
              <button
                onClick={() => navigate(`/reschedule-appointment/${id}`)}
                className="w-full bg-gray-100 text-gray-700 py-2 px-4 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
              >
                Reschedule
              </button>
            </div>

            <div className="pt-2">
              <button
                onClick={() => navigate(`/cancel-appointment/${id}`)}
                className="w-full bg-red-100 text-red-700 py-2 px-4 rounded-md hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
