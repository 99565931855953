import React, { useState, useEffect } from "react";
import InvoiceGenerator from "../../applicationUi/components/InvoiceGenerator";
import { useDispatch } from "react-redux";
import { setSelectedAppointment } from "../../store/slices/appointmentsSlice";
import { setCurrentInvoice } from "../../store/slices/invoiceSlice";
import { fetchInvoicesByAppointmentId } from "../../store/thunks/invoiceThunk";
import { fetchAppointmentById } from "../../store/thunks/appointmentsThunk";
import AppointmentSummary from "../../applicationUi/components/appointments/AppointmentSummary";

function PayrollDetails({
  payrollId,
  appointment, // Pass appointmentId as a prop
  selectedPeriod,
  selectedPeriodDates,
  submitBy,
  payBy,
  refetchInvoices,
  onRefresh,
}) {
  const [fetchedAppointment, setFetchedAppointment] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [showInvoiceGenerator, setShowInvoiceGenerator] = useState(false);
  const [isWideSidePanelOpen, setIsWideSidePanelOpen] = useState(false);
  const [payPercentage, setPayPercentage] = useState(0);
  const [calculatedAmount, setCalculatedAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch appointment details
        const fetchedAppointment = await dispatch(
          fetchAppointmentById(appointment._id)
        ).unwrap();
        setFetchedAppointment(fetchedAppointment);

        // Fetch associated invoices
        const fetchedInvoices = await dispatch(
          fetchInvoicesByAppointmentId(appointment._id)
        ).unwrap();
        setInvoices(fetchedInvoices);

        // Set default pay percentage and invoice
        if (fetchedAppointment?.clinician) {
          setPayPercentage(
            fetchedAppointment.clinician.defaultPayPercentage || 45
          );
        }

        if (fetchedInvoices.length > 0) {
          setSelectedInvoice(fetchedInvoices[0]); // Use the first invoice as default
          dispatch(setCurrentInvoice(fetchedInvoices[0]));
        }
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    fetchData();
  }, [dispatch, appointment]);

  useEffect(() => {
    if (selectedInvoice) {
      setCalculatedAmount(
        ((selectedInvoice.amount * payPercentage) / 100).toFixed(2)
      );
    }
  }, [selectedInvoice, payPercentage]);

  const handleInvoiceClick = (invoice) => {
    setSelectedInvoice(invoice);
    dispatch(setCurrentInvoice(invoice));
    setShowInvoiceGenerator(true);
  };

  const handleAppointmentClick = () => {
    dispatch(setSelectedAppointment(appointment));
    setIsWideSidePanelOpen(true);
  };

  const handleCloseWideSidePanel = () => {
    setIsWideSidePanelOpen(false);
    onRefresh();
  };

  const isCompleted =
    appointment?.history?.some((historyItem) =>
      historyItem.status.includes("Soap Notes Completed")
    ) &&
    selectedInvoice &&
    selectedInvoice.status.toLowerCase() === "paid";

  console.log("isCompleted", isCompleted);

  return (
    <div className="px-4 sm:px-0">
      <div className="border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          {/* Appointment Details */}
          {appointment && (
            <div className="my-4">
              <div className="flex items-start justify-between">
                <h3 className="text-base font-semibold leading-7 text-gray-900">
                  Appointment Information
                </h3>
              </div>

              <div
                className="bg-indigo-100 rounded-lg shadow-lg p-1 my-4 cursor-pointer hover:bg-indigo-300 transition duration-200"
                onClick={handleAppointmentClick}>
                <div className="bg-white px-4 py-6 rounded-tl rounded-tr sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Clinician
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0 sm:col-span-2">
                    {appointment.clinician
                      ? `${appointment.clinician.firstName} ${appointment.clinician.lastName}`
                      : "Clinician info unavailable"}
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Client
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0 sm:col-span-2">
                    {appointment.client
                      ? `${appointment.client.firstName} ${appointment.client.lastName}`
                      : "Client info unavailable"}
                  </dd>
                </div>
                <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Service
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0 sm:col-span-2">
                    {appointment.title || "Service info unavailable"}
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-6 rounded-bl rounded-br sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Service Date
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0 sm:col-span-2">
                    {appointment.start
                      ? new Intl.DateTimeFormat("en-US", {
                          weekday: "short",
                          month: "short",
                          day: "numeric",
                          year: "numeric",
                        }).format(new Date(appointment.start))
                      : "Date unavailable"}
                  </dd>
                </div>
              </div>
            </div>
          )}

          {/* Invoice Details */}
          {/* Invoice Details */}
          {(Array.isArray(invoices) ? invoices.length > 0 : invoices) && (
            <div className="my-4">
              <div className="flex items-start justify-between">
                <h3 className="text-base font-semibold leading-7 text-gray-900">
                  Invoice Information
                </h3>
              </div>

              {/* Normalize invoices to an array */}
              {(Array.isArray(invoices) ? invoices : [invoices]).map(
                (invoice, index) => (
                  <div
                    key={index}
                    className="bg-indigo-100 rounded-lg shadow-lg p-1 my-4 cursor-pointer hover:bg-indigo-300 transition duration-200"
                    onClick={() => handleInvoiceClick(invoice)}>
                    <div className="bg-white px-4 py-6 rounded-tl rounded-tr sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Invoice ID
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0 sm:col-span-2">
                        {invoice.invoiceId}
                      </dd>
                    </div>
                    <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Invoice Amount
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0 sm:col-span-2">
                        ${invoice.amount.toFixed(2)}
                      </dd>
                    </div>
                    <div className="bg-white px-4 py-6 rounded-bl rounded-br sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Invoice Status
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0 sm:col-span-2">
                        {invoice.status}
                      </dd>
                    </div>
                  </div>
                )
              )}

              {showInvoiceGenerator && selectedInvoice && (
                <InvoiceGenerator data={selectedInvoice} />
              )}
            </div>
          )}
        </dl>
      </div>

      {isWideSidePanelOpen && (
        <AppointmentSummary
          title="Appointment Summary"
          open={isWideSidePanelOpen}
          onClose={handleCloseWideSidePanel}
          appointment={appointment}
        />
      )}

      {!isCompleted && (
        <div className="mt-6 flex justify-end">
          <p>Payrolls will automatically save every hour.</p>
          {/* <button
            onClick={handleSavePayroll}
            className={`rounded-md px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
              isLoading
                ? "bg-gray-400 cursor-not-allowed"
                : selectedInvoice &&
                  selectedInvoice.status.toLowerCase() === "paid"
                ? "bg-green-500 ring-green-600/20 hover:bg-green-400 focus-visible:outline-green-600"
                : "bg-gray-400 cursor-not-allowed"
            }`}
            disabled={
              isLoading ||
              !(
                selectedInvoice &&
                selectedInvoice.status.toLowerCase() === "paid"
              )
            }>
            {isLoading ? "Saving..." : "Save to Payroll"}
          </button>

          {success && <div className="mt-4 text-green-600">{success}</div>}
          {error && <div className="mt-4 text-red-600">{error}</div>} */}
        </div>
      )}
    </div>
  );
}

export default PayrollDetails;
