import React, { useState } from "react";
import Pagination from "./Pagination";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/20/solid";

const PayrollTable = ({
  columns,
  data = [],
  onRowClick,
  actions = [],
  title,
  description,
  description2,
  description3,
  showPagination,
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const [sortState, setSortState] = useState({
    columnKey: null,
    direction: null,
  }); // State for sorting

  // Helper function to get nested property
  const getNestedProperty = (obj, path) => {
    return path
      .split(/[\.\[\]]/)
      .filter(Boolean)
      .reduce((acc, part) => acc && acc[part], obj);
  };

  // Sort data based on current sortState
  const sortedData = React.useMemo(() => {
    if (!sortState.columnKey || !sortState.direction) return data;

    const sortColumn = columns.find(
      (column) => column.header === sortState.columnKey
    );

    if (!sortColumn) return data;

    const compareFn = (a, b) => {
      const aValue =
        typeof sortColumn.accessor === "function"
          ? sortColumn.accessor(a)
          : getNestedProperty(a, sortColumn.accessor);

      const bValue =
        typeof sortColumn.accessor === "function"
          ? sortColumn.accessor(b)
          : getNestedProperty(b, sortColumn.accessor);

      if (aValue === bValue) return 0;

      if (sortState.direction === "ascending") {
        return aValue > bValue ? 1 : -1;
      } else if (sortState.direction === "descending") {
        return aValue < bValue ? 1 : -1;
      }
      return 0;
    };

    return [...data].sort(compareFn);
  }, [data, sortState, columns]);

  // Handle column header click for sorting
  const handleSort = (header) => {
    setSortState((prev) => {
      if (prev.columnKey === header) {
        // Rotate through default -> ascending -> descending -> default
        if (prev.direction === "ascending")
          return { columnKey: header, direction: "descending" };
        if (prev.direction === "descending")
          return { columnKey: null, direction: null };
      }
      return { columnKey: header, direction: "ascending" };
    });
  };

  return (
    <div className="px-4 py-4 sm:px-6 lg:px-4">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            {title}
          </h1>
          <p className="mt-2 text-sm text-gray-700">{description}</p>
          <p className="mt-2 text-sm text-gray-700">{description2}</p>
          <p className="mt-2 text-sm text-gray-700">{description3}</p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none flex space-x-2">
          {actions.map((action, index) => (
            <button
              key={index}
              type="button"
              onClick={action.onClick}
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              {action.label}
            </button>
          ))}
        </div>
      </div>

      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-4">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-4">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    {columns.map((column) => (
                      <th
                        key={column.header}
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                        onClick={() => handleSort(column.header)}>
                        <div className="flex items-center">
                          {column.header}
                          {sortState.columnKey === column.header && (
                            <span className="ml-1">
                              {sortState.direction === "ascending" && (
                                <ChevronUpIcon className="h-4 w-4 inline text-gray-500" />
                              )}
                              {sortState.direction === "descending" && (
                                <ChevronDownIcon className="h-4 w-4 inline text-gray-500" />
                              )}
                            </span>
                          )}
                        </div>
                      </th>
                    ))}
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {sortedData.length === 0 && (
                    <tr>
                      <td
                        colSpan={columns.length + 1}
                        className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
                        No appointments available.
                      </td>
                    </tr>
                  )}
                  {sortedData.map((item) => (
                    <tr
                      key={item._id}
                      onClick={() => onRowClick(item)}
                      className="cursor-pointer hover:bg-gray-100">
                      {columns.map((column) => (
                        <td
                          key={column.header}
                          className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {typeof column.accessor === "function"
                            ? column.accessor(item)
                            : getNestedProperty(item, column.accessor)}
                        </td>
                      ))}
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <span className="text-indigo-600 hover:text-indigo-900">
                          Edit<span className="sr-only">, {item._id}</span>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {showPagination && (
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={onPageChange}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayrollTable;
