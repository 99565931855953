import React, { useEffect, useState } from "react";
import Table from "../../applicationUi/components/Table";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchServices,
  fetchServiceById,
  exportServices,
} from "../../store/thunks/servicesThunk";
import EditServiceModal from "../services/EditServiceModal";
import SearchDropdown from "../../applicationUi/components/SearchDropdown";
import { fetchRoles } from "../../store/thunks/rolesThunk";
import { logError, logInfo } from "../../utils/logger";

function ServicesPage({ clickedItem }) {
  const dispatch = useDispatch();
  const {
    services,
    pagination,
    status: serviceStatus,
    error: serviceError,
  } = useSelector((state) => state.services);

  const [isServiceModalOpen, setIsServiceModalOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  // Separate active and inactive services
  const activeServices = services.filter((service) => service.active);
  const inactiveServices = services.filter((service) => !service.active);

  useEffect(() => {
    if (clickedItem && clickedItem._id) {
      logInfo("Fetching specific service", { serviceId: clickedItem._id });
      dispatch(fetchServiceById(clickedItem._id))
        .unwrap()
        .then((service) => {
          logInfo("Specific service fetched successfully", {
            serviceId: service._id,
          });
          setSelectedService(service);
        })
        .catch((error) => {
          logError("Failed to fetch specific service", {
            error,
            serviceId: clickedItem._id,
          });
          console.error("Failed to fetch specific service:", error);
        });
    } else {
      logInfo("Fetching services", {
        page: pagination.currentPage,
        limit: pagination.pageSize,
      });
      dispatch(
        fetchServices({
          page: pagination.currentPage,
          limit: pagination.pageSize,
        })
      )
        .unwrap()
        .then(() => {
          logInfo("Services fetched successfully", {
            serviceCount: services.length,
          });
        })
        .catch((error) => {
          logError("Failed to fetch services", { error });
          console.error("Failed to fetch services:", error);
        });
    }
  }, [
    dispatch,
    pagination.currentPage,
    pagination.pageSize,
    clickedItem,
    services.length,
  ]);

  useEffect(() => {
    dispatch(fetchServices({ page: 1, limit: pagination?.pageSize || 10 }));
    dispatch(fetchRoles());
  }, [dispatch, pagination?.pageSize, isServiceModalOpen]);

  const handleRowClick = (serviceId) => {
    const selectedService = services.find(
      (service) => service._id === serviceId
    );
    setIsServiceModalOpen(true);
    setSelectedService(selectedService);
  };

  const handleSearchClick = (service) => {
    setIsServiceModalOpen(true);
    setSelectedService(service);
  };

  const handleAddService = () => {
    setIsServiceModalOpen(true);
    setSelectedService(null); // Set to null for adding a new service
  };

  return (
    <div>
      <div className="m-8">
        <div className="text-base font-semibold leading-6 text-gray-900">
          Search for services by name:
        </div>
        <SearchDropdown
          prefix=":service:"
          displayFields={[
            "serviceCode",
            "description",
            "duration",
            "price",
          ].filter((field) => field !== null)}
          onSelection={(service) => handleSearchClick(service)}
          placeholder={"Type to start searching..."}
        />
      </div>
      {serviceStatus === "loading" && <p>Loading services...</p>}
      {serviceStatus === "failed" && (
        <p>
          Error loading services:{" "}
          {typeof serviceError === "object"
            ? JSON.stringify(serviceError)
            : serviceError}
        </p>
      )}
      {serviceStatus === "succeeded" && services.length === 0 && (
        <p>No services available</p>
      )}
      {serviceStatus === "succeeded" && services.length > 0 && (
        <>
          {selectedService ? (
            <EditServiceModal
              service={selectedService}
              onClose={() => {
                setIsServiceModalOpen(false);
                setSelectedService(null);
              }}
              onSave={() => {
                setIsServiceModalOpen(false);
                setSelectedService(null);
                dispatch(
                  fetchServices({
                    page: 1,
                    limit: pagination?.pageSize || 10,
                  })
                );
              }}
            />
          ) : (
            <div className="space-y-8">
              <Table
                columns={[
                  { header: "Service Code", accessor: "serviceCode" },
                  { header: "Description", accessor: "description" },
                  { header: "Duration", accessor: "duration" },
                  { header: "Price", accessor: "price" },
                  { header: "Color", accessor: "color" },
                ]}
                data={activeServices}
                onRowClick={handleRowClick}
                actions={[
                  {
                    label: "Add Service",
                    onClick: handleAddService,
                  },
                  {
                    label: "Download XLSX",
                    onClick: () => dispatch(exportServices()),
                  },
                ]}
                title="Active Services"
                description="A list of all active services."
                showPagination={pagination.totalPages > 1}
                currentPage={pagination.currentPage}
                totalPages={pagination.totalPages}
                onPageChange={(page) =>
                  dispatch(fetchServices({ page, limit: pagination.pageSize }))
                }
              />

              <Table
                columns={[
                  { header: "Service Code", accessor: "serviceCode" },
                  { header: "Description", accessor: "description" },
                  { header: "Duration", accessor: "duration" },
                  { header: "Price", accessor: "price" },
                  { header: "Color", accessor: "color" },
                ]}
                data={inactiveServices}
                onRowClick={handleRowClick}
                title="Inactive Services"
                description="A list of all inactive services."
                showPagination={pagination.totalPages > 1}
                currentPage={pagination.currentPage}
                totalPages={pagination.totalPages}
                onPageChange={(page) =>
                  dispatch(fetchServices({ page, limit: pagination.pageSize }))
                }
              />
            </div>
          )}
        </>
      )}

      {isServiceModalOpen && (
        <EditServiceModal
          service={selectedService}
          onClose={() => {
            setIsServiceModalOpen(false);
            setSelectedService(null);
          }}
          onSave={() => {
            setIsServiceModalOpen(false);
            setSelectedService(null);
            dispatch(
              fetchServices({
                page: 1,
                limit: pagination?.pageSize || 10,
              })
            );
          }}
        />
      )}
    </div>
  );
}

export default ServicesPage;
