import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createPayment } from "../store/thunks/paymentsThunk";
import CurrencyInput from "react-currency-input-field";

const InvoicePaymentModal = ({ payment, onClose }) => {
  const invoicesFromStore = useSelector(
    (state) => state.invoices.appointmentInvoices
  );
  const payments = useSelector((state) => state.payments.appointmentPayments);
  const selectedClinic = useSelector((state) => state.clinics.selectedClinic);
  const paymentStatus = useSelector((state) => state.payments.status);
  const paymentMethods = selectedClinic?.paymentMethods || [];
  const user = useSelector((state) => state.auth.user);

  // Normalize invoices
  const invoiceArray = Array.isArray(invoicesFromStore)
    ? invoicesFromStore
    : invoicesFromStore
    ? [invoicesFromStore]
    : [];

  const dispatch = useDispatch();

  const [selectedInvoices, setSelectedInvoices] = useState({});
  const [paymentAmounts, setPaymentAmounts] = useState({});
  const [paymentMethod, setPaymentMethod] = useState(
    payment?.paymentMethod || ""
  );
  const [paymentDate, setPaymentDate] = useState(
    new Date(payment?.paymentDate || new Date()).toISOString().split("T")[0]
  );
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  // Use a ref to track if we've already initialized the state
  const initializedRef = useRef(false);

  useEffect(() => {
    // If we've already initialized once and payment is not changing, skip re-initialization
    if (initializedRef.current && !payment) return;

    if (payment) {
      // When `payment` is passed, initialize with its details only once
      const invoice = payment.invoiceId;
      if (invoice && invoice._id) {
        setSelectedInvoices({ [invoice._id]: true });
        setPaymentAmounts({ [invoice._id]: (invoice.amount || 0).toFixed(2) });
      } else {
        console.warn("Invalid payment invoiceId:", invoice);
      }
      initializedRef.current = true;
    } else {
      // If no payment, initialize the selections only once when invoices are available
      if (invoiceArray.length > 0 && !initializedRef.current) {
        const initialSelection = {};
        const initialAmounts = {};

        invoiceArray.forEach((invoice) => {
          if (!invoice || !invoice._id) {
            console.warn("Invalid invoice object:", invoice);
            return;
          }

          const hasValidPayment = payments?.some(
            (pay) => pay?.invoiceId?._id === invoice._id && !pay?.voided
          );

          initialSelection[invoice._id] = false;
          initialAmounts[invoice._id] = invoice.amount
            ? invoice.amount.toFixed(2)
            : "0.00";
        });

        setSelectedInvoices(initialSelection);
        setPaymentAmounts(initialAmounts);
        initializedRef.current = true; // Mark as initialized
      }
    }
  }, [payment, invoiceArray, payments]);

  const handleInvoiceSelection = (invoiceId) => {
    if (payment) return; // Disable selection changes when editing a passed payment
    const hasValidPayment = payments?.some(
      (pay) => pay?.invoiceId?._id === invoiceId && !pay?.voided
    );
    if (!hasValidPayment) {
      setSelectedInvoices((prev) => ({
        ...prev,
        [invoiceId]: !prev[invoiceId],
      }));
    }
  };

  const handlePaymentAmountChange = (invoiceId, value) => {
    setPaymentAmounts((prev) => ({
      ...prev,
      [invoiceId]: value || "0.00",
    }));
  };

  const totalPaymentAmount = payment
    ? parseFloat(paymentAmounts[payment.invoiceId?._id] || "0")
    : invoiceArray
        .filter(
          (invoice) => invoice && invoice._id && selectedInvoices[invoice._id]
        )
        .reduce(
          (total, invoice) =>
            total + parseFloat(paymentAmounts[invoice._id] || "0"),
          0
        );

  const handleSavePayment = async () => {
    if (!paymentMethod || totalPaymentAmount <= 0) {
      setError("Please select unpaid invoices and enter a payment method.");
      return;
    }

    setError("");

    try {
      if (payment) {
        // Editing an existing payment
        const invoice = payment.invoiceId;
        if (invoice && invoice._id) {
          await dispatch(
            createPayment({
              invoiceId: invoice._id,
              paymentMethod,
              paymentAmount: parseFloat(paymentAmounts[invoice._id]).toFixed(2),
              paymentDate: new Date(paymentDate).toISOString(),
              createdBy: user._id,
            })
          ).unwrap();

          if (paymentStatus === "succeeded") {
            setSuccessMessage("Payment created successfully!");
          }
        } else {
          setError("Invalid invoice data for this payment.");
        }
      } else {
        // Processing new payments
        let successCount = 0;
        for (const invoice of invoiceArray) {
          if (invoice && invoice._id && selectedInvoices[invoice._id]) {
            try {
              await dispatch(
                createPayment({
                  invoiceId: invoice._id,
                  paymentMethod,
                  paymentAmount: parseFloat(
                    paymentAmounts[invoice._id]
                  ).toFixed(2),
                  paymentDate: new Date(paymentDate).toISOString(),
                  createdBy: user._id,
                })
              ).unwrap();
              successCount++;
            } catch (err) {
              console.error(
                `Failed to process payment for invoice ${invoice._id}:`,
                err
              );
            }
          }
        }

        if (successCount === 0) {
          setError("Failed to process any payments. Please try again.");
        } else if (paymentStatus === "succeeded") {
          setSuccessMessage("Payments processed successfully!");
        }
      }
    } catch (error) {
      console.error("Error processing payments:", error);
      setError("Failed to process payments. Please try again.");
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75">
      <div className="bg-white rounded-lg shadow-lg max-w-5xl w-full p-6 relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-500"
          aria-label="Close">
          <svg
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <h2 className="text-base font-semibold leading-7 text-gray-900 mb-4">
          Invoice Payment
        </h2>

        {successMessage && (
          <div className="mb-4 p-3 bg-green-100 text-green-700 rounded">
            {successMessage}
          </div>
        )}

        <div className="mb-6">
          <h3 className="text-sm font-medium text-gray-900 mb-2">
            {payment ? "Invoice Details:" : "Select Invoices to Pay:"}
          </h3>
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                {!payment && (
                  <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
                )}
                <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Invoice ID
                </th>
                <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Service Date
                </th>
                <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Client Name
                </th>
                <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Invoice Amount
                </th>
                <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Payment Amount
                </th>
                <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {(payment ? [payment.invoiceId] : invoiceArray).map((invoice) => {
                if (!invoice || !invoice._id) return null;
                const hasValidPayment = payments?.some(
                  (pay) => pay.invoiceId?._id === invoice._id && !pay.voided
                );

                return (
                  <tr
                    key={invoice._id}
                    className={hasValidPayment ? "bg-gray-50" : ""}>
                    {!payment && (
                      <td className="px-3 py-2 whitespace-nowrap">
                        <input
                          type="checkbox"
                          checked={!!selectedInvoices[invoice._id]}
                          onChange={() => handleInvoiceSelection(invoice._id)}
                          disabled={hasValidPayment}
                          className="mr-2"
                        />
                      </td>
                    )}
                    <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-700">
                      {invoice.invoiceId}
                    </td>
                    <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-700">
                      {invoice.serviceDate
                        ? new Date(invoice.serviceDate).toLocaleDateString()
                        : ""}
                    </td>
                    <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-700">
                      {invoice.client?.firstName} {invoice.client?.lastName}
                    </td>
                    <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-700">
                      ${invoice.amount?.toFixed(2) || "0.00"}
                    </td>
                    <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-700">
                      <CurrencyInput
                        id={`payment-amount-${invoice._id}`}
                        name={`payment-amount-${invoice._id}`}
                        prefix="$"
                        placeholder="Enter amount"
                        value={paymentAmounts[invoice._id] || "0.00"}
                        onValueChange={(value) =>
                          handlePaymentAmountChange(invoice._id, value)
                        }
                        decimalsLimit={2}
                        decimalSeparator="."
                        intlConfig={{
                          locale: "en-US",
                          currency: "USD",
                        }}
                        disabled={
                          (!payment && !selectedInvoices[invoice._id]) ||
                          hasValidPayment
                        }
                        className="w-24 rounded-md border-gray-300 shadow-sm"
                      />
                    </td>
                    <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-700">
                      {hasValidPayment ? (
                        <span className="text-green-600">Paid</span>
                      ) : (
                        <span className="text-red-600">Unpaid</span>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-900">
            Total Payment Amount
          </label>
          <p className="text-lg font-semibold text-gray-900">
            ${totalPaymentAmount.toFixed(2)}
          </p>
        </div>

        <div className="grid grid-cols-3 gap-4 mb-4">
          <div>
            <label className="block text-sm font-medium text-gray-900">
              Payment Method
            </label>
            <select
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
              className="block w-full mt-2 rounded-md border-gray-300 shadow-sm">
              <option value="">Select Method</option>
              {paymentMethods.map((method) => (
                <option key={method.value} value={method.value}>
                  {method.englishDescription}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-900">
              Payment Date
            </label>
            <input
              type="date"
              value={paymentDate}
              onChange={(e) => setPaymentDate(e.target.value)}
              className="block w-full mt-2 rounded-md border-gray-300 shadow-sm"
            />
          </div>
        </div>

        {error && (
          <div className="mb-4 p-3 bg-red-100 text-red-700 rounded">
            {error}
          </div>
        )}

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            onClick={onClose}
            className="text-sm font-semibold text-gray-900">
            Cancel
          </button>
          <button
            onClick={handleSavePayment}
            className="rounded-md px-3 py-2 text-sm font-semibold text-white bg-indigo-600 hover:bg-indigo-500 flex items-center"
            disabled={
              totalPaymentAmount <= 0 ||
              !paymentMethod ||
              paymentStatus === "loading"
            }>
            {paymentStatus === "loading" ? (
              <>
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24">
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Processing Payment...
              </>
            ) : (
              "Save Payment"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default InvoicePaymentModal;
