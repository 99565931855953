import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchAppointmentsList } from "../../../store/thunks/appointmentsThunk";
import WaagChart from "./WaagChart";
import { fetchWaagData } from '../../../store/thunks/waagThunk';
import UserToDoList from "./UserToDoList";

export default function OwnerDashboardComponent() {
    const dispatch = useDispatch();
    const waagData = useSelector((state) => state.waag.data);

    return (
        <div className="grid grid-cols-3 gap-6 pb-20">
            <div className="col-span-3">
                <WaagChart />
                <UserToDoList />
            </div>
        </div>
    );
}
