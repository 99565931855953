import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import payrollDates from "./payrollDates";
import HourlyPayrollCalendar from "./HourlyPayrollCalendar";
import { Listbox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { fetchAllHourlyPayrolls } from "../../store/thunks/hourlyPayrollThunk";
import Table from "../../applicationUi/components/Table";

export default function HRHourlyPayrollPage() {
  const dispatch = useDispatch();
  const [currentDate] = useState(new Date());
  // const [selectedPeriod, setSelectedPeriod] = useState(findCurrentPayPeriod());
  const [payrollData, setPayrollData] = useState([]);
  const [selectedPayroll, setSelectedPayroll] = useState(null);

  // function findCurrentPayPeriod() {
  //   let closestPeriod = payrollDates[0];
  //   payrollDates.forEach((period) => {
  //     const [startDateString, endDateString] = period.includeDates.split("-");
  //     const startDate = new Date(Date.parse(`${startDateString.trim()}`));
  //     const endDate = new Date(Date.parse(`${endDateString.trim()}`));
  //     endDate.setHours(23, 59, 59, 999);

  //     if (currentDate >= startDate && currentDate <= endDate) {
  //       closestPeriod = period;
  //     }
  //   });
  //   return closestPeriod;
  // }

  const findUpperBoundPayPeriod = () => {
    return payrollDates.find((period) => {
      const [startDateString] = period.includeDates.split("-");
      const startDate = new Date(Date.parse(startDateString.trim()));
      const paymentDate = new Date(Date.parse(period.paymentDate.trim()));

      paymentDate.setHours(23, 59, 59, 999); // Include the entire payment date

      return currentDate >= startDate && currentDate <= paymentDate;
    });
  };

  const [selectedPeriod, setSelectedPeriod] = useState(
    findUpperBoundPayPeriod()
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await dispatch(
          fetchAllHourlyPayrolls(selectedPeriod.payPeriodNumber)
        ).unwrap();

        // Ensure result is an array, or fallback to an empty array
        setPayrollData(Array.isArray(result) ? result : []);
      } catch (error) {
        console.error("Error fetching payroll data:", error);
        setPayrollData([]); // Default to an empty array on error
      }
    };
    fetchData();
  }, [dispatch, selectedPeriod]);

  const columns = [
    // { header: "User ID", accessor: (item) => item.userId._id || "Unknown" },
    {
      header: "First Name",
      accessor: (item) => item.userId.firstName || "Unknown",
    },
    {
      header: "Last Name",
      accessor: (item) => item.userId.lastName || "Unknown",
    },
    { header: "Email", accessor: (item) => item.userId.email || "Unknown" },
    {
      header: "Total Hours",
      accessor: (item) => calculateTotalHours(item.hours),
    },
    { header: "Status", accessor: "status" },
  ];

  const calculateTotalHours = (hours) => {
    return Object.values(hours || {}).reduce(
      (total, day) => total + (day.regular || 0),
      0
    );
  };

  const handleRowClick = (payrollId) => {
    const selected = payrollData.find((payroll) => payroll._id === payrollId);
    setSelectedPayroll(selected);
  };

  const closeModal = () => {
    setSelectedPayroll(null);
  };

  return (
    <div className="p-6 max-w-7xl mx-auto">
      <h1 className="text-2xl font-bold mb-4">HR Hourly Payroll</h1>

      <div className="mb-6">
        <p className="mb-2 text-lg">
          Currently viewing{" "}
          <strong>
            Pay Period {selectedPeriod.payPeriodNumber.split(".")[1]} of{" "}
            {selectedPeriod.payPeriodNumber.split(".")[0]}
          </strong>
        </p>
        <Listbox value={selectedPeriod} onChange={setSelectedPeriod}>
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left border focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
              <span className="block truncate">
                {selectedPeriod.includeDates}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {payrollDates.map((period) => (
                <Listbox.Option
                  key={period.payPeriodNumber}
                  value={period}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? "bg-amber-100 text-amber-900" : "text-gray-900"
                    }`
                  }>
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}>
                        {period.includeDates}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </div>
        </Listbox>
      </div>

      <Table
        columns={columns}
        data={payrollData}
        onRowClick={handleRowClick}
        title="Hourly Payroll Records"
        description="View all hourly payroll records for the selected pay period."
        showPagination={false}
      />

      {selectedPayroll && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-4xl">
            <h2 className="text-lg font-bold mb-4">Payroll Details</h2>
            <HourlyPayrollCalendar
              includeDates={selectedPeriod.includeDates}
              dailyHours={selectedPayroll.hours || {}}
              onHoursChange={() => {}} // Set to a no-op for HR view-only
            />
            <div className="flex justify-end mt-4">
              <button
                onClick={closeModal}
                className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-500">
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
