import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { PDFDownloadLink, Text, View, StyleSheet, PDFViewer, Document, Page, Image } from '@react-pdf/renderer';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { fetchClientStatement } from '../../../../store/thunks/reportsThunk';
import SearchDropdown from '../../../../applicationUi/components/SearchDropdown';

const PAGE_HEIGHT = 792; // LETTER size in points
const PAGE_MARGIN = 30;
const HEADER_HEIGHT = 80; // Height for logo + title
const FOOTER_HEIGHT = 40;
const SUBHEADER_HEIGHT = 20;
const CLIENT_INFO_HEIGHT = 100;
const TABLE_HEADER_HEIGHT = 30;
const ROW_BASE_HEIGHT = 20;
const SUMMARY_HEIGHT = 100;

const CONTENT_HEIGHT = PAGE_HEIGHT - (2 * PAGE_MARGIN) - HEADER_HEIGHT - FOOTER_HEIGHT;
const FIRST_PAGE_CONTENT_HEIGHT = CONTENT_HEIGHT - SUBHEADER_HEIGHT - CLIENT_INFO_HEIGHT;

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    flexDirection: 'column',
    padding: PAGE_MARGIN,
  },
  header: {
    height: HEADER_HEIGHT,
    marginBottom: 20,
    textAlign: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    paddingBottom: 5,
  },
  headerContent: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: 50,
    height: 50,
    marginRight: 10
  },
  title: {
    fontSize: 12,
    fontWeight: 'bold'
  },
  footer: {
    position: 'absolute',
    bottom: PAGE_MARGIN,
    left: PAGE_MARGIN,
    right: PAGE_MARGIN,
    height: FOOTER_HEIGHT,
    fontSize: 10,
    textAlign: 'center',
    borderTopWidth: 1,
    borderTopColor: '#000',
    paddingTop: 5,
  },
  contentWrapper: {
    flex: 1,
    flexGrow: 1,
  },
  subheader: {
    height: SUBHEADER_HEIGHT,
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
  },
  clientInfo: {
    height: CLIENT_INFO_HEIGHT,
    fontSize: 10,
    marginBottom: 20,
    textAlign: 'left',
  },
  section: {
    margin: 10,
    padding: 10,
  },
  tableHeader: {
    height: TABLE_HEADER_HEIGHT,
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    paddingBottom: 5,
    marginBottom: 10,
    fontWeight: 'bold',
  },
  row: {
    flexDirection: 'row',
    paddingVertical: 3,
    fontSize: 10,
  },
  dateCell: {
    width: '15%',
  },
  typeCell: {
    width: '15%',
  },
  descriptionCell: {
    width: '30%',
  },
  chargesCell: {
    width: '20%',
    textAlign: 'right',
  },
  paymentsCell: {
    width: '20%',
    textAlign: 'right',
  },
  summarySection: {
    height: SUMMARY_HEIGHT,
    marginTop: 20,
    paddingTop: 10,
    borderTopWidth: 2,
    borderTopColor: '#000',
  },
  summaryRow: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: 5,
    fontSize: 10,
  },
  summaryLabel: {
    width: '60%',
    textAlign: 'right',
    paddingRight: 10,
    fontWeight: 'bold',
  },
  summaryValue: {
    width: '20%',
    textAlign: 'right',
    fontWeight: 'bold',
  },
  balanceRow: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: 5,
    marginTop: 5,
    borderTopWidth: 1,
    borderTopColor: '#000',
    fontSize: 10,
  },
  balanceLabel: {
    width: '60%',
    textAlign: 'right',
    paddingRight: 10,
    fontWeight: 'bold',
  },
  balanceValue: {
    width: '40%',
    textAlign: 'right',
    fontWeight: 'bold',
  },
});

const calculateRowHeight = (transaction) => {
  const baseHeight = ROW_BASE_HEIGHT;

  // Dynamically calculate the number of lines needed for the description
  const descriptionLines = Math.ceil(transaction.description.length / 40); // Approx 40 chars per line
  const typeLines = Math.ceil(transaction.type.length / 15); // Approx 15 chars for type

  // Calculate the row height based on the tallest cell
  const totalLines = Math.max(1, descriptionLines, typeLines);

  return baseHeight * totalLines;
};

const paginateTransactions = (transactions) => {
  const pages = [];
  let currentPage = [];
  let currentHeight = 0;
  let isFirstPage = true;

  transactions.forEach((transaction) => {
    const rowHeight = calculateRowHeight(transaction);

    const availableHeight = isFirstPage
      ? FIRST_PAGE_CONTENT_HEIGHT
      : CONTENT_HEIGHT;

    // Check if the current row fits on the current page
    if (currentHeight + rowHeight > availableHeight) {
      // Push the current page to pages array
      pages.push(currentPage);

      // Start a new page
      currentPage = [transaction];
      currentHeight = rowHeight;
      isFirstPage = false;
    } else {
      // Add the row to the current page
      currentPage.push(transaction);
      currentHeight += rowHeight;
    }
  });

  // Add the last page if it contains rows
  if (currentPage.length > 0) {
    pages.push(currentPage);
  }

  return pages;
};

const StatementPDF = ({ data, dateRange, clientName, clientAddress }) => {
  const transactions = [
    ...(data?.invoices?.map((inv) => ({
      date: new Date(inv.serviceDate),
      type: 'Invoice',
      description: inv.services?.map((s) => s.description).join(', ') || 'Services',
      charges: inv.amount,
      payments: 0,
    })) || []),
    ...(data?.payments?.map((pay) => ({
      date: new Date(pay.paymentDate),
      type: 'Payment',
      description: `Payment - ${pay.paymentMethod || 'Unknown method'}`,
      charges: 0,
      payments: pay.amount,
    })) || []),
  ].sort((a, b) => a.date - b.date);

  const totalCharges = transactions.reduce((sum, t) => sum + t.charges, 0);
  const totalPayments = transactions.reduce((sum, t) => sum + t.payments, 0);

  const paginatedTransactions = paginateTransactions(transactions);

  return (
    <Document>
      {paginatedTransactions.map((pageTransactions, pageIndex) => (
        <Page size="LETTER" style={styles.page} key={pageIndex}>
          {/* Header */}
          <View style={styles.header} fixed>
            <View style={styles.headerContent}>
              <Image
                style={styles.logo}
                src="https://www.hearsay.ca/wp-content/uploads/2022/03/hearsay_logo_fnl_c.jpg"
              />
              <Text style={styles.title}>Client Statement</Text>
            </View>
          </View>

          {/* Content */}
          <View style={styles.contentWrapper}>
            {pageIndex === 0 && (
              <>
                <Text style={styles.subheader}>
                  Period: {dateRange.start} to {dateRange.end}
                </Text>
                <Text style={styles.clientInfo}>
                  {clientName}
                  {'\n'}
                  {clientAddress?.streetNumber} {clientAddress?.streetName}
                  {clientAddress?.aptUnit ? `, Unit ${clientAddress.aptUnit}` : ''}
                  {'\n'}
                  {clientAddress?.city}, {clientAddress?.province} {clientAddress?.postalCode}
                  {'\n'}
                  {clientAddress?.country}
                </Text>
              </>
            )}

            {/* Table */}
            <View style={styles.section}>
              <View style={styles.tableHeader}>
                <Text style={styles.dateCell}>Date</Text>
                <Text style={styles.typeCell}>Type</Text>
                <Text style={styles.descriptionCell}>Description</Text>
                <Text style={styles.chargesCell}>Charges</Text>
                <Text style={styles.paymentsCell}>Payments</Text>
              </View>

              {pageTransactions.map((transaction, i) => (
                <View
                  key={i}
                  style={[styles.row, { minHeight: calculateRowHeight(transaction) }]}
                >
                  <Text style={styles.dateCell}>
                    {transaction.date.toLocaleDateString()}
                  </Text>
                  <Text style={styles.typeCell}>{transaction.type}</Text>
                  <Text style={styles.descriptionCell}>{transaction.description}</Text>
                  <Text style={styles.chargesCell}>
                    {transaction.charges > 0 ? `$${transaction.charges.toFixed(2)}` : ''}
                  </Text>
                  <Text style={styles.paymentsCell}>
                    {transaction.payments > 0 ? `$${transaction.payments.toFixed(2)}` : ''}
                  </Text>
                </View>
              ))}
            </View>

            {/* Summary */}
            {pageIndex === paginatedTransactions.length - 1 && (
              <View style={styles.summarySection}>
                <View style={styles.summaryRow}>
                  <Text style={styles.summaryLabel}>Total Charges:</Text>
                  <Text style={styles.summaryValue}>${totalCharges.toFixed(2)}</Text>
                </View>
                <View style={styles.summaryRow}>
                  <Text style={styles.summaryLabel}>Total Payments:</Text>
                  <Text style={styles.summaryValue}>${totalPayments.toFixed(2)}</Text>
                </View>
                <View style={styles.balanceRow}>
                  <Text style={styles.balanceLabel}>Balance Due:</Text>
                  <Text style={styles.balanceValue}>
                    ${(totalCharges - totalPayments).toFixed(2)}
                  </Text>
                </View>
              </View>
            )}
          </View>

          {/* Footer */}
          <View style={styles.footer} fixed>
            <Text>© 2023 Your Company Name. All rights reserved.</Text>
          </View>
        </Page>
      ))}
    </Document>
  );
};

export default function ClientStatementReport() {
  const dispatch = useDispatch();
  const [selectedClient, setSelectedClient] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [reportData, setReportData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleClientSelection = (client) => {
    setSelectedClient({
      _id: client._id,
      name: `${client.firstName} ${client.lastName}`,
      address: client.address,
      email: client.email,
      phoneNumbers: client.phoneNumbers
    });
  };

  const handleGenerateReport = async () => {
    if (!selectedClient) {
      setError('Please select a client');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const response = await dispatch(fetchClientStatement({
        clientId: selectedClient._id,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString()
      })).unwrap();
      
      if (!response || !response.data) {
        throw new Error('Invalid response data received');
      }

      // Update client data from response if available
      if (response.data.client) {
        setSelectedClient(prevClient => ({
          ...prevClient,
          name: `${response.data.client.firstName} ${response.data.client.lastName}`,
          address: response.data.client.address,
          email: response.data.client.email,
          phoneNumbers: response.data.client.phoneNumbers
        }));
      }
      
      setReportData(response.data);
    } catch (err) {
      setError('Failed to generate report: ' + (err.message || 'Unknown error'));
      setReportData(null);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="p-4">
      <div className="bg-white rounded-lg shadow p-6 mb-6">
        <h2 className="text-xl font-semibold mb-4">Client Statement Report</h2>
        
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Select Client
            </label>
            <SearchDropdown
                prefix=":client:"
                displayFields={["firstName", "lastName", "email"]}
                onSelection={handleClientSelection}
                placeholder="Search for a client"
              />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Start Date
              </label>
              <DatePicker
                selected={startDate}
                onChange={setStartDate}
                className="w-full p-2 border rounded"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                End Date
              </label>
              <DatePicker
                selected={endDate}
                onChange={setEndDate}
                className="w-full p-2 border rounded"
              />
            </div>
          </div>

          {error && (
            <div className="text-red-600 text-sm">{error}</div>
          )}

          <button
            onClick={handleGenerateReport}
            disabled={isLoading}
            className={`w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white 
              ${isLoading ? 'bg-gray-400' : 'bg-indigo-600 hover:bg-indigo-700'}`}
          >
            {isLoading ? 'Generating...' : 'Generate Report'}
          </button>
        </div>
      </div>

      {reportData && (
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex flex-col gap-4">
            <PDFDownloadLink
              document={
                <StatementPDF 
                  data={reportData}
                  dateRange={{
                    start: startDate.toLocaleDateString(),
                    end: endDate.toLocaleDateString()
                  }}
                  clientName={selectedClient.name}
                  clientAddress={selectedClient.address}
                />
              }
              fileName={`client-statement-${selectedClient.name}.pdf`}
              className="inline-block px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700"
            >
              {({ loading }) => 
                loading ? 'Preparing PDF...' : 'Download PDF'
              }
            </PDFDownloadLink>

            <div className="w-full h-[600px] border rounded">
              <PDFViewer width="100%" height="100%">
                <StatementPDF 
                  data={reportData}
                  dateRange={{
                    start: startDate.toLocaleDateString(),
                    end: endDate.toLocaleDateString()
                  }}
                  clientName={selectedClient.name}
                  clientAddress={selectedClient.address}
                />
              </PDFViewer>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
