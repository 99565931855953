import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchServices,
  toggleServiceActiveStatus,
  updateService,
  createService,
} from "../../store/thunks/servicesThunk";

export default function ServiceModal({ service, onClose, onCancel }) {
  const dispatch = useDispatch();
  const roles = useSelector((state) => state.roles.roles);
  const userRole = useSelector((state) => state.auth.user?.role);
  console.log("userRole", userRole);

  const canEditService = Array.isArray(userRole)
    ? userRole.some((role) =>
        ["owner", "admin", "hr", "officemanager"].includes(role)
      )
    : ["owner", "admin", "hr", "officemanager"].includes(userRole);

  console.log("canEditService", canEditService);

  const [serviceData, setServiceData] = useState({
    description: service?.description || "",
    serviceCode: service?.serviceCode || "",
    price: service?.price || 0,
    duration: service?.duration || 0,
    roles:
      service?.roles?.map((role) =>
        typeof role === "object" ? role._id : role
      ) || [],
    active: service?.active ?? true,
    tax: {
      isTaxable: service?.tax?.isTaxable ?? false,
      taxRate: service?.tax?.taxRate ?? 0.13,
    },
    color: service?.color || "#000FFF",
    serviceCategory: service?.serviceCategory || "",
    clinicianPrices: service?.clinicianPrices || [],
    waagCategory: service?.waagCategory || "",
  });

  const isEditMode = !!service;

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleEscape);
    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, [onClose, dispatch]);

  const handleSave = async (event) => {
    event.preventDefault();

    if (!canEditService) {
      alert("You don't have permission to edit services.");
      return;
    }

    if (
      !serviceData.description ||
      !serviceData.price ||
      serviceData.duration <= 0
    ) {
      alert("Service description, price, and duration are required.");
      return;
    }

    if (isEditMode) {
      dispatch(updateService({ id: service._id, serviceData }));
    } else {
      dispatch(createService(serviceData));
    }
    onClose();
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setServiceData((prevData) => {
      if (name === "price") {
        return { ...prevData, price: parseFloat(value) };
      } else if (name === "isTaxable" || name === "taxRate") {
        return {
          ...prevData,
          tax: {
            ...prevData.tax,
            [name]: type === "checkbox" ? checked : parseFloat(value),
          },
        };
      } else if (name === "roles") {
        return {
          ...prevData,
          [name]: Array.from(
            event.target.selectedOptions,
            (option) => option.value
          ),
        };
      } else {
        return { ...prevData, [name]: type === "checkbox" ? checked : value };
      }
    });
  };

  const handleToggleActive = async () => {
    if (!canEditService) {
      alert("You don't have permission to toggle service status.");
      return;
    }

    const action = serviceData.active ? "deactivate" : "activate";
    const confirmed = window.confirm(
      `Are you sure you want to ${action} this service?`
    );

    if (!confirmed) {
      return; // Exit if the user cancels the action
    }

    try {
      await dispatch(toggleServiceActiveStatus(service._id)).unwrap();
      alert(`Service has been successfully ${action}d.`);
      onClose(); // Close the modal after toggling
      dispatch(fetchServices({ page: 1, limit: 10 })); // Refresh services list
    } catch (error) {
      alert(`Failed to ${action} service. Please try again.`);
    }
  };

  if (!canEditService) {
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg p-8 max-w-md w-full mx-4">
          <h2 className="text-lg font-semibold mb-4">Permission Denied</h2>
          <p>You don't have permission to edit services.</p>
          <button
            onClick={onClose}
            className="mt-4 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500">
            Close
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 overflow-y-auto">
      <div
        className="fixed inset-0 bg-gray-900 opacity-50"
        onClick={onCancel}
      />
      <div className="bg-white rounded-lg p-8 max-w-4xl w-full mx-4 relative z-10 overflow-y-auto max-h-[90vh]">
        <form onSubmit={handleSave}>
          <div className="border-b border-gray-900/10 pb-6 mb-6">
            <h2 className="text-lg font-semibold leading-7 text-gray-900">
              {isEditMode ? "Edit Service" : "Add New Service"}
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              {isEditMode
                ? "Edit the service information."
                : "Enter the new service details."}
            </p>
          </div>

          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-4">
            <div className="sm:col-span-2">
              <label
                htmlFor="serviceDescription"
                className="block text-sm font-medium leading-6 text-gray-900">
                Description
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="description"
                  id="serviceDescription"
                  value={serviceData.description}
                  onChange={handleChange}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  required
                />
              </div>
            </div>

            <div className="sm:col-span-1">
              <label
                htmlFor="serviceCode"
                className="block text-sm font-medium leading-6 text-gray-900">
                Service Code
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="serviceCode"
                  id="serviceCode"
                  value={serviceData.serviceCode}
                  onChange={handleChange}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-1">
              <label
                htmlFor="waagCategory"
                className="block text-sm font-medium leading-6 text-gray-900">
                WAAG Category
              </label>
              <div className="mt-2">
                <select
                  name="waagCategory"
                  id="waagCategory"
                  value={serviceData.waagCategory || ""}
                  onChange={handleChange}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                  <option value="">Select WAAG Category</option>
                  <option value="intake">Intake</option>
                  <option value="therapy">Therapy</option>
                  <option value="assessment">Assessment</option>
                  <option value="consult">Consult</option>
                </select>
              </div>
            </div>

            <div className="sm:col-span-1">
              <label
                htmlFor="servicePrice"
                className="block text-sm font-medium leading-6 text-gray-900">
                Price
              </label>
              <div className="mt-2">
                <input
                  type="number"
                  name="price"
                  id="servicePrice"
                  value={serviceData.price}
                  onChange={handleChange}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  required
                />
              </div>
            </div>

            <div className="sm:col-span-1">
              <label
                htmlFor="serviceDuration"
                className="block text-sm font-medium leading-6 text-gray-900">
                Duration (minutes)
              </label>
              <div className="mt-2">
                <input
                  type="number"
                  name="duration"
                  id="serviceDuration"
                  value={serviceData.duration}
                  onChange={handleChange}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  required
                />
              </div>
            </div>

            <div className="sm:col-span-1">
              <label
                htmlFor="serviceColor"
                className="block text-sm font-medium leading-6 text-gray-900">
                Service Color
              </label>
              <div className="mt-2">
                <input
                  type="color"
                  name="color"
                  id="serviceColor"
                  value={serviceData.color}
                  onChange={handleChange}
                  className="block w-full h-10 rounded-md border-0"
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="serviceRoles"
                className="block text-sm font-medium leading-6 text-gray-900">
                Roles
              </label>
              <div className="mt-2">
                <select
                  multiple
                  name="roles"
                  id="serviceRoles"
                  value={serviceData.roles}
                  onChange={handleChange}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                  {roles?.map((role) => (
                    <option key={role._id} value={role._id}>
                      {role.name} - {role.description}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* <div className="sm:col-span-1 flex items-center">
              <input
                id="active"
                name="active"
                type="checkbox"
                checked={serviceData.active}
                onChange={handleChange}
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <label
                htmlFor="active"
                className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                Active
              </label>
            </div> */}

            <div className="sm:col-span-1 flex items-center">
              <input
                id="isTaxable"
                name="isTaxable"
                type="checkbox"
                checked={serviceData.tax.isTaxable}
                onChange={handleChange}
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <label
                htmlFor="isTaxable"
                className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                Is Taxable
              </label>
            </div>

            {serviceData.tax.isTaxable && (
              <div className="sm:col-span-1">
                <label
                  htmlFor="taxRate"
                  className="block text-sm font-medium leading-6 text-gray-900">
                  Tax Rate
                </label>
                <div className="mt-2">
                  <input
                    type="number"
                    name="taxRate"
                    id="taxRate"
                    value={serviceData.tax.taxRate}
                    onChange={handleChange}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            )}

            {/* Add more fields for serviceCategory and clinicianPrices as needed */}
          </div>

          <div className="mt-6 flex items-center justify-between gap-x-6">
            <button
              type="button"
              onClick={handleToggleActive}
              className={`rounded-md px-3 py-2 text-sm font-semibold shadow-sm ${
                serviceData.active
                  ? "bg-red-600 text-white hover:bg-red-500"
                  : "bg-green-600 text-white hover:bg-green-500"
              }`}>
              {serviceData.active ? "Deactivate Service" : "Activate Service"}
            </button>

            <div className="flex gap-x-6">
              <button
                type="button"
                onClick={onClose}
                className="text-sm font-semibold leading-6 text-gray-900">
                Cancel
              </button>
              <button
                type="submit"
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                {isEditMode ? "Save Changes" : "Add Service"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
