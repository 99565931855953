import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchDropdown from "./SearchDropdown";
import { fetchParents } from "../../store/thunks/clientsThunk";
import { fetchAppointmentById, fetchClinicianById } from "../../store/store";
import SetCliniciansModal from "../../modals/setCliniciansModal";
import QuickAddClientModal from "../../modals/QuickAddClientModal";

const CreateInvoice = forwardRef(
  (
    {
      onSubmit,
      isParent = false,
      isSplit = false,
      onClose,
      isEditInvoice = false,
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const appointment = useSelector(
      (state) => state.appointments.selectedAppointment
    );
    const editInvoice = useSelector((state) => state.invoices.currentInvoice);

    const [services, setServices] = useState([
      {
        _id: appointment.service._id,
        description: appointment.service.description,
        quantity: 1,
        notes: "",
        amount: appointment.service.price,
      },
    ]);
    const [notes, setNotes] = useState("");
    const [showSetCliniciansModal, setShowSetCliniciansModal] = useState(false);
    const [isClinicianFetched, setIsClinicianFetched] = useState(false);
    const [editingClinician, setEditingClinician] = useState(false);

    const [selectedClient, setSelectedClient] = useState(
      isParent ? null : appointment.client
    );
    const [editingClient, setEditingClient] = useState(false);

    const [selectedInsurer, setSelectedInsurer] = useState(null);
    const [billToType, setBillToType] = useState("client");
    const [billToId, setBillToId] = useState(appointment.client._id);

    const [parentNotFound, setParentNotFound] = useState(false);
    const [editingBillTo, setEditingBillTo] = useState(false);
    const [isQuickAddClientModalOpen, setIsQuickAddClientModalOpen] =
      useState(false);

    const parents = useSelector((state) => state.clients.parents);
    const statementClinician = appointment.clinician;
    const [clinicianData, setClinicianData] = useState(statementClinician);

    // If editing an invoice, set initial state from editInvoice
    useEffect(() => {
      if (isEditInvoice && editInvoice) {
        // Set services, notes, and billTo from existing invoice
        console.log("EditInvoice", editInvoice.services);
        if (editInvoice.services && editInvoice.services.length > 0) {
          setServices(
            editInvoice.services.map((srv) => ({
              _id: srv.serviceId,
              description: srv.description,
              quantity: srv.quantity,
              notes: srv.notes,
              amount: srv.cost,
            }))
          );
        }

        setNotes(editInvoice.notes || "");
        setBillToType(editInvoice.billToType || "client");
        setBillToId(editInvoice.billToId);

        if (editInvoice.billToType === "client") {
          setSelectedClient(editInvoice.client);
          setSelectedInsurer(null);
        } else if (editInvoice.billToType === "insurer") {
          setSelectedInsurer(editInvoice.insurer);
          setSelectedClient(null);
        }

        // Set clinician data if present on the invoice
        if (editInvoice.clinician) {
          setClinicianData(editInvoice.clinician);
          setIsClinicianFetched(true); // Since we already have the clinician, no need to fetch again
        }

        // If the invoice is tied to a different appointment, consider fetching it here if needed.
        // if (editInvoice.appointmentId && editInvoice.appointmentId !== appointment._id) {
        //   dispatch(fetchAppointmentById(editInvoice.appointmentId));
        // }
      }
    }, [isEditInvoice, editInvoice, dispatch, appointment]);

    console.log("Services", services);

    useEffect(() => {
      if (appointment?.client?._id) {
        dispatch(fetchParents(appointment.client._id));
      }
    }, [dispatch, appointment]);

    useEffect(() => {
      // Only fetch clinician if not in edit mode or we don't have clinician data yet
      const loadClinicianData = async () => {
        if (!isClinicianFetched && !isEditInvoice) {
          if (appointment?.clinician?.specialization !== "SLP") {
            if (appointment.client.supervisingClinician?.current) {
              const fetchedClinician = await dispatch(
                fetchClinicianById(
                  appointment.client.supervisingClinician.current
                )
              ).unwrap();
              setClinicianData(fetchedClinician);
            } else {
              setShowSetCliniciansModal(true);
            }
          } else {
            setClinicianData(appointment.clinician);
          }
          setIsClinicianFetched(true);
        }
      };

      loadClinicianData();
    }, [appointment, dispatch, isClinicianFetched, isEditInvoice]);

    const handleChargeChange = (index, field, value) => {
      const updatedServices = [...services];
      updatedServices[index][field] =
        field === "amount" ? parseFloat(value) || 0 : value;
      setServices(updatedServices);
    };

    const handleServiceSelection = (service, index) => {
      console.log("Service", service);
      const updatedServices = [...services];
      updatedServices[index] = {
        ...updatedServices[index],
        _id: service._id,
        description: service.description,
        amount: service.price,
      };
      setServices(updatedServices);
    };

    const handleClinicianSelection = (clinician) => {
      setClinicianData(clinician);
      setEditingClinician(false);
    };

    const handleInsurerSelection = (insurer) => {
      const sanitizedInsurer = {
        _id: insurer._id,
        name: insurer.name,
        companyName: insurer.companyName,
        address: insurer.address,
      };
      setSelectedInsurer(sanitizedInsurer);
      setBillToType("insurer");
      setBillToId(insurer._id);
      setEditingBillTo(false);
    };

    const handleBillToTypeChange = (type) => {
      setBillToType(type);
      if (type === "client" && selectedClient) {
        setSelectedInsurer(null);
        setBillToId(selectedClient._id);
      }
      setEditingBillTo(type === "insurer");
    };

    const addCharge = () => {
      setServices((prevServices) => [
        ...prevServices,
        { _id: "", description: "", quantity: 1, notes: "", amount: 0 },
      ]);
    };

    const removeCharge = (index) => {
      const updatedServices = services.filter((_, i) => i !== index);
      setServices(updatedServices);
    };

    const calculateSubtotal = () =>
      services.reduce(
        (total, charge) => total + charge.quantity * charge.amount,
        0
      );

    const calculateTax = () => {
      const taxRate =
        appointment.service.tax && appointment.service.tax.isTaxable
          ? appointment.service.tax.taxRate
          : 0;
      return calculateSubtotal() * taxRate;
    };

    const calculateTotal = () => calculateSubtotal() + calculateTax();

    const handleNotesChange = (e) => setNotes(e.target.value);

    const handleClientSelection = (client) => {
      setSelectedClient(client);
      if (client) {
        setParentNotFound(false);
        if (billToType === "client") {
          setBillToId(client._id);
        }
      }
      if (billToType === "client") {
        setSelectedInsurer(null);
      }
      setEditingClient(false);
    };

    const renderClinician = () => {
      if (editingClinician) {
        return (
          <div className="max-w-md mt-2">
            <SearchDropdown
              prefix=":clinician:"
              displayFields={["firstName", "lastName", "qualifications"]}
              onSelection={handleClinicianSelection}
              placeholder="Search for a clinician"
            />
          </div>
        );
      }

      if (!clinicianData)
        return <p className="text-sm text-gray-500">No clinician assigned</p>;

      return (
        <div className="mt-2 text-sm">
          <p className="font-medium">
            {clinicianData.firstName} {clinicianData.lastName}
          </p>
          <p className="text-gray-600">
            {clinicianData.qualifications || clinicianData.jobTitle}
          </p>
        </div>
      );
    };

    const renderClientSection = () => {
      if (isParent) {
        return (
          <div>
            <h2 className="text-base font-semibold border-b pb-2 mb-4">
              Client
            </h2>
            {parentNotFound || parents.length === 0 ? (
              <div className="space-y-2">
                <SearchDropdown
                  prefix=":client:"
                  displayFields={["firstName", "lastName", "email"]}
                  onSelection={handleClientSelection}
                  placeholder="Search for a client to find their parents"
                />
                <button
                  type="button"
                  onClick={() => setIsQuickAddClientModalOpen(true)}
                  className="mt-2 text-sm font-semibold text-indigo-600 hover:text-indigo-500">
                  Can't find the parent? Add a new one
                </button>
                <div className="flex items-center mt-2">
                  <input
                    id="searchAllClients"
                    type="checkbox"
                    checked={parentNotFound}
                    onChange={() => setParentNotFound(!parentNotFound)}
                    className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                  />
                  <label
                    htmlFor="searchAllClients"
                    className="ml-2 text-sm text-gray-700">
                    Search all clients
                  </label>
                </div>
              </div>
            ) : (
              <select
                onChange={(e) =>
                  handleClientSelection(
                    parents.find((parent) => parent._id === e.target.value)
                  )
                }
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                <option value="">Select a parent</option>
                {parents.map((parent) => (
                  <option key={parent._id} value={parent._id}>
                    {parent.firstName} {parent.lastName}
                  </option>
                ))}
              </select>
            )}
          </div>
        );
      } else {
        if (editingClient) {
          return (
            <div>
              <h2 className="text-base font-semibold border-b pb-2 mb-4">
                Client
              </h2>
              <SearchDropdown
                prefix=":client:"
                displayFields={["firstName", "lastName", "email"]}
                onSelection={handleClientSelection}
                placeholder="Search for a client"
              />
            </div>
          );
        }

        return (
          <div>
            <div className="flex justify-between items-center">
              <h2 className="text-base font-semibold border-b pb-2 mb-4">
                Client
              </h2>
              <button
                onClick={() => setEditingClient(true)}
                className="text-sm font-semibold text-indigo-600 hover:text-indigo-500">
                Edit
              </button>
            </div>
            {selectedClient && (
              <div className="text-sm space-y-1">
                <p className="font-medium">
                  {selectedClient.firstName} {selectedClient.lastName}
                </p>
                <p>
                  {selectedClient.address?.streetNumber}{" "}
                  {selectedClient.address?.streetName}
                </p>
                <p>
                  {selectedClient.address?.city},{" "}
                  {selectedClient.address?.province},{" "}
                  {selectedClient.address?.postalCode}
                </p>
              </div>
            )}
          </div>
        );
      }
    };

    const renderBillToSection = () => {
      return (
        <div>
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-base font-semibold border-b pb-2 flex-1">
              Bill To
            </h2>
            <div className="flex space-x-2">
              <button
                onClick={() => handleBillToTypeChange("client")}
                className={`px-4 py-1 rounded text-sm font-semibold 
                  ${
                    billToType === "client"
                      ? "bg-indigo-600 text-white"
                      : "bg-gray-200 text-gray-700"
                  }`}>
                Client
              </button>
              <button
                onClick={() => handleBillToTypeChange("insurer")}
                className={`px-4 py-1 rounded text-sm font-semibold
                  ${
                    billToType === "insurer"
                      ? "bg-indigo-600 text-white"
                      : "bg-gray-200 text-gray-700"
                  }`}>
                Insurer
              </button>
            </div>
          </div>

          {billToType === "insurer" && editingBillTo && (
            <SearchDropdown
              prefix=":insurer:"
              displayFields={["name", "address"]}
              onSelection={handleInsurerSelection}
              placeholder="Search for an insurer"
            />
          )}

          {billToType === "insurer" && !editingBillTo && selectedInsurer && (
            <div className="space-y-1 text-sm">
              <p className="font-medium">
                {selectedInsurer.companyName || selectedInsurer.name}
              </p>
              <p>{selectedInsurer.address.street}</p>
              <p>
                {selectedInsurer.address.city},{" "}
                {selectedInsurer.address.province},{" "}
                {selectedInsurer.address.postalCode}
              </p>
              <button
                onClick={() => setEditingBillTo(true)}
                className="mt-2 text-sm font-semibold text-indigo-600 hover:text-indigo-500">
                Edit Insurer
              </button>
            </div>
          )}

          {billToType === "client" && selectedClient && (
            <div className="space-y-1 text-sm">
              <p className="font-medium">
                {selectedClient.firstName} {selectedClient.lastName}
              </p>
              <p>
                {selectedClient.address?.streetNumber}{" "}
                {selectedClient.address?.streetName}
              </p>
              <p>
                {selectedClient.address?.city},{" "}
                {selectedClient.address?.province},{" "}
                {selectedClient.address?.postalCode}
              </p>
            </div>
          )}

          {billToType === "insurer" && !selectedInsurer && !editingBillTo && (
            <div className="text-sm text-gray-500">
              No insurer selected. Click "Insurer" above to select one.
            </div>
          )}
        </div>
      );
    };

    useImperativeHandle(ref, () => ({
      getInvoiceData: () => {
        console.log("Getting invoice data...");

        // Log services array
        console.log("Services:", services);

        const validServices = services.filter((service) => service._id);
        if (validServices.length !== services.length) {
          console.error(
            "Some services are missing valid _id fields. Services:",
            services
          );
          return null;
        }

        if (!appointment || !appointment._id) {
          console.error("Appointment data is missing:", appointment);
          return null;
        }

        if (!billToId) {
          console.error("Bill-to ID is missing. Bill-to Type:", billToType);
          return null;
        }

        // Log the final data to be returned
        const invoiceData = {
          appointmentId: appointment._id,
          client: selectedClient,
          services: validServices,
          clinic: appointment.clinic,
          clinician: clinicianData,
          serviceDate: appointment.start,
          notes,
          amount: calculateTotal(),
          status: "created",
          billToId: billToId,
          billToType: billToType,
        };
        console.log("Invoice Data:", invoiceData);
        return invoiceData;
      },
    }));

    return (
      <div className="space-y-8 border-t border-b border-gray-200 pb-12">
        {/* Header: Clinician and Clinic Info */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 py-6">
          <div>
            <div className="flex justify-between items-center">
              <h2 className="text-base font-semibold text-gray-900">
                Clinician
              </h2>
              <button
                onClick={() => setEditingClinician(true)}
                className="text-sm font-semibold text-indigo-600 hover:text-indigo-500">
                Edit
              </button>
            </div>
            {renderClinician()}
          </div>
          <div className="text-right space-y-1 mt-4 md:mt-0">
            <h2 className="text-base font-semibold text-gray-900">Clinic</h2>
            <p className="text-sm font-medium">{appointment.clinic.name}</p>
            <p className="text-sm text-gray-600">
              {appointment.clinic.address.street},{" "}
              {appointment.clinic.address.city},{" "}
              {appointment.clinic.address.province},{" "}
              {appointment.clinic.address.postalCode}
            </p>
            <p className="text-sm text-gray-600">
              Service Date:{" "}
              {new Date(appointment.start).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                // weekday: "short",
                day: "numeric",
              })}
            </p>
          </div>
        </div>

        {/* Client and BillTo Sections */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 py-6">
          <div className="border p-4 rounded-md shadow-sm">
            {renderClientSection()}
          </div>
          <div className="border p-4 rounded-md shadow-sm">
            {renderBillToSection()}
          </div>
        </div>

        {/* Services Table */}
        <div className="py-6">
          <h2 className="text-base font-semibold text-gray-900 mb-4">
            Services
          </h2>
          <table className="min-w-full divide-y divide-gray-300 text-sm">
            <thead className="bg-gray-50">
              <tr>
                <th className="py-3.5 px-3 text-left font-semibold text-gray-900 w-1/3">
                  Description
                </th>
                <th className="py-3.5 px-3 text-left font-semibold text-gray-900 w-1/12">
                  Qty
                </th>
                <th className="py-3.5 px-3 text-left font-semibold text-gray-900 w-1/3">
                  Notes
                </th>
                <th className="py-3.5 px-3 text-right font-semibold text-gray-900 w-1/6">
                  Amount
                </th>
                <th className="py-3.5 px-3 text-right font-semibold text-gray-900 w-1/12">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {services.map((service, index) => (
                <tr key={index}>
                  <td className="py-3 px-3">
                    <SearchDropdown
                      prefix=":service:"
                      displayFields={["serviceCode", "description", "price"]}
                      onSelection={(selectedService) =>
                        handleServiceSelection(selectedService, index)
                      }
                      placeholder={
                        service.description || "Search by name or code"
                      }
                      activeServices={true}
                    />
                  </td>
                  <td className="py-3 px-3">
                    <input
                      type="number"
                      value={service.quantity}
                      onChange={(e) =>
                        handleChargeChange(index, "quantity", e.target.value)
                      }
                      className="w-full border rounded-md px-2 py-1 text-gray-700"
                    />
                  </td>
                  <td className="py-3 px-3">
                    <input
                      type="text"
                      value={service.notes}
                      onChange={(e) =>
                        handleChargeChange(index, "notes", e.target.value)
                      }
                      className="w-full border rounded-md px-2 py-1 text-gray-700"
                    />
                  </td>
                  <td className="py-3 px-3 text-right">
                    <input
                      type="number"
                      value={service.amount}
                      onChange={(e) =>
                        handleChargeChange(index, "amount", e.target.value)
                      }
                      className="w-full border rounded-md px-2 py-1 text-right text-gray-700"
                    />
                  </td>
                  <td className="py-3 px-3 text-right">
                    <button
                      onClick={() => removeCharge(index)}
                      className="text-red-600 font-semibold hover:text-red-800">
                      Remove
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button
            onClick={addCharge}
            className="mt-4 bg-indigo-600 text-white px-4 py-2 rounded shadow hover:bg-indigo-700 transition">
            Add Charge
          </button>
        </div>

        {/* Notes Section */}
        <div className="py-6">
          <h2 className="text-base font-semibold text-gray-900 mb-2">Notes</h2>
          <textarea
            placeholder="Type any invoice-related notes here..."
            rows={3}
            value={notes}
            onChange={handleNotesChange}
            className="w-full border rounded-md px-3 py-2 text-sm text-gray-700 focus:outline-none focus:ring-1 focus:ring-indigo-500"
          />
        </div>

        {showSetCliniciansModal && (
          <SetCliniciansModal
            message="Client has no supervising clinician assigned. Please assign one before creating an invoice."
            onSubmit={() => setShowSetCliniciansModal(false)}
            onClose={() => {
              dispatch(fetchAppointmentById(appointment._id));
              setShowSetCliniciansModal(false);
            }}
            onCancelAll={onClose}
            client={selectedClient}
          />
        )}

        {isQuickAddClientModalOpen && (
          <QuickAddClientModal
            addParent={true}
            onClose={() => setIsQuickAddClientModalOpen(false)}
          />
        )}
      </div>
    );
  }
);

export default CreateInvoice;
