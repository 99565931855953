import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import Table from "../Table";
import QuickBooksLogo from "../../../assets/quickbooks-1.svg";
import InvoiceModal from "../InvoiceModal";
import { fetchAppointmentById } from "../../../store/thunks/appointmentsThunk";
import InvoicePaymentModal from "../../../modals/InvoicePaymentModal";
import ViewInvoicePDFModal from "../../../modals/ViewInvoicePDFModal";
import { setCurrentInvoice } from "../../../store/slices/invoiceSlice";
import { setCurrentPayment } from "../../../store/slices/paymentsSlice";

export const AppointmentInvoices = () => {
  const dispatch = useDispatch();
  const [showViewInvoiceModal, setShowViewInvoiceModal] = useState(false);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [convertInvoice, setConvertInvoice] = useState(null);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: null,
  });

  const appointmentInvoices = useSelector(
    (state) => state.invoices.appointmentInvoices || []
  );
  const appointmentPayments = useSelector(
    (state) => state.payments.appointmentPayments || []
  );
  const invoices = useSelector(
    (state) => state.invoices.appointmentInvoices || []
  );
  const roles = useSelector((state) => state.auth.user?.role || []);
  const payments = useSelector(
    (state) => state.payments.appointmentPayments || []
  );
  const selectedAppointment = useSelector(
    (state) => state.appointments.selectedAppointment || null
  );

  console.log("selectedappt", selectedAppointment);
  const isLoading = useSelector(
    (state) => state.invoices.loading || state.payments.loading || false
  );

  const handleShowViewInvoiceModal = (invoiceId) => {
    const invoice =
      appointmentInvoices?.find((invoice) => invoice?._id === invoiceId) ||
      null;
    const payment =
      appointmentPayments?.find(
        (payment) => payment?.invoiceId?._id === invoiceId
      ) || null;

    if (payment) {
      dispatch(setCurrentPayment(payment));
    }

    if (invoice) {
      dispatch(setCurrentInvoice(invoice));
    }

    if (invoice || payment) {
      setShowViewInvoiceModal(true);
    } else {
      console.error("Invoice or payment not found for invoiceId:", invoiceId);
    }
  };

  const handleShowInvoiceModal = () => {
    setShowInvoiceModal(true);
    setConvertInvoice(null);
  };

  const handleCollectPayment = () => {
    setIsPaymentModalOpen(true);
  };

  const handleSplitInvoice = (invoice) => {
    setConvertInvoice(invoice);
    setShowInvoiceModal(true);
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortedInvoices = [...invoices].sort((a, b) => {
    if (!sortConfig.key) return 0;

    let aValue = a[sortConfig.key];
    let bValue = b[sortConfig.key];

    // Handle nested object access for client and clinician names
    if (sortConfig.key === "client") {
      aValue = a.client ? `${a.client.firstName} ${a.client.lastName}` : "";
      bValue = b.client ? `${b.client.firstName} ${b.client.lastName}` : "";
    }
    if (sortConfig.key === "clinician") {
      aValue = a.clinician
        ? `${a.clinician.firstName} ${a.clinician.lastName}`
        : "";
      bValue = b.clinician
        ? `${b.clinician.firstName} ${b.clinician.lastName}`
        : "";
    }

    // Handle date fields
    if (["serviceDate", "createdAt"].includes(sortConfig.key)) {
      aValue = new Date(aValue);
      bValue = new Date(bValue);
    }

    if (aValue < bValue) return sortConfig.direction === "ascending" ? -1 : 1;
    if (aValue > bValue) return sortConfig.direction === "ascending" ? 1 : -1;
    return 0;
  });

  return (
    <>
      <div className="border border-gray-200 rounded-md px-4 py-4">
        <div className="mt-2">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Invoices
          </h3>
          <div className="border-t border-gray-100 px-2 py-2 sm:px-0">
            {isLoading ? (
              <div className="flex justify-center items-center py-4">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
              </div>
            ) : (
              roles.some((role) =>
                ["admin", "owner", "officeManager", "hr"].includes(role)
              ) &&
              (invoices.length === 0 ? (
                <button
                  type="button"
                  className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={() => {
                    const currentDate = new Date();
                    const appointmentStart = new Date(
                      selectedAppointment.start
                    );

                    if (currentDate < appointmentStart) {
                      const formattedStartDate =
                        appointmentStart.toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "short",
                          weekday: "short",
                          day: "numeric",
                        }) +
                        `, ${appointmentStart.toLocaleTimeString("en-US", {
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                          hour12: true,
                        })}`;

                      const proceed = window.confirm(
                        `Warning: Appointment start date of ${formattedStartDate} has not been reached yet. Are you sure you want to continue?`
                      );

                      if (!proceed) {
                        return;
                      }
                    }

                    handleShowInvoiceModal();
                  }}>
                  Create Invoice
                </button>
              ) : (
                <Table
                  columns={[
                    {
                      header: "Invoice ID",
                      accessor: "invoiceId",
                      sortable: true,
                      onSort: () => handleSort("invoiceId"),
                    },
                    {
                      header: "Service Date",
                      accessor: (row) => {
                        const date = new Date(row.serviceDate);
                        return date.toLocaleDateString("en-US", {
                          weekday: "short",
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        });
                      },
                      sortable: true,
                      onSort: () => handleSort("serviceDate"),
                    },
                    {
                      header: "Invoice Date",
                      accessor: (row) => {
                        const date = new Date(row.createdAt);
                        return date.toLocaleDateString("en-US", {
                          weekday: "short",
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        });
                      },
                      sortable: true,
                      onSort: () => handleSort("createdAt"),
                    },
                    {
                      header: "Client Name",
                      accessor: (row) =>
                        row.client
                          ? `${row.client.firstName || "Unknown"} ${
                              row.client.lastName || ""
                            }`
                          : "Unknown",
                      sortable: true,
                      onSort: () => handleSort("client"),
                    },
                    {
                      header: "Clinician",
                      accessor: (row) =>
                        row.clinician
                          ? `${row.clinician.firstName || "Unknown"} ${
                              row.clinician.lastName || ""
                            }`
                          : "Unknown",
                      sortable: true,
                      onSort: () => handleSort("clinician"),
                    },
                    {
                      header: "Amount",
                      accessor: (row) => `$${row.amount?.toFixed(2) || "0.00"}`,
                      sortable: true,
                      onSort: () => handleSort("amount"),
                    },
                    {
                      header: "Split Invoice?",
                      accessor: (row) =>
                        row.splitBilling && row.splitBilling.isSplit
                          ? "Yes"
                          : "No",
                      sortable: true,
                      onSort: () => handleSort("splitBilling"),
                    },
                    {
                      header: "Status",
                      accessor: "status",
                      sortable: true,
                      onSort: () => handleSort("status"),
                    },
                    {
                      header: "QuickBooks",
                      accessor: (row) => (
                        <div className="flex items-center">
                          {row.QBOInvoiceId && (
                            <img
                              src={QuickBooksLogo}
                              alt="QuickBooks"
                              className="ml-2 h-5 w-5"
                              title="Loaded to QuickBooks"
                            />
                          )}
                        </div>
                      ),
                      sortable: true,
                      onSort: () => handleSort("QBOInvoiceId"),
                    },
                  ]}
                  data={sortedInvoices}
                  onRowClick={(invoice) => handleShowViewInvoiceModal(invoice)}
                  sortConfig={sortConfig}
                />
              ))
            )}
            {!isLoading &&
              roles.some((role) =>
                ["admin", "owner", "officeManager"].includes(role)
              ) && (
                <>
                  <div className="flex space-x-4 mt-4">
                    {invoices.some((invoice) => {
                      const hasValidPayment = payments?.some(
                        (payment) =>
                          payment?.invoiceId?._id === invoice?._id &&
                          !payment?.voided
                      );
                      return !hasValidPayment;
                    }) && (
                      <button
                        type="button"
                        className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={handleCollectPayment}>
                        Collect Payment
                      </button>
                    )}

                    {invoices.some(
                      (invoice) => !invoice.splitBilling?.isSplit
                    ) && (
                      <button
                        type="button"
                        className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={() =>
                          handleSplitInvoice(
                            invoices.find(
                              (invoice) => !invoice.splitBilling?.isSplit
                            )
                          )
                        }>
                        Split Invoice
                      </button>
                    )}
                  </div>
                </>
              )}
          </div>
          {showInvoiceModal && (
            <InvoiceModal
              appointment={selectedAppointment}
              convertInvoice={convertInvoice}
              onClose={() => setShowInvoiceModal(false)}
            />
          )}
          {isPaymentModalOpen && (
            <InvoicePaymentModal
              onClose={async () => {
                setIsPaymentModalOpen(false);
              }}
            />
          )}
          {showViewInvoiceModal && (
            <ViewInvoicePDFModal
              onClose={() => setShowViewInvoiceModal(false)}
            />
          )}
        </div>
      </div>
    </>
  );
};
