import { createSlice } from "@reduxjs/toolkit";
import { fetchInsurers, createInsurer, fetchInsurer, updateInsurer } from "../thunks/insurerThunk";

const initialState = {
  insurers: [],
  loading: false,
  error: null,
  createdInsurer: null,
  selectedInsurer: null,
  updatedInsurer: null
};

const insurerSlice = createSlice({
  name: "insurer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch all insurers
      .addCase(fetchInsurers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchInsurers.fulfilled, (state, action) => {
        state.loading = false;
        state.insurers = action.payload;
      })
      .addCase(fetchInsurers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Create insurer
      .addCase(createInsurer.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.createdInsurer = null;
      })
      .addCase(createInsurer.fulfilled, (state, action) => {
        state.loading = false;
        state.createdInsurer = action.payload;
      })
      .addCase(createInsurer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Fetch single insurer
      .addCase(fetchInsurer.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchInsurer.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedInsurer = action.payload;
      })
      .addCase(fetchInsurer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Update insurer
      .addCase(updateInsurer.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateInsurer.fulfilled, (state, action) => {
        state.loading = false;
        state.updatedInsurer = action.payload;
        state.insurers = state.insurers.map(insurer => 
          insurer._id === action.payload._id ? action.payload : insurer
        );
      })
      .addCase(updateInsurer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default insurerSlice.reducer;
