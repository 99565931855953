import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { searchApi } from "../../store/thunks/searchApiThunk";
import { setQuery } from "../../store/slices/searchApiSlice";

function Search({ className, placeholder = "Search...", onResultClick }) {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const [showResults, setShowResults] = useState(false);
  const { results, loading, error } = useSelector((state) => state.search);

  useEffect(() => {
    if (searchQuery) {
      setShowResults(true);
    } else {
      setShowResults(false);
    }
  }, [searchQuery, results]);

  const handleSearchChange = (event) => {
    const newQuery = event.target.value;
    setSearchQuery(newQuery);
    dispatch(setQuery(newQuery));
    dispatch(searchApi(newQuery));
  };

  const handleResultClick = (result) => {
    console.log("Clicked result:", result);
    setShowResults(false);
    onResultClick(result); // Pass the clicked result back to the parent component
  };

  useEffect(() => {
    if (results.length > 0) {
      // console.log("Search results:", results);
    }
  }, [results]);

  const getTypeColor = (type) => {
    switch (type) {
      case "Client":
        return "bg-blue-100 text-blue-800";
      case "Appointment":
        return "bg-green-100 text-green-800";
      case "Product":
        return "bg-yellow-100 text-yellow-800";
      case "Service":
        return "bg-yellow-100 text-orange-800";
      case "Invoice":
        return "bg-red-100 text-red-800";
      case "Clinic":
        return "bg-purple-100 text-purple-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  const handleBlur = () => {
    setTimeout(() => {
      setSearchQuery("");
      setShowResults(false);
      dispatch(setQuery(""));
    }, 200); // Delay to allow click event to fire
  };

  return (
    <div className={className}>
      <input
        type="text"
        name="search"
        id="search"
        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
        placeholder={placeholder}
        value={searchQuery}
        onChange={handleSearchChange}
        onFocus={() => setShowResults(true)}
        onBlur={handleBlur}
        autoComplete="off"
      />

      {showResults && results.length > 0 && (
        <ul className="absolute z-50 mt-2 w-full bg-white rounded-md shadow-lg max-h-60 overflow-y-auto">
          {results.slice(0, 10).map((result, index) => (
            <li
              key={index}
              className="px-4 py-2 cursor-pointer hover:bg-gray-100 relative"
              onClick={() => handleResultClick(result)}>
              <span
                className={`absolute top-2 right-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${getTypeColor(
                  result.type
                )}`}>
                {result.type}
              </span>

              <span
                className={`inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium ${getTypeColor(
                  result.type
                )} mr-2`}>
                {result.type}
              </span>
              <span className="ml-4">
                {result.type === "Client" && (
                  <>
                    <span
                      className={`${getTypeColor(
                        "Client"
                      )} rounded-full px-3 py-1 mr-2`}>
                      {`${result.firstName || "N/A"} ${
                        result.lastName || "N/A"
                      }`}
                    </span>
                    <span
                      className={`bg-gray-100 text-gray-800 rounded-full px-3 py-1 mr-2`}>
                      {result.phoneNumbers?.[0]?.number || "N/A"}
                    </span>
                    <span
                      className={`bg-gray-100 text-gray-800 rounded-full px-3 py-1`}>
                      {result.email || "N/A"}
                    </span>
                  </>
                )}
                {result.type === "Appointment" && (
                  <>
                    <span
                      className={`${getTypeColor(
                        "Appointment"
                      )} rounded-full px-3 py-1 mr-2`}>
                      {result.title || "N/A"}
                    </span>
                    <span
                      className={`${getTypeColor(
                        "Client"
                      )} rounded-full px-3 py-1`}>
                      {`${result.client?.firstName || "N/A"} ${
                        result.client?.lastName || "N/A"
                      }`}
                    </span>
                    {" - "}
                    <span
                      className={`${getTypeColor(
                        "User"
                      )} rounded-full px-3 py-1`}>
                      {`${result.clinician?.firstName || "N/A"} ${
                        result.clinician?.lastName || "N/A"
                      }`}
                    </span>
                  </>
                )}
                {result.type === "Product" &&
                  `${result.catalogCode || "N/A"} - ${result.item || "N/A"}`}
                {result.type === "Service" &&
                  `${result.serviceCode || "N/A"} - ${
                    result.description || "N/A"
                  }`}
                {result.type === "Invoice" && (
                  <>
                    <span
                      className={`${getTypeColor(
                        "Invoice"
                      )} rounded-full px-3 py-1 mr-2`}>
                      {result.invoiceId || "N/A"}
                    </span>
                    <span
                      className={`${getTypeColor(
                        "Client"
                      )} rounded-full px-3 py-1 mr-2`}>
                      {`${result.client?.firstName || "N/A"} ${
                        result.client?.lastName || "N/A"
                      }`}
                    </span>
                    <span
                      className={`${getTypeColor(
                        "User"
                      )} rounded-full px-3 py-1`}>
                      {`${result.clinician?.firstName || "N/A"} ${
                        result.clinician?.lastName || "N/A"
                      }`}
                    </span>
                  </>
                )}
                {result.type === "Clinic" && (result.name || "N/A")}
                {result.type === "User" &&
                  `${result.firstName || "N/A"} ${result.lastName || "N/A"}`}
              </span>
            </li>
          ))}
        </ul>
      )}

      {error && <div>Error: {error.message}</div>}
    </div>
  );
}

export default Search;
