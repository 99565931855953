import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  fetchInvoicesByClientId,
  fetchInvoicesByAppointmentId,
} from "../../store/thunks/invoiceThunk";
import {
  clearCurrentInvoice,
  clearStatus,
} from "../../store/slices/invoiceSlice";
import CreateSingleInvoice from "./CreateSingleInvoice";
import CreateSplitInvoice from "./CreateSplitInvoice";
import ConvertInvoice from "./ConvertInvoice";
import EditInvoice from "./EditInvoice";
import { XMarkIcon } from "@heroicons/react/24/solid";

export default function InvoiceModal({
  appointment,
  convertInvoice,
  editInvoice,
  onClose,
}) {
  const dispatch = useDispatch();
  const [viewMode, setViewMode] = React.useState("create"); // Default to single invoice creation

  useEffect(() => {
    if (convertInvoice) {
      setViewMode("convert");
    } else if (editInvoice) {
      setViewMode("edit");
    } else if (appointment?.client?._id) {
      dispatch(fetchInvoicesByClientId(appointment.client._id));
      dispatch(fetchInvoicesByAppointmentId(appointment._id));
    }
  }, [dispatch, appointment, convertInvoice, editInvoice]);

  const handleClose = () => {
    dispatch(clearCurrentInvoice());
    dispatch(clearStatus());
    if (onClose) onClose();
  };

  console.log("Invoice Modal");
  console.log("convertInvoice", convertInvoice);
  console.log("editInvoice", editInvoice);
  console.log("viewMode", viewMode);

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 overflow-y-auto">
      {/* Background overlay */}
      <div
        className="fixed inset-0 bg-gray-900 opacity-50"
        onClick={handleClose}
      />

      {/* Modal content */}
      <div
        className="relative bg-white rounded-lg shadow-lg max-w-screen-xl w-full mx-4 p-6"
        style={{
          maxHeight: "90vh",
        }}>
        {/* Modal header */}
        <div className="flex justify-between items-center border-b pb-4">
          <h2 className="text-lg font-semibold text-gray-900">Invoices</h2>
          <button
            onClick={handleClose}
            className="text-gray-500 hover:text-gray-800 focus:outline-none">
            <XMarkIcon className="h-6 w-6" />
          </button>
        </div>

        {/* Tabs */}
        <div className="flex space-x-4 mt-6">
          {!convertInvoice && !editInvoice && (
            <>
              <button
                onClick={() => setViewMode("create")}
                className={`px-4 py-2 rounded ${
                  viewMode === "create"
                    ? "bg-indigo-600 text-white"
                    : "bg-gray-200 text-gray-600"
                }`}>
                Create Single Invoice
              </button>
              <button
                onClick={() => setViewMode("createsplit")}
                className={`px-4 py-2 rounded ${
                  viewMode === "createsplit"
                    ? "bg-indigo-600 text-white"
                    : "bg-gray-200 text-gray-600"
                }`}>
                Create Split Invoice
              </button>
            </>
          )}
          {convertInvoice && (
            <button
              onClick={() => setViewMode("convert")}
              className={`px-4 py-2 rounded ${
                viewMode === "convert"
                  ? "bg-indigo-600 text-white"
                  : "bg-gray-200 text-gray-600"
              }`}>
              Convert Invoice
            </button>
          )}
          {editInvoice && (
            <button
              onClick={() => setViewMode("edit")}
              className={`px-4 py-2 rounded ${
                viewMode === "edit"
                  ? "bg-indigo-600 text-white"
                  : "bg-gray-200 text-gray-600"
              }`}>
              Edit Invoice
            </button>
          )}
        </div>

        {/* Scrollable content container */}
        <div
          className="mt-6 bg-gray-100 rounded-md p-4 overflow-y-auto"
          style={{
            maxHeight: "calc(95vh - 300px)", // Increased from 90vh and reduced offset
          }}>
          {viewMode === "create" && (
            <CreateSingleInvoice appointment={appointment} />
          )}
          {viewMode === "createsplit" && (
            <CreateSplitInvoice appointment={appointment} />
          )}
          {viewMode === "convert" && (
            <ConvertInvoice invoice={convertInvoice} />
          )}
          {viewMode === "edit" && <EditInvoice invoice={editInvoice} />}
        </div>
      </div>
    </div>
  );
}
