import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InvoiceGenerator from "../../applicationUi/components/InvoiceGenerator";
import { fetchInvoicesByAppointmentId } from "../../store/thunks/invoiceThunk";
import { setCurrentInvoice } from "../../store/slices/invoiceSlice";
import { fetchAppointmentById } from "../../store/thunks/appointmentsThunk";
import {
  fetchPayrollById,
  updatePayroll,
  fetchPayrollByInvoiceId,
} from "../../store/thunks/payrollThunk";
import AppointmentSummary from "../../applicationUi/components/appointments/AppointmentSummary";
import ManualPayrollOverride from "./ManualPayrollOverride";
import payrollDates from "./payrollDates";

function HRPayrollDetails({
  payrollId,
  appointment,
  selectedPeriod,
  selectedPeriodDates,
  submitBy,
  payBy,
  onUpdateSuccess,
}) {
  const [fetchedAppointment, setFetchedAppointment] = useState(null);
  const [fetchedPayroll, setFetchedPayroll] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [overrideInvoice, setOverrideInvoice] = useState(null); // To store the selected invoice for override
  const [payrollStatusMap, setPayrollStatusMap] = useState({}); // Store payroll status by invoiceId
  const [showInvoiceGenerator, setShowInvoiceGenerator] = useState(false);
  const [isWideSidePanelOpen, setIsWideSidePanelOpen] = useState(false);
  const [isOverrideModalOpen, setIsOverrideModalOpen] = useState(false);
  const [payPercentage, setPayPercentage] = useState(0);
  const [calculatedAmount, setCalculatedAmount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  console.log("payrollid", payrollId);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedAppointment = await dispatch(
          fetchAppointmentById(appointment._id)
        ).unwrap();
        setFetchedAppointment(fetchedAppointment);

        const fetchedPayroll = await dispatch(
          fetchPayrollById(payrollId)
        ).unwrap();

        console.log("Fetched payroll successfully:", fetchedPayroll);
        setFetchedPayroll(fetchedPayroll);

        const fetchedInvoices = await dispatch(
          fetchInvoicesByAppointmentId(appointment._id)
        ).unwrap();
        setInvoices(fetchedInvoices);

        // Check for payroll existence for each invoice
        const payrollChecks = await Promise.all(
          fetchedInvoices.map(async (invoice) => {
            try {
              const payroll = await dispatch(
                fetchPayrollByInvoiceId(invoice._id)
              ).unwrap();
              return { invoiceId: invoice._id, payroll };
            } catch (error) {
              return { invoiceId: invoice._id, payroll: null }; // If payroll doesn't exist
            }
          })
        );

        // Create a map of payroll statuses by invoiceId
        const statusMap = payrollChecks.reduce(
          (map, { invoiceId, payroll }) => {
            map[invoiceId] = payroll ? payroll._id : null;
            return map;
          },
          {}
        );
        setPayrollStatusMap(statusMap);

        if (fetchedAppointment?.clinician) {
          setPayPercentage(
            fetchedPayroll.percentReceived ||
              fetchedAppointment.clinician.defaultPayPercentage ||
              45
          );
        }

        if (fetchedInvoices.length > 0) {
          setSelectedInvoice(fetchedInvoices[0]);
          dispatch(setCurrentInvoice(fetchedInvoices[0]));
        }
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    fetchData();
  }, [dispatch, appointment, payrollId, isOverrideModalOpen]);

  console.log("fethced payroll", fetchedPayroll);

  useEffect(() => {
    if (selectedInvoice) {
      setCalculatedAmount(
        ((selectedInvoice.amount * payPercentage) / 100).toFixed(2)
      );
    }
  }, [selectedInvoice, payPercentage]);

  // jsut for logging purposes
  useEffect(() => {
    if (fetchedPayroll) {
      console.log("Updated fetchedPayroll:", fetchedPayroll);
    }
  }, [fetchedPayroll]);

  const handleInvoiceClick = (invoice) => {
    setSelectedInvoice(invoice);
    dispatch(setCurrentInvoice(invoice));
    setShowInvoiceGenerator(true);
  };

  const handleOverrideClick = (invoice) => {
    setOverrideInvoice(invoice); // Set the selected invoice for the override
    setIsOverrideModalOpen(true); // Open the modal
  };

  const handleAppointmentClick = () => {
    setIsWideSidePanelOpen(true);
  };

  const handleCloseWideSidePanel = () => {
    setIsWideSidePanelOpen(false);
  };

  const handleUpdatePayrollStatus = async () => {
    if (!fetchedPayroll?.status) {
      setError("Payroll status unavailable. Unable to proceed.");
      return;
    }

    const nextStatus = {
      Pending: "Ready",
      Ready: "Approved",
      Approved: "Paid to Clinician",
    }[fetchedPayroll.status];

    if (!nextStatus) {
      setError(
        "Payroll is already marked as 'Paid to Clinician' or an invalid status."
      );
      return;
    }

    const confirmUpdate = window.confirm(
      `Are you sure you want to mark this payroll as '${nextStatus}'?`
    );
    if (!confirmUpdate) return;

    setIsLoading(true);
    setError(null);
    setSuccess(false);

    const updateData = {
      status: nextStatus,
      updatedBy: user._id,
    };

    try {
      await dispatch(updatePayroll({ id: payrollId, updateData })).unwrap();
      setSuccess(`Payroll status updated to '${nextStatus}' successfully!`);
      setFetchedPayroll((prev) => ({
        ...prev,
        status: nextStatus,
      }));

      if (onUpdateSuccess) {
        onUpdateSuccess(); // Notify parent of success
      }
    } catch (err) {
      setError("Failed to update payroll status. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const isCompleted =
    appointment?.status.includes("Soap Notes Completed") &&
    selectedInvoice &&
    selectedInvoice.status.toLowerCase() === "paid";

  return (
    <div className="px-4 sm:px-0">
      <div className="border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          {/* Appointment Details */}
          {appointment && (
            <div className="my-4">
              <div className="flex items-start justify-between">
                <h3 className="text-base font-semibold leading-7 text-gray-900">
                  Appointment Information
                </h3>
              </div>

              <div
                className="border-2 border-indigo-100 rounded-lg shadow-lg p-1 my-4 cursor-pointer hover:bg-indigo-300 transition duration-200"
                onClick={handleAppointmentClick}>
                <div className="bg-white px-4 py-6 rounded-tl rounded-tr sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Clinician
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0 sm:col-span-2">
                    {appointment.clinician
                      ? `${appointment.clinician.firstName} ${appointment.clinician.lastName}`
                      : "Clinician info unavailable"}
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Client
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0 sm:col-span-2">
                    {appointment.client
                      ? `${appointment.client.firstName} ${appointment.client.lastName}`
                      : "Client info unavailable"}
                  </dd>
                </div>
                <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Service
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0 sm:col-span-2">
                    {appointment.title || "Service info unavailable"}
                  </dd>
                </div>
              </div>
            </div>
          )}

          {/* Invoice Details */}
          {Array.isArray(invoices) && invoices.length > 0 ? (
            <div className="my-4">
              <div className="flex items-start justify-between">
                <h3 className="text-base font-semibold leading-7 text-gray-900">
                  Invoice Information
                </h3>
              </div>

              {invoices.map((invoice) => (
                <div
                  key={invoice._id}
                  className="border-2 border-indigo-100 rounded-lg shadow-lg p-4 my-4">
                  <div className="bg-white px-4 py-6 rounded-tl rounded-tr sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      Invoice ID
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0 sm:col-span-2">
                      {invoice.invoiceId || "N/A"}
                    </dd>
                  </div>
                  <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                      Invoice Amount
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0 sm:col-span-2">
                      ${invoice.amount?.toFixed(2) || "0.00"}
                    </dd>
                  </div>
                  <div className="flex flex-col items-end mt-4">
                    <button
                      className="rounded-md text-sm font-semibold bg-red-600 px-4 py-2 text-white hover:bg-red-500"
                      onClick={() => handleOverrideClick(invoice)}>
                      Manual Override - {invoice.invoiceId}
                    </button>
                    <div className="mt-2 text-xs text-gray-600">
                      {payrollStatusMap[invoice._id] ? (
                        <p>
                          Payroll{" "}
                          <strong>{payrollStatusMap[invoice._id]}</strong>{" "}
                          exists for invoice{" "}
                          <strong>{invoice.invoiceId}</strong>
                        </p>
                      ) : (
                        <p>
                          Payroll does not exist for invoice{" "}
                          <strong>{invoice.invoiceId}</strong>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              ))}

              {showInvoiceGenerator && selectedInvoice && (
                <InvoiceGenerator data={selectedInvoice} />
              )}
            </div>
          ) : (
            <div className="my-4">
              <p className="text-sm text-gray-500">No invoices available.</p>
            </div>
          )}
        </dl>
      </div>
      {isWideSidePanelOpen && (
        <AppointmentSummary
          title="Appointment Summary"
          open={isWideSidePanelOpen}
          onClose={handleCloseWideSidePanel}
          appointment={appointment}
        />
      )}

      <div className="mt-6 flex justify-end space-x-3">
        <button
          className="rounded-md text-sm font-semibold bg-indigo-600 px-4 py-2 text-white hover:bg-indigo-500"
          onClick={handleUpdatePayrollStatus}
          disabled={isLoading}>
          {isLoading
            ? "Processing..."
            : `Mark Payroll As ${
                fetchedPayroll?.status === "Pending"
                  ? "Ready"
                  : fetchedPayroll?.status === "Ready"
                  ? "Approved"
                  : "Paid to Clinician"
              }`}
        </button>

        {/* <button
          className="rounded-md text-sm font-semibold bg-red-600 px-4 py-2 text-white hover:bg-red-500"
          onClick={() => setIsOverrideModalOpen(true)}>
          Manual Override
        </button> */}
      </div>
      <div className="flex items-center relative z-10">
        <ManualPayrollOverride
          isOpen={isOverrideModalOpen}
          onClose={() => setIsOverrideModalOpen(false)}
          appointment={appointment}
          invoices={overrideInvoice ? [overrideInvoice] : []}
          payrollDates={payrollDates}
          payroll={
            overrideInvoice && payrollStatusMap[overrideInvoice._id]
              ? { _id: payrollStatusMap[overrideInvoice._id] }
              : null
          }
        />
      </div>
    </div>
  );
}

export default HRPayrollDetails;
